import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import Editor from '@monaco-editor/react';
import { DataContext } from '../../../utils/dataContext';
import { processNode } from '../../../utils/nodeUtils';


function CodeEditor({tabId, blockId, updateBlockContent, handlePlayButton, language}) {
  const { jsonData } = useContext(DataContext);
  const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
  const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === blockId);
 
  const [height, setHeight] = useState(20); // Start with 20px for one line
  const editorRef = useRef(null);

  // const handleUpdateBlockNode = async () => {
  //   await processNode(jsonDataBlock, null, blockId);
  // }

  const adjustHeight = useCallback(() => {
    if (editorRef.current) {
      const editorElement = editorRef.current.getDomNode();
      if (editorElement) {
        const contentHeight = editorRef.current.getContentHeight();
        // Limit height to 400px
        setHeight(Math.min(contentHeight, 400));
      }
    }
  }, []);
  

  const handleEditorDidMount = useCallback((editor, monaco) => {
    editorRef.current = editor;
    editor.onDidChangeModelContent(adjustHeight);
    
    // Ajustar la altura después de montar el editor, si tiene un valor predeterminado
    if (jsonDataBlock.input) {
      adjustHeight();
    }
  }, [adjustHeight, jsonDataBlock.input]);


  // const handleUpdateBlockContent = (value) => {
  //     updateBlockContent(value);
  // }

  const handleKeyDown = (e) =>{
    if (e.keyCode === 13 && e.shiftKey){
      e.preventDefault();
      handlePlayButton();
    }
  }

  return (
    <div style={{ borderRadius: '5px', overflow: 'hidden' }} onKeyDown={handleKeyDown}>
      <Editor
        height={`${height}px`}
        language={language ? language : 'python'}
        defaultValue={jsonDataBlock.input}
        theme="vs-dark"
        onMount={handleEditorDidMount}
        onChange={updateBlockContent}
        options={{ 
          lineNumbers: 'on',
          scrollBeyondLastLine: false // Prevent padding at the bottom
        }}
      />
    </div>
  );
}

export default CodeEditor;