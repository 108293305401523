import React, { useState } from 'react';
import FilesComponent from "./filesComponent";
import TabListComponent from "./tabsListComponent";
import { Input, Divider } from 'antd';

const { Search } = Input;

function LeftSideComponent() {
  const [panelWidth, setPanelWidth] = useState(221); // Ancho inicial del panel
  const [resizing, setResizing] = useState(false);
  const [hovered, setHovered] = useState(false);

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleMouseDown = (e) => {
    setResizing(true);
  };

  const handleMouseUp = (e) => {
    setResizing(false);
  };

  const handleMouseMove = (e) => {
    if (resizing) {
      const newWidth = e.clientX; // Puedes ajustar este cálculo según tus necesidades
      setPanelWidth(newWidth);
    }
  };

  const leftSideStyles = {
    minWidth: '221px',
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '13.5px 0 5px 10px', // Eliminado el padding derecho para el handler
    height: "100%",
    position: 'relative', // Asegura que el manejo de eventos de redimensionamiento funcione correctamente
    overflow: 'hidden', // Oculta el contenido que se desborda si se redimensiona más allá del ancho del panel
  };

  const contentStyles = {
    minWidth: '221px',
    maxWidth: '330px',
    padding: '13.5px 10px 5px 10px', // Aplicar el padding al contenido interno
    height: '100%',
  };

  const resizeHandleStyles = {
    cursor: 'col-resize',
    width: '5px',
    position: 'absolute',
    top: '50%',
    right: '0',
    height: '10%',
    backgroundColor: hovered ? '#ccc' : '#eee',
    transition: 'background-color 0.3s ease',
  };

  return (
    <div style={leftSideStyles} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      {/* Contenedor para el contenido principal con padding */}
      <div style={{ ...contentStyles, width: panelWidth }}>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{
            width: '100%',
          }}
        />
        <Divider style={{ margin: '5px 0' }} />
        <TabListComponent />
        <Divider style={{ margin: '5px 0' }} />
        <FilesComponent />
      </div>
      {/* Handle para redimensionar */}
      <div
        className="resize-handle"
        style={resizeHandleStyles}
        onMouseDown={handleMouseDown}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      />
    </div>
  );
}

export default LeftSideComponent;
