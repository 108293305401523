
import Login from './login';
import Signup from './signup';
import ResetPasswordComponent from './resetPassword';
import {Modal, Tabs, message, Button} from 'antd';
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { verifyAccount, checkToken } from '../../utils/authApi';
import { DataContext } from "../../utils/dataContext";
import { decodeJWT } from "../../utils/authApi";
import '../../styles/auth.css'
import { RollbackOutlined } from '@ant-design/icons';
function Auth() {
  const [searchParams, setSearchParams] = useSearchParams();
  const verifyCalled = useRef(false);
  const navigate = useNavigate();
  const cookies = parseCookies();
  const [items, setItems] = useState([])
  const {jsonData} = useContext(DataContext);
  const [activeKey, setActiveKey] = useState('Log-in');

  useEffect(() => {

    
    
    

    const handleVerifyAccount = async (token) =>{
      if (verifyCalled.current) return; // Evita que se llame dos veces
      verifyCalled.current = true;
      try {
        const response = await verifyAccount(token);
        message.success("Account successfuly verified, plase log-in to user your account");
      } catch (error){

      } finally{
        verifyCalled.current = false;
      }
    }
    const verifyToken = searchParams.get('verifyToken');
    if (verifyToken){
      handleVerifyAccount(verifyToken);
    }

    const resetToken = searchParams.get('resetToken');
    if (resetToken){
      const resetItems = [
        {
          key: 'reset-password',
          label: 'Reset Password',
          children: <ResetPasswordComponent token={resetToken}/>
        },
      ]
      setItems(resetItems);
    } else {
      const loginItems = [
        {
          key: 'Log-in',
          label: 'Log-in',
          children: <Login />
        },
        {
          key: 'Sign-up',
          label: 'Sign-up',
          children: <Signup/>
        }
      ]
      setItems(loginItems);

      const next = searchParams.get('next');
      if (next === 'signup') {
        setActiveKey('Sign-up');
      } else {
        setActiveKey('Log-in');
      }
    }

    
    
  }, []);

  

  useEffect(() => {

    const handleCheckToken = async () =>{
      const token = cookies.accessToken;

    if (token) {
      const response = await checkToken(token);
      if (response == 'Valid token'){
        const decodedJWT = decodeJWT(token);
        jsonData.userId = decodedJWT.uuid;
        jsonData.username = decodedJWT.username;
        navigate('/preload');
      }
      
    }
    }
    handleCheckToken();
    
  }, [cookies, navigate]);



    return (
      <div className='auth-body'>
    <Modal 
        id="modal-auth"
        centered
        open={true} 
        closable={false} 
        width={600}   
        footer={null}>
         <Tabs items={items}
         activeKey={activeKey}
         onChange={()=>{setActiveKey()}} 
         tabBarExtraContent={{
          right: (
            <Button href={process.env.REACT_APP_LANDING_URL}  style={{ display: 'flex', justifyContent: 'right' }}>
              <RollbackOutlined />
              Return
            </Button>
          ),
        }}
         >
          
         </Tabs>
    </Modal>
    </div>
    )
}

export default Auth;