import { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, message, Spin } from 'antd';
import { getNodeContent, processNode, saveProcess } from '../../../utils/nodeUtils';
import { createVenv } from '../../../utils/nodeUtils';
import { getContainer, createContainer, getContainers, stopContainer } from '../../../utils/dockerApi';
import { saveEnvironment, updateEnvironment } from '../../../utils/nodeUtils';
import { parseCookies } from 'nookies';
import { useNavigate } from 'react-router-dom';

function CreateEnvironmentComponent({ openCreateEnvironment, setOpenCreateEnvironment, jsonData, handleGetEnvironments }) {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const cookies = parseCookies();
  const navigate = useNavigate();

  // Función para validar el nombre del entorno
  const validateNoSpaces = (_, value) => {
    if (value && /\s/.test(value)) {
      return Promise.reject('Environment name cannot contain spaces');
    }
    return Promise.resolve();
  };

    
  const findAvailablePort = (start, end, occupiedPorts) => {
    const occupiedSet = new Set(occupiedPorts);

    for (let port = start; port <= end; port++) {
        if (!occupiedSet.has(port)) {
            return port;
        }
    }
    return null;
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const unavailablePorts = [];

      setLoading(true);
      // const containers = await getContainers();
      // for (const ct of containers){
      //     const container = await getContainer(ct.Names[0].split("/")[1]);
      //     unavailablePorts.push(parseInt(container.HostConfig.PortBindings["8000/tcp"][0].HostPort))
      // }
      // const startPort = 8011;
      // const endPort = 8999;
      // const port = findAvailablePort(startPort, endPort, unavailablePorts);
      // await createContainer(jsonData.userId, values.name, port);
      await saveEnvironment(values.name, "", true);
      const environmentId = cookies.environmentId;
      const environmentName = cookies.environmentName;
      await updateEnvironment(environmentId, null, null, false)
      stopContainer(`${jsonData.userId}-${environmentName}`);
      message.success('Environment successfully created');
      setTimeout(()=>{
        navigate('/preload')
      }, 2000)
    } catch (error) {
      console.log(error);
      if ('name' in error.response.data) {
        message.error('Error creating environment: '+ error.response.data.name );
      } else {
        message.error('Error creating environment');
      }
      
    } finally {
      setLoading(false);
      setOpenCreateEnvironment(false);
      formRef.current.resetFields();
      handleGetEnvironments();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={<p>Create new environment</p>}
      footer={
        <Button type="primary" onClick={() => formRef.current.submit()}>
          Submit
        </Button>
      }
      open={openCreateEnvironment}
      onCancel={() => setOpenCreateEnvironment(false)}
    >
      {loading && (
        <Spin spinning={true} style={{ position: 'absolute', zIndex: 99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Spin>
      )}
      <Form
        name="basic"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please provide a name for the environment' },
            { validator: validateNoSpaces } // Agrega la validación personalizada aquí
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateEnvironmentComponent;
