import { Form, Input, Button, Spin, message } from "antd";
import { useState, useEffect } from "react";
import { LockOutlined} from "@ant-design/icons";
import ProfileImageComponent from "./profileImageComponent";
import { changePassword } from "../../../utils/authApi";
import { useNavigate, } from 'react-router-dom';
import { parseCookies, destroyCookie } from 'nookies';


function ChangePasswordComponent({userId, handleCancel}) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Configurar valores iniciales cuando el componente se monta
    setInitialValues({
      password: '',
      new_password: '',
      new_password_confirmation: ''
    });
    form.setFieldsValue({
      password: '',
      new_password: '',
      new_password_confirmation: ''
    });
  }, [form]);

  const onFinish = async (values) => {
    setLoading(true);
    try{
      const {password, new_password, new_password_confirmation} = values;
      const response = await changePassword(
        password, new_password, new_password_confirmation 
      )
      message.success('Password successfully updated.')

      setTimeout(()=>{
        destroyCookie(null, 'accessToken');
        navigate('/auth')
      }, 2500)

    }catch(error){
      if ('non_field_errors' in error.response.data){
        const non_field_errors = error.response.data.non_field_errors;
        if (non_field_errors[0] === 'Identical passwords, expecting different ones'){
          message.info("Identical passwords, expecting different ones")
        } else if (non_field_errors[0] === 'Inexistent user'){
          message.info("Wrong password, plase try again");
        }
          
      } else {
        message.error('Error updating password, plase try again later')
      }
      
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    // Lógica para manejar errores en el formulario
  };

  const onValuesChange = (changedValues, allValues) => {
    // Comprobar si los valores actuales son iguales a los valores iniciales
    const isChanged = Object.keys(allValues).some(
      key => allValues[key] !== initialValues[key]
    );
    setIsButtonDisabled(!isChanged);
  };

  const passwordValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your password!");
    }
    const minLength = 8;
    const maxLength = 64;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[$¡!|/+.()°{}_<>;:%%*@#¿?&]/;
    if (value.length < minLength || value.length > maxLength) {
      return Promise.reject(`Password must be between ${minLength} and ${maxLength} characters.`);
    }
    if (!hasUpperCase.test(value)) {
      return Promise.reject("Password must include at least one uppercase letter.");
    }
    if (!hasLowerCase.test(value)) {
      return Promise.reject("Password must include at least one lowercase letter.");
    }
    if (!hasNumber.test(value)) {
      return Promise.reject("Password must include at least one number.");
    }
    if (!hasSpecialChar.test(value)) {
      return Promise.reject("Password must include at least one special character.");
    }
    return Promise.resolve();
  };

  const repeatPasswordValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your password!");
    }
    if (form.getFieldValue("new_password") !== value) {
      return Promise.reject("Password do not match!");
    }
    return Promise.resolve();
  };

  
  const handleCancelButton = () => {
    form.setFieldsValue(initialValues);
    setIsButtonDisabled(true);
    handleCancel();

  }
  


  return (
    <>
      <Form
        name="change-password"
        layout="vertical"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600, marginTop: '10px' }}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        onValuesChange={onValuesChange}
        requiredMark={false}
      >
        {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
        <Form.Item
          label="Current password"
          name="password"
          rules={[{ validator: passwordValidator }]}
        >
          <Input.Password prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          label="New password"
          name="new_password"
          rules={[{ validator: passwordValidator }]}
        >
          <Input.Password prefix={<LockOutlined />}/>
        </Form.Item>
        <Form.Item
          label="New password confirmation"
          name="new_password_confirmation"
          rules={[{ validator: repeatPasswordValidator }]}
        >
          <Input.Password prefix={<LockOutlined />}/>
        </Form.Item>
      <Form.Item>
      <div style={{display: 'flex', gap: '5px', justifyContent: 'right'}}>
          <Button 
            
            onClick={handleCancelButton}
            disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
          >
            Cancel
          </Button>
            <Button 
              type="primary" 
              htmlType="submit" 
              disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
            >
              Save
            </Button>
      
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default ChangePasswordComponent;
