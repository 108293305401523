import { Form, Input, Button, message, Spin } from "antd";
import { useState, useEffect } from "react";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { updateUserInfo } from "../../../utils/authApi";


function PersonalInfoComponent({ personalInfo, handleCancel, handleGetUserInfo }) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Configurar valores iniciales cuando el componente se monta
    const initialValues = {
      first_name: personalInfo.first_name,
      last_name: personalInfo.last_name,
      phone_number: personalInfo.phone_number,
    };

    setInitialValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [personalInfo, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try{
      const { first_name, last_name, phone_number } = values;
 
      const response = await updateUserInfo(
        {first_name, last_name, phone_number}, 
        personalInfo.userId
      )
      message.success('Personal info successfully updated.')
      setIsButtonDisabled(true);
      setTimeout(()=>{
        handleGetUserInfo();
        handleCancel();
      }, 1500)
      

    }catch(error){
      message.error('Error updating personal info.')
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    // Lógica para manejar errores en el formulario
  };

  const onValuesChange = (changedValues, allValues) => {
    // Habilitar el botón si hay algún cambio
    const isChanged = Object.keys(allValues).some(
      key => allValues[key] !== initialValues[key]
    );

    setIsButtonDisabled(!isChanged);
  };

  const handleCancelButton = () => {
    form.setFieldsValue(initialValues);
    setIsButtonDisabled(true);
    handleCancel();

  }

  return (
    <>
      <Form
        name="personal-info"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600, marginTop: '10px' }}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        onValuesChange={onValuesChange}
        requiredMark={false}
      >
        {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
        <Form.Item
          label="Name"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Surname"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your surname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "Please input your Phone Number",
            },
          ]}
        >
          <Input prefix={<PhoneOutlined />} />
        </Form.Item>

        {/* Botón de "Update" siempre visible pero inicialmente deshabilitado */}
        <Form.Item>
          <div style={{display: 'flex', gap: '5px', justifyContent: 'right'}}>
          <Button 
            
            onClick={handleCancelButton}
            disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
          >
            Cancel
          </Button>
            <Button 
              type="primary" 
              htmlType="submit" 
              disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
            >
              Save
            </Button>
      
          </div>
          
        </Form.Item>
      </Form>
    </>
  );
}

export default PersonalInfoComponent;
