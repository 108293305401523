import React, { useRef, useEffect, useState } from 'react';

const ConsoleInput = ({ onSendMessage }) => {
    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (inputRef.current) {
                const message = inputRef.current.value;
                onSendMessage(message);
                inputRef.current.value = ''; // Limpiar el campo después de enviar
            }
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div style={{marginLeft: '10px', marginRight: '10px', paddingBottom: '5px'}}>
              <input
            ref={inputRef}
            type="text"
            placeholder=""
            style={{
                marginTop: '10px',
                outline: 'none',
                border: 'none',
                background: 'transparent',
                color: 'inherit',
                fontFamily: 'monospace',
                fontSize: '1em',
                padding: '0',
                borderBottom: '1px solid #ccc',
                boxShadow: 'none',
                width: '100%',
            }}
            onKeyDown={handleKeyDown}
        />
        </div>
      
    );
};

export default ConsoleInput;
