import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

const ProfileImageComponent = ({ onFileChange }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (onFileChange) {
      onFileChange(fileList);
    }
  }, [fileList]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onFileChange(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url || (await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    }));
    
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ImgCrop rotationSlider>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={1}
          customRequest={({ file, onSuccess }) => {
            setFileList([file]);
            onSuccess(null, file);
          }}
        >
          {fileList.length === 0 && '+ Upload'}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default ProfileImageComponent;
