import React, { useState, useContext, useEffect, useRef } from "react";
import { Tree, Dropdown, Skeleton, Button } from "antd";
import {
  CaretDownOutlined,
  HeatMapOutlined,
  EditOutlined,
  DeleteOutlined, 
  BorderlessTableOutlined,
  PlusCircleOutlined,
  EllipsisOutlined,
  ArrowsAltOutlined
} from "@ant-design/icons";
import { getTabsList } from "../../../utils/nodeUtils";
import { DataContext } from "../../../utils/dataContext";
import { processNode, getNodeContent, getProcessesList } from "../../../utils/nodeUtils";
import ContextMenuTabsComponent from "./contextMenuTabs";
import { deleteProcess } from "../../../utils/nodeUtils";

function ProcessListComponent() {
  const { jsonData } = useContext(DataContext);
  const [gData, setGData] = useState([]);
  const [expandedKeys] = useState([]);
  const addCalled = useRef(false);
  const [selectedTab, setSelectedTab] = useState(null)
  const [selectedTabs, setSelectedTabs] = useState([])
  const [titleSpan, setTitleSpan] = useState(null)
  const [openTab, setOpenTab] = useState(false);



  useEffect(() => {
    setTimeout(()=>{
      fetchProcessesList();
    }, 2000);
    
    const handleFetchProcessesList = async (event) =>{
      await fetchProcessesList();
    }


    document.addEventListener("fetchProcessesList", handleFetchProcessesList);

  return () => {
    document.removeEventListener("fetchProcessesList", handleFetchProcessesList);

  };
  }, []);
  const parseTabsList = async (processesList, process) => {
    const response = await getNodeContent(process._id);
    const nodeData = response.node_output;
    var children = [];
    var data = { title: process.title, key: process._id, children: children };
    data.icon = <HeatMapOutlined />;
    if (nodeData.childs && nodeData.childs.length > 0) {
      // Usamos map para crear una lista de promesas
      const childPromises = nodeData.childs.map(async (child) => {
        await parseTabsList(children, child);
      });
      // Esperamos a que todas las promesas se resuelvan
      await Promise.all(childPromises);
    } else {
      data.isLeaf = true;
    }

    processesList.push(data);
};

const fetchProcessesList = async () => {
    if (addCalled.current) return; // Evita que se llame dos veces
    addCalled.current = true;
    try {
        const response = await getProcessesList();
  
        const processesList = [];
        // Usamos map para crear una lista de promesas
        const processPromises = response.map(async (process) => {
            await parseTabsList(processesList, process);
        });
        // Esperamos a que todas las promesas se resuelvan
        await Promise.all(processPromises);
        setGData(processesList);
    } catch (error) {
        console.error("Error fetching process type options:", error);
    } finally {
        addCalled.current = false;
    }
};
  const onDragEnter = (info) => {
   
  };

  const updateChilds = async (tab) => {
    const children = [];
    const response = await getNodeContent(tab.key);
    const tabData = response.node_output;

    for (const child of tab.children) {
        children.push(child.key);
        await updateChilds(child);  // Asegúrate de esperar la llamada recursiva
    }

    tabData.childs = children;
    await processNode(tabData, null, tab.key);
};


const updateJsonDataTabs = async (data) => {
  const newTabList = [];

  for (const tab of data) {
      newTabList.push(tab.key);
      await updateChilds(tab);  // Asegúrate de esperar la llamada asíncrona
  }

  const response = await getNodeContent(jsonData.userId);
  const userData = response.node_output;
  userData.tabs = newTabList;
  await processNode(userData, null, jsonData.userId);
  fetchProcessesList();
};




  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    // updateJsonDataTabs(dragKey, dropKey, info.dropToGap);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setGData(data);

    updateJsonDataTabs(data);

  };

  const findAntTreeTitleElement = (element) => {
    if (!element) return null;
  
    if (element.classList.contains('ant-tree-title')) {
      return element;
    } else {
      // Recorrer los hijos del elemento
      for (let i = 0; i < element.children.length; i++) {
        const foundElement = findAntTreeTitleElement(element.children[i]);
        if (foundElement) return foundElement;
      }
    }
  
    return null;
  };

  const findSiblingByClass = (element, className) => {
    let sibling = element.nextElementSibling;
    while (sibling) {
      if (sibling.classList.contains(className)) {
        return sibling;
      }
      sibling = sibling.nextElementSibling;
    }
    return null; // Retorna null si no se encuentra ningún hermano con la clase deseada
  };

const handleRightClick = (e) => {
  if (e.event.target.className === 'ant-tree-title') {
    const titleSpan = e.event.target;
    setTitleSpan(titleSpan)
  } else if (e.event.target.parentNode.className.includes('anticon')) {
    const titleSpan = findSiblingByClass(e.event.target.parentNode.parentNode, 'ant-tree-title');
    setTitleSpan(titleSpan)
  } if (e.event.target.parentNode.className.includes('ant-tree-treenode')) {
    const titleSpan = findAntTreeTitleElement(e.event.target.parentNode);
    setTitleSpan(titleSpan)
  }
  
  setSelectedTab(e.node.key);
  showContextMenu(e.event.pageX, e.event.pageY, e.node.key);

};


  const showContextMenu = (x, y, key) => {
    setContextMenu({
      visible: true,
      x,
      y,
      key,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      key: null,
    });
  };


  const updateTabTitle = async (newValue) => {
    const response = await getNodeContent(selectedTab);
    const tabData = response.node_output;
    tabData.tabIdName = newValue;
    await processNode(tabData, null, selectedTab);
  };


  const handleRenameTab = () => {
    const currentText = titleSpan.textContent.trim();
    const input = document.createElement('input');
    input.value = currentText;
    input.classList.add('ant-tree-title');
    input.style.width = '80%';
    input.style.borderRadius = '4px';
    input.style.fontSize = '12px';
    input.style.border = 'none';
    input.style.outline = '1.4px solid #1677ff';
  
    // Reemplaza titleSpan con input para permitir la edición
    titleSpan.replaceWith(input);
  
    input.focus();
  
    const handleInputBlur = () => {
      cleanupEventListeners();
      const newValue = input.value;
      titleSpan.textContent = newValue;
      updateTabTitle(newValue);
      input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
       // Limpia los event listeners después de completar la edición
    };
  
    const handleInputKeyDown = (e) => {
      if (e.key === 'Enter') {
        cleanupEventListeners();
        const newValue = input.value;
        titleSpan.textContent = newValue;
        updateTabTitle(newValue);
        input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
        // Limpia los event listeners después de completar la edición
      }
    };
  
    input.addEventListener('blur', handleInputBlur);
    input.addEventListener('keydown', handleInputKeyDown);
  
    const cleanupEventListeners = () => {
      input.removeEventListener('blur', handleInputBlur);
      input.removeEventListener('keydown', handleInputKeyDown);
    };
  }

  const findParentNodeByKey = (data, key) => {
    // Recorre cada nodo en data
    for (const node of data) {
      // Verifica si el nodo actual tiene el key deseado
      if (node.children && node.children.some(child => child.key === key)) {
        return node; // Retorna el nodo actual si alguno de sus hijos coincide con el key
      }
  
      // Si el nodo tiene hijos, realiza la búsqueda recursiva en los hijos
      if (node.children) {
        const parentNode = findParentNodeByKey(node.children, key);
        if (parentNode) {
          return parentNode; // Retorna el nodo padre encontrado en los hijos
        }
      }
    }
    return null; // Retorna null si no se encuentra ningún nodo con el key deseado
  };
  

  const handleDeleteTab = async (tab) => {
    var fetch = false;
    if (!tab){
      tab = selectedTab
      fetch = true;
    }
    const parent = findParentNodeByKey(gData, tab);
    if (!parent) {
      const userResponse = await getNodeContent(jsonData.userId);
      const userData = userResponse.node_output;
      userData.tabs = userData.tabs.filter(item => item !== tab);
      await processNode(userData, null, jsonData.userId)
      
      
    }
    else {
      const parentResponse = await getNodeContent(parent.key);
      const parentData = parentResponse.node_output;
      parentData.childs = parentData.childs.filter(item => item !== tab);
      await processNode(parentData, null, parent.key)

    }
    await deleteProcess(tab)
    if (fetch){
      fetchProcessesList();
    }

    const event = new CustomEvent('deletedTab', { detail: tab});
    document.dispatchEvent(event);
    const eventFetch = new CustomEvent('fetchTabsList', { detail: tab});
    document.dispatchEvent(eventFetch);
  }

  const handleOpenProcess = async (tab) => {
    if (!tab){
      tab = selectedTab
    }
    const event = new CustomEvent('addExistingTab', { detail: tab });
    document.dispatchEvent(event);
    
  }

  const handleMenuClick = ({ key}) => {
    switch(key) {
      case 'open-process':
        handleOpenProcess();
        break
      case 'rename':
        handleRenameTab()
        break
      case 'delete':
        handleDeleteTab()
        break
    }
    handleCloseContextMenu();
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    key: null,
  });

  const items = [
    {
      label: "Open Process",
      key: "open-process",
      icon: <BorderlessTableOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Rename",
      key: "rename",
      icon: <EditOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined style={{marginRight: 5}}/>
    }
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
};

  

  useEffect(() => {
  const handleClickOutside = (event) => {
    
    if (contextMenu.visible) {

      const menuElement = document.querySelector(".context-menu-tabs");
      if (menuElement && !menuElement.contains(event.target)) {
        handleCloseContextMenu();
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [contextMenu.visible]);

  const handleDoubleClickTab = (e, node) =>{
    setSelectedTab(node.key);
    setOpenTab(true);
    
  }
  useEffect(() => {
    if (openTab && selectedTab !== null) {
      handleOpenProcess();
      setOpenTab(false); // Reset openTab after handling
    }
  }, [selectedTab, openTab]);

  const handleNewTab = () => {
    const eventNewTab = new CustomEvent('contextMenuNewTab', { detail: true });
    document.dispatchEvent(eventNewTab);
  }

  const itemsMenuTabs = [
    { label: 'Open selected', key: 'open-selected', icon: <ArrowsAltOutlined/> },
    { label: 'Delete selected', key: 'delete-selected', icon: <DeleteOutlined/> }
];

const handleOpenSelected = async () =>{
  const tabsToOpen = [...selectedTabs];
  
  setSelectedTabs([]);

  for (const tab of tabsToOpen) {
    handleOpenProcess(tab);
  }

}

const handleDeleteSelected = async () => {
  const tabsToDelete = [...selectedTabs];
  
  setSelectedTabs([]);

  for (const tab of tabsToDelete) {
    await handleDeleteTab(tab);
  }
};

const handleMenuTabsClick = async (e) => {
  const key = e.key;

  switch(key){
    case 'open-selected':
      await handleOpenSelected();
      break
    case 'delete-selected':
      await handleDeleteSelected();
      fetchProcessesList();
      break
  }
} 
const menuPropsTabs = {
    items: itemsMenuTabs,
    onClick: handleMenuTabsClick
};

  const handleCheckTab = (keys, e)=>{
    setSelectedTabs(keys)
    console.log(e)
  }

  return (
    <ContextMenuTabsComponent>

<div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
  <label style={{ marginRight: '10px', fontSize: '14px' }}>Processes</label>
  <div style={{ marginLeft: 'auto' }}>
    <Button style={{ marginLeft: '5px' }} title='New Tab'  onClick={handleNewTab}>
      <PlusCircleOutlined />
    </Button>
    <Dropdown menu={menuPropsTabs} trigger={["click"]}>
      <Button style={{ marginLeft: '5px', maxWidth: "44px", minHeight: "32px" }} title="More options">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  </div>
</div>
    {!gData.length > 0  && (<Skeleton active />)}
    
    
    <Tree
    height={500}
      checkable
      showLine
      switcherIcon={<CaretDownOutlined />}
      className="draggable-tree"
      defaultExpandedKeys={expandedKeys}
      draggable={{
        icon: false  // Esto desactivará el icono de arrastre
      }}
      blockNode
      showIcon
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={gData}
      onRightClick={handleRightClick}
      onDoubleClick={handleDoubleClickTab}
      checkedKeys={selectedTabs}
      onCheck={handleCheckTab}
  
    />
    {contextMenu.visible && (
      <div style={{position: 'absolute'}}>
        <Dropdown
        
          menu={menuProps}
          trigger={[]}
          open={contextMenu.visible}
          overlayStyle={{
            position: "fixed",
            left: contextMenu.x,
            top: contextMenu.y,
          }}
          overlayClassName="context-menu-tabs"
        >
          <div
            style={{
              position: "fixed",
              left: contextMenu.x,
              top: contextMenu.y,
              zIndex: 1,
            }}
            onClick={handleCloseContextMenu}
          />
        </Dropdown>
        </div>
      )}
    </ContextMenuTabsComponent>
  );
}
export default ProcessListComponent;
