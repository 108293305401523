import React, { useState, useEffect, useContext, useRef } from 'react';
import { Tree, Dropdown, Button, Skeleton } from 'antd';
import { 
  CaretDownOutlined, 
  EllipsisOutlined, 
  FileAddOutlined, 
  FolderAddOutlined,
  EditOutlined,
  DeleteOutlined, 
  BorderlessTableOutlined
} from '@ant-design/icons';
import { getFilesList, uploadFile, processNode, getNodeContent, directoryCreate, renameFile, moveDirectory, removeFile, deleteNodes } from '../../../utils/nodeUtils';
import { DataContext } from '../../../utils/dataContext';
import ContextMenuFilesComponent from "./contextMenuFiles";
import { generateLabel } from '../../../utils/labels';
const { DirectoryTree } = Tree;


function FilesComponent() {
  const { jsonData } = useContext(DataContext);
  const [gData, setGData] = useState([]);
  const [noFilesMsg, setNoFilesMsg] = useState(false);
  const [gDataPrevious, setGDataPrevious] = useState([]);
  const [expandedKeys] = useState([]);
  const addCalled = useRef(false);
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [titleSpan, setTitleSpan] = useState(null)
  const [itemsContextMenu, setItemsContextMenu] = useState([]);

  const parseFilesList = (filesList, file) => {
    var children = []
    var data = {title: file.name, key: file.folderId, children: children, type: file.type, path: file.path}
    if (file.type === 'file') {
      data.isLeaf = true;
    }
    if (file.childs.length > 0){
      file.childs.forEach((child)=>{
        parseFilesList(children, child);
      })
    } 
    filesList.push(data)
  }

  useEffect(() => {
    const handlerGetFiles = async () => {
      fetchFilesList();
    }
    document.addEventListener("getFiles", handlerGetFiles);
  return () => {
    document.removeEventListener("getFiles", handlerGetFiles);
  };
    
  }, []);

  const deepCopy = (items) => 
    items.map(item => ({
      ...item,
      children: item.children ? deepCopy(item.children) : []
    }));

  const fetchFilesList = async () =>{
    if (addCalled.current) return; // Evita que se llame dos veces
    addCalled.current = true;
    try {
      const response = await getFilesList(jsonData.userId);
      const filesList = []
      response.forEach((file)=>{
        
          parseFilesList(filesList, file)
      })
      setGData(filesList);
      const filesListCopy = deepCopy(filesList);
      setGDataPrevious(filesListCopy);
      jsonData.folders = response;
      if (filesList.length === 0){
        setNoFilesMsg(true);
      } else {
        setNoFilesMsg(false);
      }
    } catch (error) {
      console.error('Error fetching process type options:', error);
    } finally {
      addCalled.current = false;
    }
  }


  const onDragEnter = (info) => {
    console.log(info);
    // expandedKeys, set it when controlled is needed
    // setExpandedKeys(info.expandedKeys)
  };





const moveElementToIndex = (arr, element, toIndex) => {
  // Encuentra el índice del elemento a mover
  const fromIndex = arr.indexOf(element);
  
  // Verifica si el elemento está en el array
  if (fromIndex === -1) {
    throw new Error('Elemento no encontrado en el array');
  }
  
  // Verifica si el índice de destino está dentro del rango
  if (toIndex < 0 || toIndex >= arr.length) {
    throw new Error('Índice de destino fuera de rango');
  }
  
  // Extrae el elemento desde la posición original
  arr.splice(fromIndex, 1);
  
  // Inserta el elemento en la nueva posición
  arr.splice(toIndex, 0, element);
  
  return arr;
};

const insertElementAtIndex = (arr, element, index) => {
  // Verifica si el índice está dentro del rango válido
  if (index < 0 || index > arr.length) {
    throw new Error('Índice fuera de rango');
  }
  
  // Inserta el nuevo elemento en la posición especificada
  arr.splice(index, 0, element);
  
  return arr;
};

  const updateChildrenPath = async (child, basePath) => {
    const childResponse = await getNodeContent(child.key);
    const childData = childResponse.node_output;
    childData.path = `${basePath}/${child.title}`;
    console.log('updating path: ' + childData.path)
    await processNode(childData, null, child.key)
    if (child.type === 'folder'){
      const basePath = `${basePath}/${child.title}`;
      for (const child of child.children){
        await updateChildrenPath(child, basePath)
      }
    }
  }

  const updatePositionOnDrop = async (info, gridData) => {
    const dragNode = info.dragNode;
    const dropToGap = info.dropToGap;
    const node = info.node;
    if (!dropToGap){
     
      if (node.type === 'folder'){
       
        const parentDragNode = findParentNodeByKey(gridData, dragNode.key)
        console.log(parentDragNode)
        console.log(node)
        if ((parentDragNode && node.key !== parentDragNode.key) || (!parentDragNode)){
          const folderResponse = await getNodeContent(node.key);
          const folderData = folderResponse.node_output;
          const newPosition = insertElementAtIndex(folderData.childs, dragNode.key, info.dropPosition)
          folderData.childs = newPosition;
          console.log(info)
          await processNode(folderData, null, node.key)
          const dragedResponse = await getNodeContent(dragNode.key);
          const dragedData = dragedResponse.node_output;
          dragedData.path = `${folderData.path}/${dragNode.title}`;
          console.log('updating path:' + dragedData.path)
          await processNode(dragedData, null, dragNode.key)
          await moveDirectory(dragNode.path, `${folderData.path}/${dragNode.title}`)
          console.log(gridData)
          const parentNode = findParentNodeByKey(gridData, dragNode.key)
          console.log(parentNode)
          if (!parentNode) {
            const userResponse = await getNodeContent(jsonData.userId);
            const userData = userResponse.node_output;
            userData.folders = userData.folders.filter(item => item !== dragNode.key);
            await processNode(userData, null, jsonData.userId)
          } else {
            const parentFolderResponse = await getNodeContent(parentNode.key);
            const parentFolderData = parentFolderResponse.node_output;
            parentFolderData.childs = parentFolderData.childs.filter(item => item !== dragNode.key);
            await processNode(parentFolderData, null, parentNode.key)
          }
        

        
          if (dragNode.type === 'folder'){
            const basePath = `${folderData.path}/${dragNode.title}`;
            for (const child of dragNode.children){
              await updateChildrenPath(child, basePath)
            }
          }
        } else {
          console.log(node.type)
          if (node.type === 'folder'){
            const folderResponse = await getNodeContent(node.key);
            const folderData = folderResponse.node_output;
            const newPosition = moveElementToIndex(folderData.childs, dragNode.key, info.dropPosition)
            folderData.childs = newPosition;
            await processNode(folderData, null, node.key)
          } else {
            const parentNode = findParentNodeByKey(gridData, node.key)
            console.log(parentNode)
            if (parentNode){
              const folderResponse = await getNodeContent(parentNode.key);
              const folderData = folderResponse.node_output;
              console.log(folderData.childs)
              const newPosition = moveElementToIndex(folderData.childs, dragNode.key, info.dropPosition)
              console.log(newPosition)
            }
          }
        }
        
      } else {
        
      }
    } else {
      const parentNode = findParentNodeByKey(gridData, node.key)
      const parentDragNode = findParentNodeByKey(gridData, dragNode.key)
      if (parentNode !== parentDragNode){
        
        if (!parentNode){
          const userResponse = await getNodeContent(jsonData.userId);
          const userData = userResponse.node_output;
          const newPosition = insertElementAtIndex(userData.folders, dragNode.key, info.dropPosition)
          userData.folders = newPosition;
          await processNode(userData, null, jsonData.userId)
          const dragedResponse = await getNodeContent(dragNode.key);
          const dragedData = dragedResponse.node_output;
          dragedData.path = `${dragNode.title}`;
          await processNode(dragedData, null, dragNode.key)
          await moveDirectory(dragNode.path, `${dragNode.title}`)
          if (dragNode.type === 'folder'){
            const basePath = `${dragNode.title}`;
            for (const child of dragNode.children){
              await updateChildrenPath(child, basePath)
            }
          }

        } else {
          const folderResponse = await getNodeContent(parentNode.key);
          const folderData = folderResponse.node_output;
          const newPosition = insertElementAtIndex(folderData.childs, dragNode.key, info.dropPosition)
          folderData.childs = newPosition;
          await processNode(folderData, null, parentNode.key)
          const dragedResponse = await getNodeContent(dragNode.key);
          const dragedData = dragedResponse.node_output;
          dragedData.path = `${folderData.path}/${dragNode.title}`;
          console.log('updating path:' + dragedData.path)
          await processNode(dragedData, null, dragNode.key)
          await moveDirectory(dragNode.path, `${folderData.path}/${dragNode.title}`)
          if (dragNode.type === 'folder'){
            const basePath = `${folderData.path}/${dragNode.title}`;
            for (const child of dragNode.children){
              await updateChildrenPath(child, basePath)
            }
          }
        }
        if (!parentDragNode) {
          const userResponse = await getNodeContent(jsonData.userId);
          const userData = userResponse.node_output;
          userData.folders = userData.folders.filter(item => item !== dragNode.key);
          await processNode(userData, null, jsonData.userId)
        } else {
          const parentFolderResponse = await getNodeContent(parentDragNode.key);
          const parentFolderData = parentFolderResponse.node_output;
          parentFolderData.childs = parentFolderData.childs.filter(item => item !== dragNode.key);
          await processNode(parentFolderData, null, parentDragNode.key)
        }
      } else {
        if (parentNode){
            const folderResponse = await getNodeContent(parentNode.key);
            const folderData = folderResponse.node_output;
            const newPosition = moveElementToIndex(folderData.childs, dragNode.key, info.dropPosition)
            folderData.childs = newPosition;
            await processNode(folderData, null, parentNode.key)
        } else {
            const userResponse = await getNodeContent(jsonData.userId);
            const userData = userResponse.node_output;
            const newPosition = moveElementToIndex(userData.folders, dragNode.key, info.dropPosition)
            userData.folders = newPosition;
            await processNode(userData, null, jsonData.userId)
        }
      }
    }
    fetchFilesList();
  }

  const onDrop = (info) => {
    console.log(info);

    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];
    

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    
  
    updatePositionOnDrop(info, gDataPrevious);
    setGData(data);
    // const dataCopy = deepCopy(data);
    // setGDataPrevious(dataCopy);

  };

  const items = [
    { label: 'Upload File', key: 'upload-file', icon: <FileAddOutlined/> },
    { label: 'Upload Folder', key: 'upload-folder', icon: <FolderAddOutlined/> },
    { label: 'Delete selected', key: 'delete-selected', icon: <DeleteOutlined/> }
];

const handlePosCreated = async (data)=>{
  if (!selectedFile) {
    const userResponse = await getNodeContent(jsonData.userId);
    const userData = userResponse.node_output;
    userData.folders.push(data.folderId);
    await processNode(userData, null, jsonData.userId)
    if (data.type === 'folder'){
      await directoryCreate(data.name)

    }
    data.path = data.name;
    await processNode(data, null, data.folderId);
  } else {
    const folderResponse = await getNodeContent(selectedFile.key);
    const folderData = folderResponse.node_output;
    folderData.childs.push(data.folderId);
    await processNode(folderData, null, selectedFile.key);
    if (data.type === 'folder'){
      await directoryCreate(`${folderData.path}/${data.name}`);

    }
    data.path = `${folderData.path}/${data.name}`;
    await processNode(data, null, data.folderId);
  }
  fetchFilesList();
};

const handleNewFolder = async () =>{
  const response = await processNode('');
  const nodeId = response.node_id;
  var folderData = {
    name: generateLabel(),
    folderId: nodeId,
    type: "folder",
    childs: []
  }
  await processNode(folderData, null, nodeId);

  await handlePosCreated(folderData)
}


const handleFileAttached = async (event) => {
  const nodeResponse = await processNode('');
  const nodeId = nodeResponse.node_id;
  const uploadForm = document.getElementById('form-files-list');

  const formData = new FormData(uploadForm);

  if (selectedFile){
    const folderResponse = await getNodeContent(selectedFile.key);
    const folderData = folderResponse.node_output;
    formData.append('path', folderData.path)
  } 
  const fileResponse = await uploadFile(formData);

  const dataFile = {
    folderId: nodeId,
    blockType: 'file',
    content: fileResponse.file.name,
    type: "file",
    name: fileResponse.file.name,
    url: fileResponse.file.url,
    childs:[]
  };

  await processNode(dataFile, null, nodeId);
  await handlePosCreated(dataFile);
}

const handleUploadFile =() =>{
  var fileInput = document.getElementById('input-files-list');
  fileInput.click();
}

const handleNewFile = async () => {
  var name = generateLabel();
  var fileInput = document.getElementById('input-files-list');
  const file = new File(["foo"], name, {
      type: "text/plain",
  });
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  fileInput.files = dataTransfer.files;
  
  await handleFileAttached();
  
}

const findNodeByKey = (data, keyToFind) => {
  let result = null;

  const searchNode = (nodes) => {
    for (const node of nodes) {
      if (node.key === keyToFind) {
        result = node;
        break;
      }
      if (node.children && node.children.length > 0) {
        searchNode(node.children);
      }
    }
  };

  searchNode(data);
  return result;
};

const handleDeleteSelected = async () =>{
  const filesToDelete = [...selectedFiles];
  setSelectedFiles([]);

  const data = deleteNodesByKeys(gData, filesToDelete);
  setGData(data);
  await deleteNodes(filesToDelete, 'folders');
  for (const file of filesToDelete){
    const node = findNodeByKey(gData, file)
    await (removeFile(node.path))
  }
  fetchFilesList();

}

const handleMenuFilesClick = async (e) => {
  const key = e.key;

  switch(key){
    case 'upload-file':
      handleUploadFile();
      break
    case 'upload-folder':
  
      break
    case 'delete-selected':
      await handleDeleteSelected();
      break
}
} 
const menuProps = {
    items,
    onClick: handleMenuFilesClick
};
const findAntTreeTitleElement = (element) => {
  if (!element) return null;

  if (element.classList.contains('ant-tree-title')) {
    return element;
  } else {
    // Recorrer los hijos del elemento
    for (let i = 0; i < element.children.length; i++) {
      const foundElement = findAntTreeTitleElement(element.children[i]);
      if (foundElement) return foundElement;
    }
  }

  return null;
};

const findSiblingByClass = (element, className) => {
  let sibling = element.nextElementSibling;
  while (sibling) {
    if (sibling.classList.contains(className)) {
      return sibling;
    }
    sibling = sibling.nextElementSibling;
  }
  return null; // Retorna null si no se encuentra ningún hermano con la clase deseada
};

const handleRightClick = (e) => {
if (e.event.target.className === 'ant-tree-title') {
  const titleSpan = e.event.target;
  setTitleSpan(titleSpan)
} else if (e.event.target.parentNode.className.includes('anticon')) {
  const titleSpan = findSiblingByClass(e.event.target.parentNode.parentNode, 'ant-tree-title');
  setTitleSpan(titleSpan)
} if (e.event.target.parentNode.className.includes('ant-tree-treenode')) {
  const titleSpan = findAntTreeTitleElement(e.event.target.parentNode);
  setTitleSpan(titleSpan)
}

setSelectedFile(e.node);
let newItemsContextMenu;
if (e.node.type === 'file') {
  newItemsContextMenu = [
    {
      label: "Open File",
      key: "open-file",
      icon: <BorderlessTableOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Rename",
      key: "rename",
      icon: <EditOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined style={{marginRight: 5}}/>
    }
  ];
} else if (e.node.type === 'folder') {
  newItemsContextMenu = [
    {
      label: "New File",
      key: "new-file",
      icon: <FileAddOutlined style={{marginRight: 5}}/>
    },
    {
      label: "New Folder",
      key: "new-folder",
      icon: <FolderAddOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Rename",
      key: "rename",
      icon: <EditOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined style={{marginRight: 5}}/>
    }
  ];
}

setItemsContextMenu(newItemsContextMenu);
showContextMenu(e.event.pageX, e.event.pageY, e.node.key);

};

const showContextMenu = (x, y, key) => {
  setContextMenu({
    visible: true,
    x,
    y,
    key,
  });
};

const handleCloseContextMenu = () => {
  setContextMenu({
    visible: false,
    x: 0,
    y: 0,
    key: null,
  });
};

const updateFileTitle = async (newValue) => {
  const response = await getNodeContent(selectedFile.key);
  const fileData = response.node_output;
  await renameFile(fileData.path, newValue)
  fileData.path = fileData.path.split(fileData.name)[0] + newValue;
  fileData.name = newValue;
  
  await processNode(fileData, null, selectedFile.key);
  
};


const handleRenameFile = () => {
  const currentText = titleSpan.textContent.trim();
  const input = document.createElement('input');
  input.value = currentText;
  input.classList.add('ant-tree-title');
  input.style.width = '80%';
  input.style.borderRadius = '4px';
  input.style.fontSize = '12px';
  input.style.border = 'none';
  input.style.outline = '1.4px solid #1677ff';

  // Reemplaza titleSpan con input para permitir la edición
  titleSpan.replaceWith(input);

  input.focus();

  const handleInputBlur = () => {
    cleanupEventListeners();
    const newValue = input.value;
    titleSpan.textContent = newValue;
    updateFileTitle(newValue);
    input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
     // Limpia los event listeners después de completar la edición
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      cleanupEventListeners();
      const newValue = input.value;
      titleSpan.textContent = newValue;
      updateFileTitle(newValue);
      input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
      // Limpia los event listeners después de completar la edición
    }
  };

  input.addEventListener('blur', handleInputBlur);
  input.addEventListener('keydown', handleInputKeyDown);

  const cleanupEventListeners = () => {
    input.removeEventListener('blur', handleInputBlur);
    input.removeEventListener('keydown', handleInputKeyDown);
  };
}

const findParentNodeByKey = (data, key) => {
  // Recorre cada nodo en data
  for (const node of data) {
    // Verifica si el nodo actual tiene el key deseado
    if (node.children && node.children.some(child => child.key === key)) {
      return node; // Retorna el nodo actual si alguno de sus hijos coincide con el key
    }

    // Si el nodo tiene hijos, realiza la búsqueda recursiva en los hijos
    if (node.children) {
      const parentNode = findParentNodeByKey(node.children, key);
      if (parentNode) {
        return parentNode; // Retorna el nodo padre encontrado en los hijos
      }
    }
  }
  return null; // Retorna null si no se encuentra ningún nodo con el key deseado
};

const deleteNodesByKeys = (tree, keysToDelete) => {
  // Función recursiva que busca y elimina los nodos con las keys especificadas
  const deleteRecursive = (nodes, keysToDelete) =>  {
    return nodes.filter(node => {
      // Si el nodo tiene una key en la lista de keysToDelete, lo eliminamos
      if (keysToDelete.includes(node.key)) {
        return false;
      }
      // Si tiene hijos, buscar recursivamente en ellos
      if (node.children && node.children.length > 0) {
        node.children = deleteRecursive(node.children, keysToDelete);
      }
      // Devolver el nodo si no es uno de los que queremos eliminar
      return true;
    });
  }

  // Ejecutar la función de eliminación recursiva en el árbol completo
  return deleteRecursive(tree, keysToDelete);
}

const handleDeleteFile = async () => {
  const data = deleteNodesByKeys(gData, [selectedFile.key]);
  setGData(data);
  await deleteNodes([selectedFile.key], 'folders')
  fetchFilesList();
  await removeFile(selectedFile.path);
  setSelectedFile(false);
}



const handleMenuClick = ({ key}) => {
  switch(key) {
    case 'open-file':
      // handleOpenTab();
      break
    case 'new-file':
      handleNewFile();
      break
    case 'new-folder':
      handleNewFolder();
      break
    case 'rename':
      handleRenameFile();
      break
    case 'delete':
      handleDeleteFile();
      break
  }
  handleCloseContextMenu();
};


const menuPropsContextMenu = {
  items: itemsContextMenu,
  onClick: handleMenuClick,
};

const [contextMenu, setContextMenu] = useState({
  visible: false,
  x: 0,
  y: 0,
  key: null,
});




useEffect(() => {
  const handleClickOutside = (event) => {
    if (contextMenu.visible) {
      const menuElement = document.querySelector(".context-menu-files");
      if (menuElement && !menuElement.contains(event.target)) {
        handleCloseContextMenu();
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [contextMenu.visible]);





  const handleLeftClick = (e, node) =>{
    if (node.type === 'file') {
      const parent = findParentNodeByKey(gData, node.key);
     
        setSelectedFile(parent)
      
    } else {
      setSelectedFile(node)
    }
  }

  useEffect(()=>{
    const handler = async (event)=>{
   
    }

    document.addEventListener('contextMenuNewFile', handleNewFile);
    document.addEventListener("contextMenuNewFolder", handleNewFolder)
    document.addEventListener('contextMenuUploadFile', handleUploadFile);

    return () => {
      document.removeEventListener('contextMenuNewFile', handleNewFile);
      document.removeEventListener("contextMenuNewFolder", handleNewFolder);
      document.removeEventListener('contextMenuUploadFile', handleUploadFile);
    };
  }, [items])

  const handleCheckFiles = (keys)=>{
    setSelectedFiles(keys)
  }


  return (
    <ContextMenuFilesComponent>
  <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
  <label style={{ marginRight: '10px', fontSize: '14px' }}>Files</label>
  <div style={{ marginLeft: 'auto' }}>
  <form id="form-files-list" action="#" method="POST" encType="multipart/form-data" style={{display: "none"}}>
        <input type="file" id="input-files-list" name="file" onChange={handleFileAttached}/>
    </form>
    <Button style={{ marginLeft: '5px' }} title='New File'  onClick={handleNewFile}>
      <FileAddOutlined />
    </Button>
    <Button style={{ marginLeft: '5px' }} title="New Folder" onClick={handleNewFolder}>
      <FolderAddOutlined />
    </Button>
    <Dropdown menu={menuProps} trigger={["click"]}>
      <Button style={{ marginLeft: '5px', maxWidth: "44px", minHeight: "32px" }} title="More options">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  </div>
</div>
{noFilesMsg && <label style={{fontSize: '13px'}}>No files available</label>}

{(!gData.length > 0 && !noFilesMsg )&& (<Skeleton active />)}

    <DirectoryTree
    checkable
    height={250}
        showLine
        switcherIcon={<CaretDownOutlined />}
      className="draggable-tree"
      defaultExpandedKeys={expandedKeys}
      draggable={{
        icon: false  // Esto desactivará el icono de arrastre
      }}
      
      blockNode
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={gData}
      onRightClick={handleRightClick}
      onClick={handleLeftClick}
      onCheck={handleCheckFiles}
      checkedKeys={selectedFiles}
    />
    {contextMenu.visible && (
      <div style={{position: 'absolute'}}>
        <Dropdown
          menu={menuPropsContextMenu}
          trigger={[]}
          open={contextMenu.visible}
          overlayStyle={{
            position: "fixed",
            left: contextMenu.x,
            top: contextMenu.y,
          }}
          overlayClassName="context-menu-files"
        >
          <div
            style={{
              position: "fixed",
              left: contextMenu.x,
              top: contextMenu.y,
              zIndex: 1,
            }}
            onClick={handleCloseContextMenu}
          />
        </Dropdown>
        </div>
      )}
      
      </ContextMenuFilesComponent>
    
  );
};
export default FilesComponent;