import React, { useEffect, useState, useContext } from 'react';
import { Button, Input, message, Select, Space } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import AddBlockComponent from '../content/addBlockComponent';
import { DataContext } from '../../../utils/dataContext';
import { parseCookies } from 'nookies';

function InputMlComponent() {
    const { jsonData } = useContext(DataContext);
    const [socket, setSocket] = useState(null);
    const [input, setInput] = useState('');
    const cookies = parseCookies();

    useEffect(() => {
        const connectWebSocket = async () => {
    
            const apiPort = cookies.apiPort;

            var url = '/ws/copilot/';
            if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
                url = `/pipeline/port/${apiPort}${url}`
            }
     
            const ws = new WebSocket(process.env.REACT_APP_PIPELINE_WEBSOCKET_URL + url);
            setSocket(ws);

            ws.onopen = () => {
                console.log('Connected to WebSocket');
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const eventBotMessage = new CustomEvent('addBlock', { detail: { type: "text", content: data.message, tabId: jsonData.activeTabId, context: 'bot' } });
                document.dispatchEvent(eventBotMessage);
            };

            ws.onclose = () => {
                console.log('Disconnected from WebSocket');
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            // Limpiar la conexión cuando el componente se desmonta
            return () => {
                ws.close();
            };
        };

        connectWebSocket();
    }, []);

    useEffect(() => {
        const handler = (event) => {
            if (socket) {
                socket.send(JSON.stringify({ message: event.detail }));
                
            } else {
                console.error('WebSocket is not connected');
            }
        };

        document.addEventListener('aggregatedBlockByChat', handler);
        return () => {
            document.removeEventListener('aggregatedBlockByChat', handler);
        };
    }, [socket, input]);

    const inputMlStyles = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        gap: '10px',
        margin: '10px'
    };

    const handleMenuClick = ({ key }) => {
        const event = new CustomEvent('addBlock', { detail: { type: key, content: '', tabId: jsonData.activeTabId, context: 'menu' } });
        document.dispatchEvent(event);
    };

    const handlePressEnter = async (e) => {
        e.preventDefault();
        const event = new CustomEvent('addBlock', { detail: { type: "text", content: input, context: "user", tabId: jsonData.activeTabId } });
        document.dispatchEvent(event);
        setInput('');
    };

    const handleSendButton = async (e) => {
        if (input !== '') {
            const event = new CustomEvent('addBlock', { detail: { type: "text", content: input, context: "user", tabId: jsonData.activeTabId } });
            document.dispatchEvent(event);
            setInput('');
        }
    };

    return (
        <div style={inputMlStyles}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Space.Compact style={{ width: '100%' }}>
                    <AddBlockComponent onMenuClick={handleMenuClick}></AddBlockComponent>
                    <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 5 }}
                        style={{ width: '100%', borderRadius: 0 }}
                        onPressEnter={handlePressEnter}
                        value={input}
                        onChange={(e) => { setInput(e.target.value) }} />
                    <Button style={{ maxWidth: '44px' }} onClick={handleSendButton}><CaretRightOutlined /></Button>
                </Space.Compact>
            </Space>
        </div>
    );
}

export default InputMlComponent;
