import { useEffect, useState, useContext } from "react";
import {Input, Button} from "antd";
import { DataContext } from '../../../utils/dataContext';
import { processNode } from "../../../utils/nodeUtils";
import { RobotOutlined, UserOutlined } from "@ant-design/icons";
import Editor from '@monaco-editor/react';
import { generateLabel } from "../../../utils/labels";

function TabContentComponentNormal({tabId}){
    const { jsonData } = useContext(DataContext);
    const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
    const [blocks, setBlocks] = useState(jsonDataTab.blocks || []);


    const handleUpdateTab = async () =>{
        const dataTab = { ...jsonDataTab };
        const blockIds = jsonDataTab.blocks.map(block => block.blockId);
        dataTab.blocks = blockIds;
        await processNode(dataTab, null, jsonDataTab.tabId);
      }

      const generateRandomLabel = (length = 6) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };
    

    const handleSetBlock = async (type, content, context, language) => {
        try {
          const response = await processNode('');
          const blockData = {
            blockId: response.node_id,
            blockType: type,
            input: content ? content: '',
            output: '',
            label: generateLabel(),
            active: true,
            origin: context
          };
    
          if (type === 'code'){
            blockData.language = language;
          }
          
          // Update the jsonDataTab and set the new state for blocks
          jsonDataTab["blocks"] = [...jsonDataTab["blocks"], blockData];
    
          await processNode(blockData, null, response.node_id);
          
          // Update the state with the new block
          setBlocks([...blocks, blockData]);
          await handleUpdateTab();
          if (context && context === 'user') {
            const event = new CustomEvent('aggregatedBlockByChat', { detail: content });
            document.dispatchEvent(event);
            
          }
        } catch (error) {
          console.error('Error adding new block:', error);
        }
      };

      const detectCodeLanguage = (code) => {
        // Define simple patterns for JavaScript and Python
        const jsPatterns = [
            /console\.log\(/,
            /function\s+\w+\s*\(/,
            /let\s+\w+\s*=|\bvar\b|\bconst\b/,
            /import\s+\w+\s+from\s+/,
            /return\s+/,
        ];

        const pythonPatterns = [
            /def\s+\w+\s*\(/,
            /import\s+\w+/,
            /print\(/,
            /class\s+\w+/,
            /return\s+/,
        ];

        let jsScore = 0;
        let pythonScore = 0;

        // Check against JavaScript patterns
        jsPatterns.forEach(pattern => {
            if (pattern.test(code)) jsScore++;
        });

        // Check against Python patterns
        pythonPatterns.forEach(pattern => {
            if (pattern.test(code)) pythonScore++;
        });

        // Determine the language based on scores
        if (jsScore > pythonScore) {
            return 'javascript';
        } else if (pythonScore > jsScore) {
            return 'python';
        } else {
            return '';
        }
    };

    useEffect(() => {
        const handler = (event) => {
            if (event.detail.tabId == tabId){
             
                const detectedLanguage = detectCodeLanguage(event.detail.content);
                const blockType = detectedLanguage === '' ? 'text': 'code'
                handleSetBlock(blockType, event.detail.content, event.detail.context, detectedLanguage)
            }
        };
    
        document.addEventListener('addBlock', handler);
    
        return () => {
          document.removeEventListener('addBlock', handler);
        };
      }, [blocks]);


    return (
        <div className="content-container" >
    

        {blocks.map((block) => (
            <div id={block.blockId} key={block.blockId} style={{display: 'flex'}}>
              <Button shape="circle">{block.origin === 'user' ?  <UserOutlined />: <RobotOutlined />}</Button>
              {block.blockType === 'text' && (
                <Input.TextArea  variant="borderless" value={block.input} readOnly autoSize={{ minRows: 1 }}/>
              )}
              {block.blockType === 'code' && (
                <div style={{marginLeft: '5px', width: '100%', height: '100%', borderRadius: '5px'}}>
                <Editor
                height="300px"
                defaultLanguage={block.language}
                defaultValue={block.input}
                options={{ readOnly: true }}
              
              />
                  </div>
              )}
                
                </div>
            ))}
   
      
        
        
    </div>
    );
}

export default TabContentComponentNormal;