import { useState, useEffect } from 'react';
import { Modal, Tabs } from 'antd';
import PersonalInfoComponent from './personalInfoComponent';
import AccountInfoComponent from './accountInfoComponent';
import ChangePasswordComponent from './changePasswordComponent';
import { getUserInfo } from '../../../utils/authApi';
import { SettingOutlined } from '@ant-design/icons';

function ProfileComponent({ showProfile, setShowProfile, jsonData, setAvatarImage}) {
  const [open, setOpen] = useState(false);
  const [activeKey, setActiveKey] = useState('personal-info');
  const [personalInfo, setPersonalInfo] = useState(false);
  const [accountInfo, setAccountInfo] = useState(false);


  useEffect(() => {
    setOpen(showProfile);
  }, [showProfile]);

  const handleGetUserInfo = async () =>{
    const response = await getUserInfo(jsonData.userId);
    setPersonalInfo(
      {
        first_name: response.first_name,
        last_name: response.last_name,
        phone_number: response.phone_number,
        userId: response.uuid
      }
    )
    let profileImage = null;
    if (response.profile_image) {
      profileImage = response.profile_image.replace('/media/', '/users-api/media/');
    }

    setAccountInfo({
      username: response.username,
      email: response.email,
      profile_image: profileImage,
      userId: response.uuid
    })
    setAvatarImage(profileImage);
  }

  useEffect(()=>{
    
    setTimeout(()=>{
      handleGetUserInfo();
    }, 1000)
    
  }, [])

  const handleCancel = () => {
    setOpen(false);
    setShowProfile(false);
  };

  const items = [
    {
      key: 'personal-info',
      label: 'Personal Information',
      children: <PersonalInfoComponent personalInfo={personalInfo} handleCancel={handleCancel} handleGetUserInfo={handleGetUserInfo}/>
    },
    {
      key: 'account-info',
      label: 'Account Information',
      children: <AccountInfoComponent accountInfo={accountInfo} handleCancel={handleCancel} handleGetUserInfo={handleGetUserInfo}/>
    },
    {
      key: 'change-password',
      label: 'Change password',
      children: <ChangePasswordComponent userId={jsonData.userId} handleCancel={handleCancel} handleGetUserInfo={handleGetUserInfo}/>
    }
  ]

  return (
    <>
      <Modal
        title={
        <div style={{display: 'inline-flex', gap: '5px'}}>
          <SettingOutlined />
          <p>Settings</p>
        </div>
        }
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs 
          items={items}
          defaultActiveKey="personal-info"
          // activeKey={activeKey}
          onChange={()=>{setActiveKey()}} 
        />
      </Modal>
    </>
  );
}

export default ProfileComponent;
