import {useEffect, useState, useRef, useContext} from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
// import { createPost, generateJWT } from '../../../utils/ghostApi';
import { DataContext } from '../../../utils/dataContext';

function PostComponent({ showPost, setShowPost }) {
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);
  const [blockOptions, setBlockOptions] = useState([]);
  const { jsonData } = useContext(DataContext);

  const findBlockById = (blockId) => {
    if (jsonData && jsonData.tabs) {
      for (const tab of jsonData.tabs) {
        const block = tab.blocks.find(block => block.blockId === blockId);
        if (block) {
          return block;
        }
      }
    }
    return null; // O puedes lanzar un error o devolver un valor predeterminado
  };

  useEffect(() => {
    const handler = (event) => {
      const block = findBlockById(event.detail.blockId);
      if (block) {
        if (event.detail.display) {
          // Agregar el bloque a blockOptions si display es true
          setBlockOptions(prevOptions => [
            ...prevOptions.filter(option => option.value !== block.blockId),
            { label: block.label, value: block.blockId }
          ]);
        } else {
          // Eliminar el bloque de blockOptions si display es false
          setBlockOptions(prevOptions => 
            prevOptions.filter(option => option.value !== block.blockId)
          );
        }
      }
    };

    document.addEventListener('displayCodeEditor', handler);

    return () => {
      document.removeEventListener('displayCodeEditor', handler);
    };
  }, [jsonData]);

  const onFinish = async (values) => {
    const header = {
      alg: "HS256",
      typ: "JWT",
      kid: 'your-key-id'
    };
    
    const payload = {
      aud: '/your/audience',
      sub: 'your-subject',
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor(Date.now() / 1000) + (365 * 24 * 60 * 60),
      blocks: values.blocks,
    };
    
    const secret = 'your-secret-key'; 

    const description = `<p>${values.description}</p><br>`
    
    // const jwt = await generateJWT(header, payload, secret);
    // const data = {
    //   posts: [{
    //     title: values.title,
    //     html: `${description} 
    //     <iframe src="http://localhost:3000/try/${jwt}" 
    //         width="100%" height="600px" frameborder="0">
    //     </iframe>`
    //   }]
    // };
    

    // const response = await createPost(data);

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    setOpen(showPost);
  }, [showPost]);

  const handleCancel = () => {
    setOpen(false);
    setShowPost(false);
  };


  return (
    <>
      <Modal
        title={<p>New Post</p>}
        open={open}
        onCancel={handleCancel}
        footer={
          <Button type="primary" onClick={() => formRef.current.submit()}>
            Submit
          </Button>
        }
      >
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={formRef}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please input your title!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tags"
            name="tags"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Authors"
            name="authors"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
          >
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 50 }} />
          </Form.Item>

          <Form.Item
            label="Blocks"
            name="blocks"
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
             
              options={blockOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PostComponent;
