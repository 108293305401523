import {get, post, patch} from './apiUtils';
import { parseCookies } from 'nookies';
export const checkUsernameEmail = async (username, email) => {
    const requestData = {};
    if(username){
        requestData.username = username
    }
    if(email){
        requestData.email = email
    }
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/check_email_username/', requestData)
        return response;
    } catch (error) {
        console.error('Error check username/email:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const login = async (requestData) => {


    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/login/', requestData)
        return response;
    } catch (error) {
        console.error('Error login:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const signup = async (requestData) => {
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/signup/', requestData)
        return response;
    } catch (error) {
        console.error('Error signup:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const resendVerificationEmail = async (email) => {
    const requestData = {email: email}
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/resend/', requestData)
        return response;
    } catch (error) {
        console.error('Error login:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const sendPasswordResetEmail = async (email) => {
    const requestData = {email: email}
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/forgetpassword/', requestData)
        return response;
    } catch (error) {
        console.error('Error login:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const verifyAccount = async (token) => {
    const requestData = {token: token}
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/verify/', requestData)
        return response;
    } catch (error) {
        console.error('Error verifying account:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const checkToken = async (token) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await get(process.env.REACT_APP_USERS_API_URL, '/user/check_token/', headers)
        return response;
    } catch (error) {
        console.error('Error login:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const changePasswordToken = async (requestData) => {

    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, '/user/changepasswordtoken/', requestData)
        return response;
    } catch (error) {
        console.error('Error verifying account:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const base64urlDecode = (encoded) => {
    // Replace characters according to base64url specifications
    let base64 = encoded
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    
    // Add padding if necessary
    const pad = base64.length % 4;
    if (pad > 0) {
        base64 += '='.repeat(4 - pad);
    }
    
    // Decode from base64 to binary string
    const binaryString = atob(base64);
    // Convert binary string to Uint8Array
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const decodeJWT = (token) => {
    // Divide el token en sus partes
    const [header, payload, signature] = token.split('.');

    // Decodifica la parte del payload
    const decodedPayloadBytes = base64urlDecode(payload);
    const decodedPayload = new TextDecoder().decode(decodedPayloadBytes);

    // Convierte el payload decodificado de JSON a objeto JavaScript
    const payloadObject = JSON.parse(decodedPayload);

    return payloadObject;
};

export const getUserInfo = async (user_id) => {
    const cookies = parseCookies();
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }

    var url = `/user/${user_id}/`;
    console.log(url)
    try {
        const response = await get(process.env.REACT_APP_USERS_API_URL, url, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};



export const updateUserInfo = async (requestData, userId) => {
    const cookies = parseCookies();
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }
    
    var url = `/user/${userId}/`;

    try {
        const response = await patch(process.env.REACT_APP_USERS_API_URL, url, requestData, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const changePassword = async (password, new_password, new_password_confirmation) => {
    const cookies = parseCookies();
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }
    
    var url = `/user/changepassword/`;

    const requestData = {
        password: password,
        new_password: new_password,
        new_password_confirmation: new_password_confirmation
    }

    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, url, requestData, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};