import React, { useState, useEffect } from 'react';
import { Dropdown} from 'antd';
import {BorderlessTableOutlined,} from '@ant-design/icons';

const ContextMenuTabsComponent = ({ children }) => {


    const handleClickMenu = ({ key }) => {
        console.log(key)
        switch(key){
          case "new-tab":
            const eventNewTab = new CustomEvent('contextMenuNewTab', { detail: true });
            document.dispatchEvent(eventNewTab);
            break
          default:
            break
    
        }
      };
    
      
    
      const items = [
        { label: "New Tab", key: "new-tab", icon: <BorderlessTableOutlined/> },
      ];



  const menuProps = {
    items: items,
    onClick: handleClickMenu
  }


  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleContextMenu = (e) => {
    
    const notAllowed = [
      "ant-tree-node-content-wrapper",
      "ant-tree-node-content-wrapper-close",
      "ant-tree-title",
      "ant-tree-iconEle", 
      "ant-tree-icon__customize",
      "ant-tree-checkbox-inner",
      "ant-btn css-dev-only-do-not-override-1jsclrj", 
      "ant-btn-default",
       
    
    
    ]
    let classList = e.target.className.baseVal || e.target.className; // Manejar SVGAnimatedString
  if (typeof classList !== 'string') {
    classList = ''; // Manejar casos donde className no es una cadena
  }

    const classArray = classList.split(' ');
    const allow = notAllowed.some(targetClass => classArray.includes(targetClass));
    console.log(e.clientY)
    e.preventDefault();
    if (e.target.tagName === "svg" || e.target.closest("svg")) {
      return;
  }
    if (!allow){
  
      setPosition({ x: e.clientX, y: e.clientY });
      setVisible(true);
    }
    
  };

  const handleCloseMenu = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (visible) {
  
        const menuElement = document.querySelector(".context-menu-left");
        if (menuElement && !menuElement.contains(event.target)) {
          handleCloseMenu();
        }
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible]);

  return (
    <div onContextMenu={handleContextMenu} onClick={handleCloseMenu} style={{ width: '100%',  height: '18rem'}}>
      <Dropdown overlayClassName="context-menu-left" menu={menuProps} open={visible} trigger={['contextMenu']} overlayStyle={{ position: 'fixed', left: position.x, top: position.y }}>
        <div style={{ width: '100%', height: '100%', display: "flex", flexDirection: "column", gap: '10px'}}>
          {children}
        </div>
      </Dropdown>
    </div>
  );
};

export default ContextMenuTabsComponent;
