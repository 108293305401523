import { Form, Input, Button, Spin, message } from "antd";
import { useState, useEffect } from "react";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import ProfileImageComponent from "./profileImageComponent";
import { updateUserInfo, checkUsernameEmail } from "../../../utils/authApi";

function AccountInfoComponent({ accountInfo, handleCancel, handleGetUserInfo }) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originalImage, setOriginalImage] = useState(false);

  useEffect(() => {
    // Configurar valores iniciales cuando el componente se monta
    setInitialValues({
      username: accountInfo.username,
      email: accountInfo.email,
      profile_image: accountInfo.profile_image
    });
    form.setFieldsValue({
      username: accountInfo.username,
      email: accountInfo.email,
      profile_image: accountInfo.profile_image
    });
    setOriginalImage({url: accountInfo.profile_image});
  }, [accountInfo, form]);

  const userValidator = async (_, value) => {
    if (!value) {
      return Promise.reject("Please input your username!");
    }
    if (value === initialValues.username){
      return Promise.resolve();
    }
    try {
      const response = await checkUsernameEmail(value, null);
      if (response.username === "available") {
        return Promise.resolve();
      } else {
        return Promise.reject("User with this username already exists");
      }
    } catch (error) {
      console.error("Error checking username:", error);
      return Promise.reject("Error validating username");
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try{
      const {username, email} = values;
      const data = new FormData();

      data.append('username', username);
      data.append('email', email);

      if (profileImage) {
        data.append('profile_image', profileImage);
      }
      const response = await updateUserInfo(data, accountInfo.userId)
      message.success('Account info successfully updated.')
      setIsButtonDisabled(true);
      setTimeout(()=>{
        handleGetUserInfo();
        handleCancel();
      }, 1500)
      

    }catch(error){
      message.error('Error updating account info')
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    // Lógica para manejar errores en el formulario
  };

  const onValuesChange = (changedValues, allValues) => {
    // Comprobar si los valores actuales son iguales a los valores iniciales
    const isChanged = Object.keys(allValues).some(
      key => allValues[key] !== initialValues[key]
    );
    setIsButtonDisabled(!isChanged);
  };

  
  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setProfileImage(file);
      if (initialValues.profile_image !== fileList[0].url){
        setIsButtonDisabled(false);
      }
      
    } else {
      setProfileImage(null);
    }
  };

  const handleCancelButton = () => {
    form.setFieldsValue(initialValues);
    setIsButtonDisabled(true);
    setProfileImage(null);
    const timestamp = new Date().getTime();  // Para generar un valor único
    setOriginalImage({
      url: initialValues.profile_image, 
      key: timestamp // Agregamos un key único para forzar la actualización
    });
    
    handleCancel();

  }

  return (
    <>
      <Form
        name="account-info"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600, marginTop: '10px' }}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        onValuesChange={onValuesChange}
        requiredMark={false}
      >
        {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
        <Form.Item
          label="Username"
          name="username"
          rules={[{ validator: userValidator }]}
        >
          <Input prefix={<UserOutlined />}/>
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />}/>
        </Form.Item>
        <Form.Item label="Profile Image" name="profile_image">
          <ProfileImageComponent onFileChange={handleFileChange} profile_image={originalImage}/>
          </Form.Item>

        <Form.Item>
        <div style={{display: 'flex', gap: '5px', justifyContent: 'right'}}>
          <Button 
            
            onClick={handleCancelButton}
            disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
          >
            Cancel
          </Button>
            <Button 
              type="primary" 
              htmlType="submit" 
              disabled={isButtonDisabled}  // Deshabilitado si los valores no cambian
            >
              Save
            </Button>
      
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default AccountInfoComponent;
