import { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, Input, message, Spin } from 'antd';
import { getNodeContent, processNode, saveProcess } from '../../../utils/nodeUtils';

function SaveProcessComponent({ openSaveProcess, setOpenSaveProcess, jsonData, jsonDataTab }) {
  const formRef = useRef(null);
  const [code, setCode] = useState('');
  const [processId, setProcessId] = useState('');
  const [loading, setLoading] = useState(false);

  const handleParseContent = () => {
    var content = '';
    var firstBlock = true;

    for (const data of jsonDataTab["blocks"]) {
      if (!firstBlock) {
        content += '\n';
      } else {
        firstBlock = false;
      }
      content += data.input;
    }

    return content;
  };

  const validatePythonCode = () => {
    // Expresión regular para encontrar todas las funciones main, sin importar si están comentadas o dentro de clases
    const functionRegex = /(?<!#\s*)def\s+main\s*\(/g; // Asegúrate de no capturar funciones en comentarios
    const classFunctionRegex = /class\s+\w+.*?(def\s+main\s*\()/gs; // Encuentra main dentro de clases
  
    // Busca funciones main que no están dentro de clases
    const functionMatches = code.match(functionRegex);
  
    // Busca funciones main dentro de clases
    const classFunctionMatches = code.match(classFunctionRegex);
  
    // Elimina las funciones que están dentro de clases
    const filteredFunctionMatches = functionMatches && functionMatches.filter(
      (func) => !classFunctionMatches || !classFunctionMatches.some(
        (classFunc) => classFunc.includes(func)
      )
    );
  
    // Retorna verdadero solo si hay exactamente una función main fuera de clases
    return filteredFunctionMatches && filteredFunctionMatches.length === 1;
  };

  useEffect(() => {
    if (openSaveProcess) {
      const contentCode = handleParseContent();
      setCode(contentCode);
      setProcessId(jsonDataTab.tabId);

    }
  }, [openSaveProcess, jsonDataTab]);

  const onFinish = async (values) => {
    
    if (!validatePythonCode()) {
      message.error('The code must contain exactly one function named "main".');
      return
    }
    try {
      setLoading(true);
      await saveProcess(processId, values.title, code, jsonData.userId);
      const response = await getNodeContent(processId);
      const data = response.node_output;
      data.isProcess = true;
      data.tabIdName = values.title;
      await processNode(data, null, processId);
      message.success('Process successfully saved');
      const eventFetchProcesses = new CustomEvent('fetchProcessesList', { detail: true });
      document.dispatchEvent(eventFetchProcesses);
    } catch (error) {
      console.log(error);
      message.error('Error saving process');
    } finally {
      setLoading(false);
      setOpenSaveProcess(false);
      formRef.current.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={<p>Save code as process</p>}
      footer={
        <Button type="primary" onClick={() => formRef.current.submit()}>
          Submit
        </Button>
      }
      open={openSaveProcess}
      onCancel={() => setOpenSaveProcess(false)}
    >
      {loading && (
        <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}></Spin>
      )}
      <Form
        name="basic"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please provide a title for the process' }]}
        >
          <Input value={jsonDataTab ? jsonDataTab.tabIdName : ''}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SaveProcessComponent;
