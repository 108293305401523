import axios from 'axios';

// Función de utilidad para realizar una solicitud GET
export const get = async (baseUrl, endpoint, headers={}) => {
    try {
        const response = await axios.get(`${baseUrl}${endpoint}`, {headers});
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

// Función de utilidad para realizar una solicitud POST
export const post = async (baseUrl, endpoint, data, headers={}) => {
    try {
        const response = await axios.post(`${baseUrl}${endpoint}`, data, {headers});
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};


export const put = async (baseUrl, endpoint, data, headers={}) => {
    try {
        const response = await axios.put(`${baseUrl}${endpoint}`, data, {headers});
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const patch = async (baseUrl, endpoint, data, headers={}) => {
    try {
        const response = await axios.patch(`${baseUrl}${endpoint}`, data, {headers});
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const deleteFunc = async (baseUrl, endpoint, headers={}) => {
    try {
        const response = await axios.delete(`${baseUrl}${endpoint}`, {headers});
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};