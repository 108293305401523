// Listas de palabras ampliadas para generar la etiqueta
const adjectives = [
    "agile", "brave", "calm", "daring", "eager", "fancy", "gentle", "happy", "inventive",
    "jolly", "kind", "lively", "mighty", "noble", "optimistic", "proud", "quick", "reliable",
    "strong", "trusty", "unique", "vivid", "witty", "zealous", "adventurous", "brilliant",
    "creative", "dynamic", "energetic", "fearless", "graceful", "humble", "intelligent",
    "joyful", "keen", "loyal", "motivated", "noteworthy", "outgoing", "passionate", "resilient",
    "smart", "talented", "upbeat", "valiant", "wise", "youthful", "zesty", "bold", "charming",
    "dedicated", "enthusiastic", "friendly", "generous", "honest", "imaginative", "jubilant",
    "knowledgeable", "logical", "meticulous", "nurturing", "observant", "persistent", "resourceful",
    "sincere", "thoughtful", "understanding", "victorious", "warmhearted", "xenial", "yearning", "zany"
];

const nouns = [
    "artist", "builder", "creator", "dreamer", "explorer", "fixer", "guru", "hero", "innovator",
    "juggler", "knight", "leader", "master", "navigator", "observer", "pioneer", "quester", 
    "rockstar", "scientist", "teacher", "unicorn", "visionary", "warrior", "xenophile", "yogi", "zenith",
    "architect", "captain", "designer", "engineer", "founder", "geek", "hacker", "inventor", "joker",
    "king", "legend", "magician", "ninja", "orator", "philosopher", "ranger", "sage", "trailblazer",
    "uplifter", "voyager", "wizard", "youth", "zenmaster", "alchemist", "biologist", "chemist", 
    "doctor", "ecologist", "farmer", "gardener", "historian", "illustrator", "judge", "knower",
    "linguist", "musician", "novelist", "orchestrator", "poet", "raconteur", "strategist", "thinker",
    "umpire", "veteran", "watcher", "xeroxer", "yawner", "zealot"
];

const numbers = Array.from({length: 10000}, (_, i) => i); // Genera números del 0 al 9999

// Función para obtener una palabra aleatoria de una lista
const getRandomElement = (array) => {
    return array[Math.floor(Math.random() * array.length)];
}

// Función para generar una etiqueta al estilo de Codespaces
export const generateLabel = () => {
    const adjective = getRandomElement(adjectives);
    const noun = getRandomElement(nouns);
    // const number = getRandomElement(numbers);
    return `${adjective}-${noun}`;
}
