import React, { useState } from 'react';
import { Upload, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { parseCookies } from 'nookies';

const FileInputComponent = ({ file }) => {

  const cookies = parseCookies();
  const apiPort = cookies.apiPort;
  

  const getFilePreview = () => {
    if (!file) return null;

    // Check if file is a URL (string) or a file object
    if (typeof file === 'string') {
      console.log(file)
      // If it's a URL
      const url = `${process.env.REACT_APP_PIPELINE_API_URL}/pipeline/port/${apiPort}/media` + file.replace('/workspace', '');
      const fileType = url.split('.').pop().toLowerCase();

      

      if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif' || fileType === 'webp') {
        return <img src={url} alt="preview" style={{ maxWidth: '100%' }} />;
      } else if (fileType === 'mp4' || fileType === 'ogg') {
        return <ReactPlayer url={url} controls width="100%" height="auto" />;
      } else if (fileType === 'mp3' || fileType === 'wav') {
        return <ReactPlayer url={url} controls width="100%" height="auto" />;
      } else {
        return <p>Archivo no compatible para vista previa.</p>;
      }
    } else if (file instanceof Blob) {
      // If it's a file object
      const fileURL = URL.createObjectURL(file);

      switch (file.type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/webp':
          return <img src={fileURL} alt="preview" style={{ maxWidth: '100%' }} />;
        case 'video/mp4':
        case 'video/ogg':
          return <ReactPlayer url={fileURL} controls width="100%" height="auto" />;
        case 'audio/mp3':
        case 'audio/wav':
          return <ReactPlayer url={fileURL} controls width="100%" height="auto" />;
        default:
          return <p>Archivo no compatible para vista previa.</p>;
      }
    } else {
      return <p>No se puede determinar el tipo de archivo.</p>;
    }
  };

  return (
    <div>
 
      {file && (
    
          getFilePreview()

      )}
    </div>
  );
};

export default FileInputComponent;
