import React, { useContext, useEffect, useState } from 'react';
import runtimeLogo from '../../../assets/runtime-logo.svg';
import {Cascader, message, Spin} from 'antd';
import { getEnvironments, createVenv } from '../../../utils/nodeUtils';
import { DataContext } from '../../../utils/dataContext';
import CreateEnvironmentComponent from './createEnvironmentComponent';
import { parseCookies } from 'nookies';
import { useNavigate } from 'react-router-dom';
import { updateEnvironment } from '../../../utils/nodeUtils';
import { stopContainer } from '../../../utils/dockerApi';


function TopLeftComponent() {
  const [options, setOptions] = useState([]);
  const {currentEnvironment, setCurrentEnvironment} = useContext(DataContext);
  const {jsonData} = useContext(DataContext);
  const [openCreateEnvironment, setOpenCreateEnvironment] = useState(false);
  const cookies = parseCookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const environmentId = cookies.environmentId;
  const environmentName = cookies.environmentName;

  const updateOptions = (environments) => {
    setOptions([
      // {
      //   value: 'export',
      //   label: 'Export as',
      //   children: [
      //     {
      //       value: 'json',
      //       label: 'JSON',
      //     },
      //     {
      //       value: 'bin',
      //       label: 'BIN',
      //     },
      //   ],
      // },
      // {
      //   value: 'open',
      //   label: 'Open',
      //   children: [
      //     {
      //       value: 'json',
      //       label: 'Open JSON',
      //     },
      //     {
      //       value: 'bin',
      //       label: 'Open BIN',
      //     },
      //   ],
      // },
      {
        value: 'new-file',
        label: 'New file',
      },
      {
        value: 'file',
        label: 'File',
        children: [
 
          ...environments.map(env => ({
            value: env._id, // Ajusta según la estructura de tus datos
            label: env.name, // Ajusta según la estructura de tus datos
          })),
        ],
      },
    ]);
  };

  const handleGetEnvironments = async () =>{
    const response = await getEnvironments();
    try{
      updateOptions(response.results)

    } catch(error){
      console.log("Error in handle get environments:" + error)
    }
    
    
  }

  useEffect(()=>{

    
    handleGetEnvironments();

  }, []);

  const handleChangeEnvironment = async(selectedOptions) =>{
    try{
      if (selectedOptions.label === environmentName){
        message.info(`File ${selectedOptions.label} is already open`);
        return
      }
      setLoading(true);
      message.info(`Changing to ${selectedOptions.label}`);
     
      await updateEnvironment(environmentId, null, null, false)
      await updateEnvironment(selectedOptions.value, null, null, true)
      stopContainer(`${jsonData.userId}-${environmentName}`);
      navigate('/preload')

    }catch(error){
      message.error(`Error changing to ${selectedOptions.label}`);
    } finally{
      setLoading(false);
    }
      
  }


  const onChange = (value, selectedOptions) => {
    switch(value[0]){
      case 'file':
        handleChangeEnvironment(selectedOptions[1]);
        break
      case 'new-file':
        setOpenCreateEnvironment(true);
      default:
        break
    }
  };

  const containerStyles = {
    display: 'flex', 
    alignItems: 'center', 
    gap: '25px', 
    flexDirection: 'row', 
    justifyContent: 'left', 
    marginLeft: '10px' 
  }

  const handleOnContextMenu = (e) => {
    console.log(e.target);
    e.preventDefault();
    if (e.target.classList.contains('ant-cascader-menu-item-content') && e.target.textContent !== 'File'){
      console.log(e)
    }
  }

  return (
    <>
     {loading && (
        <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}></Spin>
      )}
      <div style={containerStyles}>
        <img src={runtimeLogo} style={{ height: '32px' }} alt="Runtime Logo" />
        <Cascader onContextMenu={handleOnContextMenu} options={options} onChange={onChange} defaultValue={['file', environmentName]} style={{width: '144px'}}/>
      </div>
      <CreateEnvironmentComponent 
      openCreateEnvironment={openCreateEnvironment} 
      setOpenCreateEnvironment={setOpenCreateEnvironment} 
      jsonData={jsonData}
      handleGetEnvironments={handleGetEnvironments}
      />
    </>
  );
}

export default TopLeftComponent;
