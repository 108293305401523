// themeConfig.js
export const themeConfig = {
    components: {
      Tree: {
        directoryNodeSelectedBg: "rgb(158, 158, 158)",
        colorPrimary: "rgb(35, 35, 35)"
      },
      Tabs: {
        cardHeight: 32,
        controlHeightLG: 32
      }
    },
    token: {
      colorPrimary: "#6e6e6e",
      colorInfo: "#6e6e6e",
      colorSuccess: "#35e461",
      colorError: "#ff395d",
      colorWarning: "#fbe300",
      colorLink: "#818181",
      fontFamily: "Inter",
      fontSize: 12
      },
  };
  