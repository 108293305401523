import React, { useEffect } from 'react';
import { useState } from 'react';
import { Cascader } from 'antd';
import { getProcessesList } from '../../../utils/nodeUtils';

function ProcessSelectorComponent () {
    const [processTypeOptions, setProcessTypeOptions] = useState([]);
    const onChange = (value) => {
    console.log(value);
    };

    useEffect(()=>{
        fetchProcessses();
    }, [])

    const fetchProcessses = async () =>{
        try {
          const response = await getProcessesList();
          const processesList = []
          response.forEach((process)=>{
              processesList.push({value: process._id, label: process.title})
          })
          setProcessTypeOptions(processesList);
       
        } catch (error) {
          console.error('Error fetching process type options:', error);
        }
      }

    return(
        <div style={{display: 'flex', flexDirection:'column'}}>
        <label style={{ marginBottom: '8px', fontSize: '14px', color: '#595959' }}>
        <b>Processes</b>
    </label>
        <Cascader
        style={{
          width: '100%',
        }}
        options={processTypeOptions}
        onChange={onChange}
        multiple
        maxTagCount="responsive"
        placeholder="Here you can select a process"
      />
        </div>
    );
   
  
}
export default ProcessSelectorComponent;