import { Input, Button, Space } from "antd";
import { 

    CaretRightOutlined,
 
} from "@ant-design/icons";
import { useParams } from 'react-router-dom'
import { useState } from "react";
// import { decodeJWT } from "../../utils/ghostApi";

function TryCodeComponent () {
    const { token } = useParams()
    const [textInput, setTextInput] = useState('');

    const [blocks, setBlocks] = useState([]);
    const [outputs, setOutputs] = useState([]);

    useState(()=>{
        // const decodedToken = decodeJWT(token);
        // setBlocks(decodedToken.blocks)
        // console.log(decodedToken)
    }, [token])
    


    const styles = {
        height: '500px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        // alignItems: 'center',
        // justifyContent: 'center'
    }

    const outputStyles = {
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflow: 'auto',
    }

    const handlePressEnter = () => {
        if (textInput.trim() !== '') {
            setOutputs([...outputs, textInput]);
            setTextInput('');
        }
    };

    return (
        <div className="try-code-container" style={styles}>
    
            <div className="try-output-container" style={outputStyles}>
            {outputs.map((output, index) => (
                    <Input key={index} value={output} readOnly />
                ))}
            </div>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Space.Compact style={{ width: '100%' }}>
                    
                    <Input
                        style={{ width: '100%' }}
                        onPressEnter={handlePressEnter}
                        value={textInput}
                        onChange={(e) => { setTextInput(e.target.value) }} />
                    <Button style={{ maxWidth: '44px' }}><CaretRightOutlined /></Button>
                </Space.Compact>
            </Space>
            
            
        </div>
    );
}

export default TryCodeComponent;