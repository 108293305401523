import React, { useEffect, useRef, useState, useContext } from 'react';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import { FitAddon } from 'xterm-addon-fit';
import { parseCookies } from 'nookies';
import { DataContext } from '../../../utils/dataContext';
const TerminalComponent = ({jsonData, jsonDataTab, jsonDataBlock, updateBlockContent}) => {
  const terminalRef = useRef(null);
  const socketRef = useRef(null);
  const inputRef = useRef(''); // Usar useRef para manejar la entrada del usuario
  const [commandHistory, setCommandHistory] = useState([]);
  const historyIndexRef = useRef(-1);
  const firstMessageReceived = useRef(false);
  const cookies = parseCookies();
  const apiPort = cookies.apiPort;
  const {currentEnvironment, setCurrentEnvironment} = useContext(DataContext);
  
  const canDeleteFromPath = (input) => {
    return input.length > 0;
  };

  const extractBetweenColonAndDollar = (inputString) => {
    // Define the regular expression pattern
    const pattern = /:(.*?)\$/;

    // Use the match method to find matches
    const match = inputString.match(pattern);

    // If there's a match, return the first capture group
    return match ? match[1] : null;
}


  useEffect(() => {
    const term = new Terminal();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon); 
    term.open(terminalRef.current);
    fitAddon.fit();
    
    var url = '/ws/terminal/';
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
        url = `/pipeline/port/${apiPort}${url}`
    }

    socketRef.current = new WebSocket(process.env.REACT_APP_PIPELINE_WEBSOCKET_URL + url);

    socketRef.current.onopen = () => {
      if (jsonDataBlock.currentPath){
        const initialMessage = {command: `cd ${jsonDataBlock.currentPath}` };
      socketRef.current.send(JSON.stringify(initialMessage));
      }
      
    };




    socketRef.current.onmessage = (event) => {
      const response = JSON.parse(event.data);

  

      if (response.type === 'mkdir' && response.output === 'Directory created'){
        const eventFiles = new CustomEvent('getFiles', { detail: true });
        document.dispatchEvent(eventFiles);
      } else {
        let formattedMessage = response.message;
        // Dividir la salida en dos partes basadas en el símbolo `$`
        const parts = formattedMessage.split('$$$');
        
    
        if (parts.length > 1 && parts[1]!==' ') {
          const newParts = parts[0].split('\n');
          if (newParts.length > 1){
            newParts.forEach((part)=>{
              term.writeln(part)
            });
          } else {
            term.writeln(parts[0])
          }
          
          term.write(parts[1])
          jsonDataBlock.currentPath = extractBetweenColonAndDollar(parts[1]);
          
          
        } else {
          if (!firstMessageReceived.current) {
            jsonDataBlock.currentPath = extractBetweenColonAndDollar(formattedMessage);
            firstMessageReceived.current = true;
          }
         
          term.write(formattedMessage)
        }
        

        // Escribir el mensaje formateado en el terminal
      }
      
    };

    term.onData((data) => {
   
      if (data === '[A' || data === '[B' || data === '[C'|| data === '[D'){
      
        return
      }
      if (data === '\x7f') { // Código de la tecla Backspace
        const currentInput = inputRef.current;
        if (canDeleteFromPath(currentInput)) {
          inputRef.current = currentInput.slice(0, -1);
          term.write('\x08'); // Carácter de retroceso
          term.write(' ');   // Espacio para borrar el carácter
          term.write('\x08'); // Carácter de retroceso
        }
      } else if (data === '\r') { // Código de Enter
        term.write('\r\n'); // Nueva línea
        // Enviar el comando al backend
        
        socketRef.current.send(JSON.stringify({ command: inputRef.current, user_id: jsonData.userId }));
        inputRef.current = ''; // Limpiar la entrada después de enviar el comando
        setCommandHistory(prevHistory => [...prevHistory, inputRef.current]);
        historyIndexRef.current = -1;
      } else {
        inputRef.current += data;
        term.write(data);
      }

      updateBlockContent(inputRef.current)
    });

    term.onKey((event) => {
    
  
      if (event.domEvent.key === 'ArrowUp') {
        if (historyIndexRef.current < commandHistory.length - 1) {
          historyIndexRef.current += 1;
          const command = commandHistory[commandHistory.length - 1 - historyIndexRef.current];
          term.write('\r'); // Mover el cursor al inicio de la línea
          term.write('\x1B[K'); // Limpiar la línea
          term.write(command); // Escribir el comando del historial
          inputRef.current = command; // Actualizar la entrada actual
        }
      } else if (event.domEvent.key === 'ArrowDown') {
        if (historyIndexRef.current > 0) {
          historyIndexRef.current -= 1;
          const command = commandHistory[commandHistory.length - 1 - historyIndexRef.current];
          term.write('\r'); // Mover el cursor al inicio de la línea
          term.write('\x1B[K'); // Limpiar la línea
          term.write(command); // Escribir el comando del historial
          inputRef.current = command; // Actualizar la entrada actual
        } else if (historyIndexRef.current === 0) {
          historyIndexRef.current -= 1;
          term.write('\r'); // Mover el cursor al inicio de la línea
          term.write('\x1B[K'); // Limpiar la línea
          inputRef.current = ''; // Limpiar la entrada actual
        }
      }
    });


  

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      term.dispose();
    };
  }, [jsonDataBlock]);

  return (
    <div ref={terminalRef} className="terminal-container" style={{ height: 'auto', width: '100%' }}></div>
  );
};

export default TerminalComponent;
