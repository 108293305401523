import { useState, useEffect } from "react";
import ProcessListComponent from "./processListComponent";
function RightSideComponent(){
    const [settingsComponent, setSettingsComponent] = useState(null);

    // useEffect(() => {
    //     const handleCustomEvent = (event) => {
    //         const { component } = event.detail;
    //         console.log(component)
    //         setSettingsComponent(component);
    //         console.log(settingsComponent);
    //     };

    //     document.addEventListener('passSettingsComponent', handleCustomEvent);

    //     return () => {
    //         document.removeEventListener('passSettingsComponent', handleCustomEvent);
    //     };
    // }, [settingsComponent]);

    const rightSideStyles = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: '13.5px 10px 5px 10px',
        minWidth: '200px'
    };

    return (
        <div style={rightSideStyles}>
           <ProcessListComponent/>
        </div>
    );
}

export default RightSideComponent;
