import React, { useState, useEffect, useContext } from 'react';
import { Modal, Statistic, Row, Col, Card } from 'antd';
import { getContainerStats } from '../../../utils/dockerApi';
import { parseCookies } from 'nookies';

function SystemMonitorComponent({ showMonitor, setShowMonitor, jsonData }) {
    const cookies = parseCookies();
    const environmentName = cookies.environmentName;
    const [stats, setStats] = useState(null);
    const [error, setError] = useState(null);

    const handleCancel = () => {
        setShowMonitor(false);
    };
    

    useEffect(() => {
        let intervalId;

        const fetchStats = async () => {
            try {
                var userId_environmentName = `${jsonData.userId}-${environmentName}`
                const data = await getContainerStats(userId_environmentName );
                setStats(data);
            } catch (err) {
                setError(err);
            }
        };

        if (showMonitor) {
            fetchStats();
            intervalId = setInterval(fetchStats, 2000); 
        } else {
            // Clear interval if modal closes
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [showMonitor, jsonData.userId]); // Depend on `showMonitor` to control when to fetch

    const bytesToGB = (bytes) => (bytes / 1_073_741_824).toFixed(2);

    const calculateCpuUsagePercent = (currentCpuStats, previousCpuStats) => {
      const {
          total_usage: currentTotalUsage,
          percpu_usage: currentPercpuUsage
      } = currentCpuStats.cpu_usage;
      const {
          system_cpu_usage: currentSystemUsage
      } = currentCpuStats;
  
      const {
          total_usage: previousTotalUsage
      } = previousCpuStats.cpu_usage;
      const {
          system_cpu_usage: previousSystemUsage
      } = previousCpuStats;
  
      // Calcula los cambios desde la última lectura
      const cpuDelta = currentTotalUsage - previousTotalUsage;
      const systemDelta = currentSystemUsage - previousSystemUsage;
  
      // Número de CPUs en línea
      const numCpus = currentCpuStats.online_cpus;
  
      // Calcula el porcentaje de uso de CPU
      let cpuPercent = 0.0;
      if (systemDelta > 0 && cpuDelta > 0) {
          cpuPercent = (cpuDelta / systemDelta) * numCpus * 100;
      }
  
      return cpuPercent;
  };
  

    return (
      <Modal 
          title="Resource Usage" 
          open={showMonitor} 
          width={800}
          onCancel={handleCancel}
          footer={null}
          centered={true}
      >
          {error && <p>Error: {error.message}</p>}
          {stats ? (
              <Row gutter={16}>
                  <Col span={12}>
                      <Card title="Memory Usage" bordered={false}>
                          <Statistic
                                title="Current RAM Usage"
                                value={bytesToGB(stats.memory_stats.usage)}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="GB"
                            />
                          <Statistic
                                title="Current RAM Usage (%)"
                                value={(stats.memory_stats.usage / stats.memory_stats.limit) * 100}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="%"
                            />
                            <Statistic
                                title="RAM Limit"
                                value={bytesToGB(stats.memory_stats.limit)}
                                precision={2}
                                valueStyle={{ color: '#cf1322' }}
                                suffix="GB"
                            />
                      </Card>
                  </Col>
                  <Col span={12}>
                      <Card title="CPU Usage" bordered={false}>
                          <Statistic
                              title="CPU Usage"
                              value={calculateCpuUsagePercent(stats.cpu_stats, stats.precpu_stats)}
                              precision={2}
                              valueStyle={{ color: '#3f8600' }}
                              suffix="%"
                          />
                          <Statistic
                              title="Number of CPUs"
                              value={stats.cpu_stats.online_cpus}
                              precision={2}
                              valueStyle={{ color: '#3f8600' }}
                              suffix="CPUs"
                          />
                      </Card>
                  </Col>
              </Row>
          ) : (
              <p>Loading...</p>
          )}
      </Modal>
  );
}

export default SystemMonitorComponent;
