import { Form, Input, Button, message, Checkbox, Spin, Modal } from "antd";
import { checkUsernameEmail, login, resendVerificationEmail, sendPasswordResetEmail} from "../../utils/authApi";
import { useContext, useEffect, useState} from "react";
import { setCookie, parseCookies } from 'nookies';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataContext } from "../../utils/dataContext";

function Login() {
  const [form] = Form.useForm();
  const [showResendVerification, setShowResendVerification] = useState(false);
  const navigate = useNavigate();
  const {jsonData} = useContext(DataContext);
  
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberMe');
    if (savedEmail) {
      form.setFieldsValue({ email: savedEmail, remember: true });
    }

  
  }, [form]);

  



  const emailValidator = async (_, value) => {
    if (!value) {
      return Promise.reject('Please input your email!');
    }

    // Validación de formato de correo electrónico
    if (!/\S+@\S+\.\S+/.test(value)) {
      return Promise.reject('Invalid email format!');
    }

    // try {
    //   const response = await checkUsernameEmail(null, value);
    //   if (response.email === 'not available') {
    //     return Promise.resolve(); // Email exists, validation successful
    //   } else {
    //     return Promise.reject('User with this email does not exist');
    //   }
    // } catch (error) {
    //   console.error('Error checking email:', error);
    //   return Promise.reject('Error validating email');
    // }
  };

  const passwordValidator = (_, value) => {
    if (!value) {
      return Promise.reject('Please input your password!');
    }

    // Validación de cada requisito
    const minLength = 8;
    const maxLength = 64;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[$¡!|/+.()°{}_<>;:%%*@#¿?&]/;

    if (value.length < minLength || value.length > maxLength) {
      return Promise.reject(`Password must be between ${minLength} and ${maxLength} characters.`);
    }
    if (!hasUpperCase.test(value)) {
      return Promise.reject('Password must include at least one uppercase letter.');
    }
    if (!hasLowerCase.test(value)) {
      return Promise.reject('Password must include at least one lowercase letter.');
    }
    if (!hasNumber.test(value)) {
      return Promise.reject('Password must include at least one number.');
    }
    if (!hasSpecialChar.test(value)) {
      return Promise.reject('Password must include at least one special character.');
    }

    return Promise.resolve();
  };



  const storeTokenInCookie = (token) => {
    setCookie(null, 'accessToken', token, {
      maxAge: 30 * 60,
      path: '/',
      secure: false,  // Solo enviar a través de HTTPS
      httpOnly: false, // True si el backend maneja cookies HttpOnly
      sameSite: 'lax', // O 'strict' dependiendo de tu configuración
    });
  };


  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { email, password, remember } = values;
      const response = await login({ email, password });
      jsonData.userId = response.user.uuid;
      jsonData.username = response.user.username;
      storeTokenInCookie(response.access_token);
      if (remember) {
        localStorage.setItem('rememberMe', email);
      } else {
        localStorage.removeItem('rememberMe');
      }
      navigate('/preload');
    } catch (error){
      if (error.response.data.inactive){
        setShowResendVerification(true);
        message.info("Please check your mail and verify your account")
      }
      if (error.response.data.password){
      
        message.info("Invalid credentials, please check your password")
      }
      if (error.response.data.email){
      
        message.info("Invalid credentials, please check your email")
      }
    } finally{
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const resendVerification = async () => {
    setLoading(true);
    const email = form.getFieldValue('email');
    try {
      await resendVerificationEmail(email);
      message.success("Verification email resent successfully!");
    } catch (error) {
      console.error('Error resending verification email:', error);
      message.error("Error resending verification email");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (email) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(email);
      message.success("Password reset email sent!");
      setShowForgotPassword(false);
    } catch (error) {
      message.error("Failed to send password reset email.");
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
    <Form
      name="log-in"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      style={{ maxWidth: 600, marginTop: '10px' }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      requiredMark={false}
      
    >
      {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
      <Form.Item
        label="Email"
        name="email"
        rules={[{ validator: emailValidator }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ validator: passwordValidator }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" onClick={() => setShowForgotPassword(true)}>
          Forgot password
        </a>

        </div>
       
      </Form.Item>

      <Form.Item>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: '10px' }}>
        {showResendVerification && (
          <Button type="dashed" onClick={resendVerification}>
          Resend Verification Email
        </Button>
        )}

          <Button type="primary" htmlType="submit">
            Log-in
          </Button>
        </div>
      </Form.Item>
    </Form>
    <Modal
        title="Forgot Password"
        open={showForgotPassword}
        style={{
          top: 180,
        }}
        footer={null}
        onCancel={() => setShowForgotPassword(false)}
      >
        <Form
          name="forgot-password"
          layout="vertical"
          onFinish={(values) => handleForgotPassword(values.email)}
          autoComplete="off"
        >
          {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
          <Form.Item
            label="Email"
            name="email"
            rules={[{ validator: emailValidator }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <div style={{textAlign: 'right'}}>
            <Button type="primary" htmlType="submit" >
              Send Reset Email
            </Button>
            </div>
            
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Login;
