import { Spin, message } from "antd";
import { useState, useRef, useContext, useEffect } from "react";
import { getContainers, getContainer, startContainer, createContainer, getContainerProcesses } from "../../utils/dockerApi";
import { useNavigate } from 'react-router-dom';
import { parseCookies, setCookie } from 'nookies';
import { DataContext } from "../../utils/dataContext";
import { decodeJWT } from "../../utils/authApi";
import EnvironmentSelector from "./environmentSelectorComponent";
import { getEnvironments, saveEnvironment, updateEnvironment } from "../../utils/nodeUtils";


function PreloadComponent (){
    const navigate = useNavigate();
    const preloadCalled = useRef(false);
    const cookies = parseCookies();
    const {jsonData} = useContext(DataContext);
    const [environments, setEnvironments] = useState([]);
    const [loading, setLoading] = useState(false);


    const processToFind = "/usr/local/bin/python /usr/local/bin/daphne -p 8000 -b 0.0.0.0 config.asgi:application";

    const isProcessPresent = (processes, processToFind) => {
        return processes.some(process => process.includes(processToFind));
    }
    
    // // Intervalo en milisegundos (por ejemplo, 5000 ms = 5 segundos)
  
    const findAvailablePort = (start, end, occupiedPorts) => {
        const occupiedSet = new Set(occupiedPorts);

        for (let port = start; port <= end; port++) {
            if (!occupiedSet.has(port)) {
                return port;  // Retorna el primer puerto libre encontrado
            }
        }

        return null;  // Retorna null si no hay puertos libres disponibles
    };

    const handleRunExistingContainer = async(cont, containerName, port, id) =>{
        const container = await getContainer(`${jsonData.userId}-${containerName}`);
        

        setCookie(null, 'apiPort', port, {
            maxAge: 30 * 60,
            path: '/',
            secure: false,  // Solo enviar a través de HTTPS
            httpOnly: false, // True si el backend maneja cookies HttpOnly
            sameSite: 'lax', // O 'strict' dependiendo de tu configuración
        });

        setCookie(null, 'environmentName', `${containerName}`, {
            maxAge: 30 * 60,
            path: '/',
            secure: false,  // Solo enviar a través de HTTPS
            httpOnly: false, // True si el backend maneja cookies HttpOnly
            sameSite: 'lax', // O 'strict' dependiendo de tu configuración
        });
        setCookie(null, 'environmentId', `${id}`, {
            maxAge: 30 * 60,
            path: '/',
            secure: false,  // Solo enviar a través de HTTPS
            httpOnly: false, // True si el backend maneja cookies HttpOnly
            sameSite: 'lax', // O 'strict' dependiendo de tu configuración
        });
        if (container.State.Status !== 'running') {
            await startContainer(cont.Names[0].split("/")[1]);

            const interval = 5000;

            const checkProcessInterval = setInterval(async () => {
                const cont = await getContainerProcesses(`${jsonData.userId}-${containerName}`);
                if (isProcessPresent(cont.Processes, processToFind)) {
                    clearInterval(checkProcessInterval);
                    navigate('/');
                } else {
                    console.log("El proceso no está presente");
                }
            }, interval);
        
        } else {
            navigate('/');
        }
    }

    const handleCreateNewContainer = async(containers, containerName, lastUsedId) =>{
        const unavailablePorts = [];
        for (const ct of containers){
            console.log(ct)
            const container = await getContainer(ct.Names[0].split("/")[1]);
            unavailablePorts.push(parseInt(container.HostConfig.PortBindings["8000/tcp"][0].HostPort))
            console.log(unavailablePorts);
        }
        const startPort = 8001;
        const endPort = 8999;

        const port = findAvailablePort(startPort, endPort, unavailablePorts);
        await createContainer(jsonData.userId, containerName, port);
        await startContainer(`${jsonData.userId}-${containerName}`);
        const container = await getContainer(`${jsonData.userId}-${containerName}`);
        console.log('Env id: '+lastUsedId)
        var envResponse;
        if (lastUsedId){
            envResponse = await updateEnvironment(lastUsedId, null, port, true)
        } else {
            envResponse = await saveEnvironment(containerName, port, true)
        }

        if (container.State.Status === 'running'){
            
            setCookie(null, 'apiPort', port, {
                maxAge: 30 * 60,
                path: '/',
                secure: false,  // Solo enviar a través de HTTPS
                httpOnly: false, // True si el backend maneja cookies HttpOnly
                sameSite: 'lax', // O 'strict' dependiendo de tu configuración
              });
            setCookie(null, 'environmentName', `${containerName}`, {
                maxAge: 30 * 60,
                path: '/',
                secure: false,  // Solo enviar a través de HTTPS
                httpOnly: false, // True si el backend maneja cookies HttpOnly
                sameSite: 'lax', // O 'strict' dependiendo de tu configuración
            });
            setCookie(null, 'environmentId', `${envResponse._id}`, {
                maxAge: 30 * 60,
                path: '/',
                secure: false,  // Solo enviar a través de HTTPS
                httpOnly: false, // True si el backend maneja cookies HttpOnly
                sameSite: 'lax', // O 'strict' dependiendo de tu configuración
            });
              const interval = 5000;

              const checkProcessInterval = setInterval(async () => {
                  const cont = await getContainerProcesses(`${jsonData.userId}-${containerName}`);
                  console.log(cont)
                  if (isProcessPresent(cont.Processes, processToFind)) {
                    clearInterval(checkProcessInterval);
                    navigate('/');
                  } else {
                      console.log("El proceso no está presente");
                  }
              }, interval);
          
        }
    }
    useEffect(()=>{
        const handlePrelaod = async () =>{
            const token = cookies.accessToken;
            if (!token){
                navigate("/auth")
            }
            if (process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG === "true"){
                setCookie(null, 'environmentId', `12935435-792e-4754-a4cf-b54b80e7f2f7`, {
                    maxAge: 30 * 60,
                    path: '/',
                    secure: false,  // Solo enviar a través de HTTPS
                    httpOnly: false, // True si el backend maneja cookies HttpOnly
                    sameSite: 'lax', // O 'strict' dependiendo de tu configuración
                });
                setCookie(null, 'environmentName', `default`, {
                    maxAge: 30 * 60,
                    path: '/',
                    secure: false,  // Solo enviar a través de HTTPS
                    httpOnly: false, // True si el backend maneja cookies HttpOnly
                    sameSite: 'lax', // O 'strict' dependiendo de tu configuración
                });
                navigate('/');
                return
            }
          
            var userId = jsonData.userId;
            if (userId === ''){
                
                const decodedJWT = decodeJWT(token);
                jsonData.userId = decodedJWT.uuid;
                jsonData.username = decodedJWT.username;
                userId = decodedJWT.uuid;
            }
           
            if (preloadCalled.current) return; 
            preloadCalled.current = true;
            
            try{
                const userContainers = await getEnvironments();
                const containers = await getContainers();
                if (userContainers.results.length > 0){
                    const lastUsed = userContainers.results.find(env => env.last_used === true);
                    const containerName = `${userId}-${lastUsed.name}`
                    const port = lastUsed.port;
                    const cont = containers.find(ct => ct.Names.includes(`/${containerName}`));
                    if (cont) {
                        handleRunExistingContainer(cont, lastUsed.name, lastUsed.port, lastUsed._id);
                    } else {
                        handleCreateNewContainer(containers, lastUsed.name, lastUsed._id);
                    }

                } else{
                    handleCreateNewContainer(containers, `default`)
                    
                }
            
            } catch (error) {
                console.log(error)
            } finally {
                preloadCalled.current = false;
            }
        }
        handlePrelaod();
    }, [])
    

    const style = {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%', 
        height: '100vh',
        flexDirection: 'column'
    }

    // const styleEnvironmentsContainer = {
    //     display: 'flex', 
    //     alignItems: 'center', 
    //     justifyContent: 'center', 
    //     width: '100%', 
    //     height: '100vh',
    //     flexDirection: 'column'
    // }
    //   const userId = useRef('');

    //   useEffect(()=>{
    //     const handlePrelaod = async () =>{
    //         const token = cookies.accessToken;
    //         if (!token){
    //             navigate("/auth")
    //         }
    //         userId.current = jsonData.userId;
    //         if (userId.current === ''){
                
    //             const decodedJWT = decodeJWT(token);
    //             jsonData.userId = decodedJWT.uuid;
    //             jsonData.username = decodedJWT.username;
    //             userId.current = decodedJWT.uuid;
    //         }
           
    //         if (preloadCalled.current) return; 
    //     }
    //         handlePrelaod();
    // }, [])

    // const handleGetEnvironments = async () =>{
        
    //     try{
    //         const containers = await getContainers();

    //         const cont = containers.find(ct => ct.Names.includes(`/${userId}-default`));
            
    //         if (cont) {
    //             const container = await getContainer(`${userId}`);
    //             const port = container.HostConfig.PortBindings["8000/tcp"][0].HostPort;
    //         }
    //         const response = await getEnvironments();
    //         setEnvironments([...response.map(env => ({
    //             id: env._id, // Ajusta según la estructura de tus datos
    //             name: env.name, // Ajusta según la estructura de tus datos
    //           }))]);
    
    //     } catch(error){
    //       console.log("Error in handle get environments:" + error)
    //     }
        
        
    //   }

    // useEffect(()=>{
    //     handleGetEnvironments()
    // },[environments])

   

  
      

    //   const handleEnvironmentSelect = (envId, ) => {
    //     const selectedEnvironment = environments.find(env => env.id === envId);
        
    //     if (selectedEnvironment) {
    //         console.log('Selected Environment Name:', selectedEnvironment.name);
    //     } else {
    //         console.log('Environment not found');
    //     }
    //   };
    
    //   const handleCreateEnvironment = async (envName) => {
        
    //     const unavailablePorts = [];
    //     try{
    //         setLoading(true);
    //         const containers = await getContainers();
    //         for (const ct of containers){
    //             console.log(ct)
    //             const container = await getContainer(ct.Names[0].split("/")[1]);
    //             unavailablePorts.push(parseInt(container.HostConfig.PortBindings["8000/tcp"][0].HostPort))
    //             console.log(unavailablePorts);
    //         }
    //         const startPort = 8001;
    //         const endPort = 8999;
    //         const port = findAvailablePort(startPort, endPort, unavailablePorts);
    //         await createContainer(userId.current, envName, port);
    //         const newEnv = { id: `env${environments.length + 1}`, name: envName };
    //         setEnvironments([...environments, newEnv]);
    //         message.success('Environment successfully created')
    //     } catch(error){
    //         message.error('Error creating environment')
    //     } finally {
    //         setLoading(false)
    //     }
    //   };
    return (
        <>
        {/* <div className='environments-container' style={styleEnvironmentsContainer}>
        <h2>Select an Environment</h2>
        <EnvironmentSelector
        environments={environments}
        onSelectEnvironment={handleEnvironmentSelect}
        onCreateEnvironment={handleCreateEnvironment}
        loading={loading}
      />

        </div> */}
         <div style={style}>
            <h2>
            We are preparing your environment, please wait until the process is finished</h2>
            <Spin size="large" />
        </div>

        </>
       
    )
}

export default PreloadComponent;