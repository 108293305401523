import { get, post } from "./apiUtils";

export const getContainers = async () => {
    const filters = {
        ancestor: ["pipeline:1.2"]
    };
    
    // Codifica el filtro en formato JSON
    const filtersJson = JSON.stringify(filters);
    try {
        const response = await get(process.env.REACT_APP_DOCKER_API_URL, `/containers/json?all=true&filters=${filtersJson}`)
        return response;
    } catch (error) {
        throw error;
    }

}

export const getContainer = async (container) => {
    try {
        const response = await get(process.env.REACT_APP_DOCKER_API_URL, `/containers/${container}/json`)
        return response;
    } catch (error) {
        throw error;
    }

}

export const getContainerProcesses = async (container) => {
    try {
        const response = await get(process.env.REACT_APP_DOCKER_API_URL, `/containers/${container}/top`)
        return response;
    } catch (error) {
        throw error;
    }

}

export const getContainerStats = async (container) => {
    try {
        const response = await get(process.env.REACT_APP_DOCKER_API_URL, `/containers/${container}/stats?stream=false`)
        return response;
    } catch (error) {
        throw error;
    }

}

export const startContainer = async (container) => {
    try {
        const response = await post(process.env.REACT_APP_DOCKER_API_URL, `/containers/${container}/start`)
        return response;
    } catch (error) {
        throw error;
    }

}

export const stopContainer = async (container) => {
    try {
        const response = await post(process.env.REACT_APP_DOCKER_API_URL, `/containers/${container}/stop`)
        return response;
    } catch (error) {
        throw error;
    }

}
export const createContainer = async (userId, name, port) => {
    const requestData = {
        "Image": "pipeline:1.2",
        "ExposedPorts": {
            "8000/tcp": {}
        },
        "HostConfig": {
            "Binds": [
            `/root/${userId}/workspace:/workspace`,
            // `/root/${userId}/${name}/usr/bin:/usr/bin`,
            // `/root/${userId}/${name}/usr/local:/usr/local`,
            // `/root/${userId}/${name}/environments:/environments`,
            '/root/models:/app/models'  // Ajusta la ruta según las necesidades
            
        ],
        "PortBindings": {
        "8000/tcp": [
            {
                "HostPort": port.toString()   // Cambia el puerto si es necesario
            }
        ],
     
        }
        },
        "Cmd": ["/start"],  // Comando para ejecutar dentro del contenedor
        "NetworkMode": "local_network"  // Asegúrate de que esta red esté configurada
       
    }
    const headers = { 'Content-Type': 'application/json' }
    try {
        const response = await post(process.env.REACT_APP_DOCKER_API_URL, `/containers/create?name=${userId}-${name}`, requestData, headers)
        return response;
    } catch (error) {
        throw error;
    }

}