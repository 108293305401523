import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

const ProfileImageComponent = ({ onFileChange, profile_image }) => {
  const [fileList, setFileList] = useState([]);

  // Efecto para actualizar fileList cuando profile_image cambie
  useEffect(() => {
    if (profile_image && profile_image.url) {
      setFileList([
        {
          uid: '-1',
          name: 'profile-image',
          status: 'done',
          url: profile_image.url,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [profile_image]); // Escuchamos los cambios en el key
  

  useEffect(() => {
    if (onFileChange) {
      onFileChange(fileList);
    }
  }, [fileList, onFileChange]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (onFileChange) {
      onFileChange(newFileList);
    }
  };

  const onPreview = async (file) => {
    let src = file.url || (await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    }));

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ImgCrop rotationSlider>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={1}
          customRequest={({ file, onSuccess }) => {
            setFileList([file]);
            onSuccess(null, file);
          }}
        >
          {fileList.length === 0 && '+ Upload'}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default ProfileImageComponent;
