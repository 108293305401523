// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Auth from './components/auth/auth';
import PreloadComponent from './components/preload/preloadComponent';
import TryCodeComponent from './components/iframe/tryCodeComponent';
import { ConfigProvider } from 'antd';
import { themeConfig } from './themeConfig';
import LandingComponent from './components/landing/landing';

function App() {
    return (
        <Router>
             <div className="app">
             <ConfigProvider theme={themeConfig}>
                 <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/auth" element={<Auth />}></Route>
            <Route path="/preload" element={<PreloadComponent />}></Route>
            <Route path="/landing" element={<LandingComponent/>}></Route>
            <Route path="/try/:token/" element={<TryCodeComponent/>}></Route>

     
            </Routes>
            </ConfigProvider>
             </div>
       
    </Router>
    );
}

export default App;
