import React, { useState, useEffect, useContext, useRef } from 'react';
import { DndContext, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PointerSensor } from '@dnd-kit/core';
import BlockComponent from "./blockComponent";  
import AddBlockComponent from './addBlockComponent';
import { processNode } from '../../../utils/nodeUtils';
import { DataContext } from '../../../utils/dataContext';
import {
  restrictToVerticalAxis,
  restrictToParentElement
} from "@dnd-kit/modifiers";
import { parseCookies } from 'nookies';
import { generateLabel } from '../../../utils/labels';
import { Select, Radio, Spin, message } from 'antd';
import { SnippetsOutlined, ScissorOutlined, CopyOutlined, LoadingOutlined, CaretRightOutlined, ReloadOutlined, BorderOutlined, PauseOutlined, DeleteOutlined } from '@ant-design/icons';
class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false; 
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "svg",
    "label",
    "span",
    "textarea",
    "select",
    "option",
    "slider", // Elemento slider personalizado
    "ant-slider-handle", // Manejador de slider de Ant Design
    "ant-slider-track", // Pista de slider de Ant Design
    "ant-slider-rail", // Riel de slider de Ant Design
    "ant-select-selection-item",
    "ant-select-item-option-content",
    "ant-radio-button",
    "ant-switch-handle",
    "ant-slider-handle",
    "view-line",
    "card",
    "rc-virtual-list-scrollbar-thumb",
    "ant-select-item ant-select-item-option",
    "pre-output",
    "line-numbers"
  ];
  // Verifica si el elemento es interactivo según su etiqueta o clase
  if (
    element &&
    (
      (element.tagName && interactiveElements.includes(element.tagName.toLowerCase())) ||
      (element.classList && Array.from(element.classList).some(cls => interactiveElements.includes(cls)))
    )
  ) {
    return true;
  }

  return false;
}


function TabContentComponentDev({ tabId }) {
  const { jsonData } = useContext(DataContext);
  const {currentEnvironment, setCurrentEnvironment} = useContext(DataContext);
  const { blockToCopy, setBlockToCopy } = useContext(DataContext);
  const { blockToCut, setBlockToCut } = useContext(DataContext);
  const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
  const [blocks, setBlocks] = useState(jsonDataTab.blocks || []);
  const [activeBlock, setActiveBlock] = useState(null);
  // const [socket, setSocket] = useState(null);
  const cookies = parseCookies();
  const [spinnerPlay, setSpinnerPlay] = useState(false);
  const [spinnerRestart, setSpinnerRestart] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const completedBlocks = useRef(false);
  
  const [totalBlocks, setTotalBlocks] = useState(0)
  const socket = useRef(false)


  const handleSetBlock = async (type, content, context) => {
    try {
      const response = await processNode('');
      const blockData = {
        blockId: response.node_id,
        blockType: type,
        input: content ? content: '',
        output: '',
        origin: context,
        label: generateLabel(),
        active: true
      };
  
      // Update the jsonDataTab and set the new state for blocks
      jsonDataTab["blocks"] = [...jsonDataTab["blocks"], blockData];

      await processNode(blockData, null, response.node_id);
      
      // Update the state with the new block
      setBlocks([...blocks, blockData]);
      await handleUpdateTab();
  
      if (context && context === 'user') {
        const event = new CustomEvent('aggregatedBlockByChat', { detail: content });
        document.dispatchEvent(event);
      
      }
    } catch (error) {
      console.error('Error adding new block:', error);
    }
  };

  const handleDeleteBlock = async (blockIdToDelete) => {
    // Remove the block from the jsonDataTab
    jsonDataTab["blocks"] = jsonDataTab["blocks"].filter(block => block.blockId !== blockIdToDelete);
    
    // Update the state without the deleted block
    setBlocks(prevBlocks => prevBlocks.filter(block => block.blockId !== blockIdToDelete));
    await handleUpdateTab();
  };

  const handleMenuClick = ({ key }) => {
    handleSetBlock(key, '', 'menu');
  };

  useEffect(() => {
    const handlerAddBlock = (event) => {
      console.log(event.detail.tabId)
      console.log(event.detail)
      console.log(tabId)
      if (event.detail.tabId == tabId){
        handleSetBlock(event.detail.type, event.detail.content, event.detail.context);
      }
      
    };

    const handlerPasteBlock = async (event) => {
      const blockToPaste = event.detail.blockToPaste;
      const targetTabId = event.detail.tabId;
      const from = event.detail.from;

      const handleUpdateTargetTab = async () =>{
        const parentTab = jsonData["tabs"].find(item => item.tabId === blockToPaste.tabId);
        const blockData = parentTab["blocks"].find(item => item.blockId === blockToPaste.blockId)
        try{
          jsonDataTab["blocks"] = [...jsonDataTab["blocks"], blockData];
          await handleUpdateTab();
          setBlocks([...blocks, blockData]);
          message.info(`Block ${blockData.label} pasted`)
        }
        catch(error){
          message.error(`Failed to paste ${blockData.label} block`)
        }
      }
      if (targetTabId == tabId){
          await handleUpdateTargetTab();
      }
      setTimeout(async ()=>{
        if (from === 'cut'){
          if (blockToPaste.tabId === tabId) {
            await handleDeleteBlock(blockToPaste.blockId);
          }
        }
      }, 2000)

      setBlockToCopy(
        {
          blockId: null,
          tabId: null
        }
      )
      setBlockToCut(
        {
          blockId: null,
          tabId: null
        }
      )
      
      
    };

    const handlerDeleteBlock = async (event) =>{
      const targetTabId = event.detail.tabId;
      const blockId = event.detail.blockId;
      const blockData = jsonDataTab["blocks"].find(item => item.blockId === blockId);
      if (targetTabId == tabId){
        await handleDeleteBlock(blockId);
        message.info(`Deleted ${blockData.label} block`)
      }
    }

    document.addEventListener('addBlock', handlerAddBlock);
    document.addEventListener('pasteBlock', handlerPasteBlock);
    document.addEventListener('deleteBlock', handlerDeleteBlock);

    return () => {
      document.removeEventListener('addBlock', handlerAddBlock);
      document.removeEventListener('pasteBlock', handlerPasteBlock);
      document.removeEventListener('deleteBlock', handlerDeleteBlock);
    };
  }, [blocks]);

  const sensors = useSensors(
    useSensor(SmartPointerSensor, { activationConstraint: { distance: 10 } }),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates
    // })
  );

  const handleUpdateTab = async () =>{
    const dataTab = { ...jsonDataTab };
    const blockIds = jsonDataTab.blocks.map(block => block.blockId);
    dataTab.blocks = blockIds;
    await processNode(dataTab, null, jsonDataTab.tabId);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
  
   
    if (!active || !over) {
      return;
    }
  
    if (active.id !== over.id) {
      setBlocks((items) => {
        const oldIndex = items.findIndex(item => item.blockId === active.id);
        const newIndex = items.findIndex(item => item.blockId === over.id);
  
    
        const reorderedItems = arrayMove(items, oldIndex, newIndex);
  

        jsonDataTab.blocks = reorderedItems.map(item => ({
          ...item,

        }));
        handleUpdateTab();
   
        return reorderedItems;
      });
    }
  };
  
  
  const handleBlockClick = (blockId) => {
    setActiveBlock(blockId);
  };

  const connectWebSocket = () =>{
    return new Promise((resolve, reject) => {
      if (!socket.current) {
        const apiPort = cookies.apiPort;
        var url = '/ws/code/';
        if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
            url = `/pipeline/port/${apiPort}${url}`
        }
        socket.current = new WebSocket(process.env.REACT_APP_PIPELINE_WEBSOCKET_URL + url);
        

        socket.current.onopen = () => {
          console.log('Connected to WebSocket');
          resolve();
        };

        socket.current.onmessage = (event) => {
          const msg = JSON.parse(event.data);
          console.log(msg)
          if (msg.type === 'mkdir' && msg.output === 'Directory created'){
            const eventFiles = new CustomEvent('getFiles', { detail: true });
            document.dispatchEvent(eventFiles);
          } else if (msg.type === 'kernel' && msg.status === 'success'){
            message.info("Kernel successfuly restarted")
            setSpinnerRestart(false);
          } else {
            const eventCodeConsumer = new CustomEvent('codeConsumerMsgReceived', { detail: {tabId: tabId, message: msg }});
            document.dispatchEvent(eventCodeConsumer);
          }
          
    
        };

        socket.current.onclose = () => {
          console.log('Disconnected from WebSocket');
          socket.current = null; 
        };

        socket.current.onerror = (error) => {
          console.error('WebSocket error:', error);
          reject(error);
        };

        

      } else if (socket.current.readyState === WebSocket.OPEN) {
        resolve();  // Resuelve la promesa inmediatamente si ya está conectado
      } else if (socket.current.readyState === WebSocket.CONNECTING) {
          socket.current.onopen = () => {
              resolve();  // Resuelve la promesa cuando el WebSocket termine de conectarse
          };
          socket.current.onerror = (error) => {
              reject(error);  // Rechaza la promesa si ocurre un error durante la conexión
          };
      }
    });

    

  }
  useEffect(() => {
    return () => {
        if (socket.current) {
            socket.current.close();
        }
    };
}, []);
  

  const handlePlayAllButton = () => {
    const blocks = jsonDataTab.blocks;
    const activeBlocks = jsonDataTab.blocks.filter(block => block.active === true);
    setTotalBlocks(activeBlocks.length);
  
    blocks.forEach((block, index) => {
      setSpinnerPlay(true);
      completedBlocks.current = 0;
      const delay = index * 1000; // Retraso de 1 segundo por cada bloque
  
        setTimeout(() => {
          if (block.active){
            const eventRunAll = new CustomEvent('runAllCode', {
              detail: { tabId: tabId, blockId: block.blockId },
            });
            document.dispatchEvent(eventRunAll);
          }
        }, delay);
    });
  };

  const handlePauseAllButton = () => {
    const blocks = jsonDataTab.blocks;
    const activeBlocks = jsonDataTab.blocks.filter(block => block.active === true);

  
    blocks.forEach((block, index) => {
      setSpinnerPlay(false);
      completedBlocks.current = 0;
      const delay = index * 1000; // Retraso de 1 segundo por cada bloque
  
        setTimeout(() => {
          if (block.active){
            const eventPauseAll = new CustomEvent('pauseAllCode', {
              detail: { tabId: tabId, blockId: block.blockId },
            });
            document.dispatchEvent(eventPauseAll);
          }
        }, delay);
    });
  };

  const handleStopAllButton = () => {
    const blocks = jsonDataTab.blocks;
    const activeBlocks = jsonDataTab.blocks.filter(block => block.active === true);

  
    blocks.forEach((block, index) => {
      setSpinnerPlay(false);
      completedBlocks.current = 0;
      const delay = index * 1000; // Retraso de 1 segundo por cada bloque
  
        setTimeout(() => {
          if (block.active){
            const eventPauseAll = new CustomEvent('stopAllCode', {
              detail: { tabId: tabId, blockId: block.blockId },
            });
            document.dispatchEvent(eventPauseAll);
          }
        }, delay);
    });
  };

  const handleRestartKernelButton = () => {
    setSpinnerRestart(true);
    const codeBlocks = jsonDataTab.blocks.filter(block => 
      block.blockType === 'code' && block.language === 'python'
    );

    if (codeBlocks.length > 0){
      const eventRestartKernel = new CustomEvent('restartKernel', {
        detail: { tabId: tabId, blockId: codeBlocks[0].blockId },
      });
      document.dispatchEvent(eventRestartKernel);
    } else {
      message.info('No python code blocks available');
      setSpinnerRestart(false);
    }
  };
  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);

    setTimeout(() => {
      setSelectedValue(null); // Deselecciona el radio button después de 1 segundo
    }, 200);
  };

  useEffect(() => {
    const handleBlockCompletion = (event) => {
        const { blockId } = event.detail;

        const blockIdExists = jsonDataTab.blocks.some(block => block.blockId === blockId);
        if (blockIdExists) {
          completedBlocks.current = completedBlocks.current + 1; // Incrementa el contador de bloques completados
        }
        
        // Lógica para actualizar el estado o manejar la finalización del bloque
        
        if (completedBlocks.current + 1 === totalBlocks) { // Verifica si todos los bloques han terminado
            setSpinnerPlay(false); // Detiene el spinner
        }
    };

    document.addEventListener('blockExecutionComplete', handleBlockCompletion);

    return () => {
        document.removeEventListener('blockExecutionComplete', handleBlockCompletion);
    };
}, [completedBlocks, totalBlocks]);

  


  return (
    <>
    <div style={{display: 'flex', margin: '5px 10px 5px 5px'}} className='tab-buttons'>
    <Radio.Group style={{ display: 'inline-flex', marginLeft: '5px' }} onChange={handleRadioChange} value={selectedValue}>
                    <Radio.Button title='Restart kernel' value="refresh" onClick={handleRestartKernelButton}>
                      
                      {!spinnerRestart && (<ReloadOutlined />)}
                        
                        {spinnerRestart && (
                            <Spin indicator={<LoadingOutlined spin />} size="small" />
                        )}
                    </Radio.Button>
                    <Radio.Button title='Stop all' value="stop" onClick={handleStopAllButton}><BorderOutlined /></Radio.Button>
                    <Radio.Button title='Pause all' value="pause" onClick={handlePauseAllButton}><PauseOutlined /></Radio.Button>
                    <Radio.Button title='Play all' value="play-all" onClick={handlePlayAllButton}>
                        {!spinnerPlay && (<CaretRightOutlined />)}
                        
                        {spinnerPlay && (
                            <Spin indicator={<LoadingOutlined spin />} size="small" />
                        )}
                    </Radio.Button>
                </Radio.Group>
                
    
                
                <div className='right-side-buttons'>
                <Radio.Group style={{marginRight: '5px'}}>
                    <Radio.Button value="copy"  style={{margin: 0}}><CopyOutlined /></Radio.Button>
                    <Radio.Button value="cut"  style={{margin: 0}}><ScissorOutlined /></Radio.Button>
                    <Radio.Button value="paste" style={{margin: 0}}><SnippetsOutlined /></Radio.Button>
                    <Radio.Button value="delete"  style={{margin: 0}}><DeleteOutlined /></Radio.Button>
                    </Radio.Group>
                </div>

   </div>
    <DndContext modifiers={[restrictToParentElement, restrictToVerticalAxis]} sensors={sensors} onDragEnd={handleDragEnd} >
      <SortableContext items={blocks.map(block => block.blockId)} strategy={verticalListSortingStrategy}>
        <div className='content-container'>
          {blocks.map((block) => (
            <SortableBlock 
              key={block.blockId} 
              block={block} 
              tabId={tabId} 
              onDelete={handleDeleteBlock} 
              onClick={handleBlockClick}
              isActive={block.blockId === activeBlock}
              socket={socket}
              connectWebSocket={connectWebSocket}
              />
          ))}
          <AddBlockComponent onMenuClick={handleMenuClick} />
        </div>
      </SortableContext>
    </DndContext>
    </>
  );
}

function SortableBlock({ block, tabId, onDelete, onClick, isActive, socket, connectWebSocket }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: block.blockId });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    
  };
  
  const blockStyles = {
    border: isActive ? '1.4px solid #1677ff' : '1.4px solid #d9d9d9', // Estilo para el bloque activo
    background: isActive ? 'linear-gradient(90deg, #1677ff 0.5%, rgba(245, 255, 248, 0) 0.5%)':'linear-gradient(90deg, #aaaaaa 0.5%, rgba(245, 255, 248, 0) 0.5%)'
  }

  return (
  
  
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} onClick={() => onClick(block.blockId)}>
      <BlockComponent key={block.blockId} value={block.blockType} tabId={tabId} blockId={block.blockId} label={block.label} onDelete={onDelete} styles={blockStyles} isActive={isActive} socket={socket} openSocket={connectWebSocket}/>
    </div>

  );
}

export default TabContentComponentDev;
