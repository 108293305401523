import React from 'react';
import '../../styles/main.css';
import '../../styles/blockStyles.css';
import LeftSideComponent from './sidebar/leftSideComponent';
import RightSideComponent from './sidebar/rightSideComponent';
import TopLeftComponent from './header/topLeftComponent';
import TopRightComponent from './header/topRightComponent';
import InputMlComponent from './footer/inputMlComponent';
import TabsComponent from './content/tabsComponent';
import { decodeJWT } from '../../utils/authApi';
import { parseCookies } from 'nookies';
import { useState, useEffect, useContext } from 'react';
import { checkToken } from '../../utils/authApi';
import { useNavigate } from 'react-router-dom';
import { getContainers } from '../../utils/dockerApi';
import { DataContext } from '../../utils/dataContext';
import { Spin, message } from 'antd';


function Home () {
    const [isDev, setIsDev] = useState(true);
    const cookies = parseCookies();
    const navigate = useNavigate();
    const {jsonData} = useContext(DataContext);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        const handleCheckToken = async () =>{
            const token = cookies.accessToken;
            const environmentName = cookies.environmentName;
            if (token) {
                const response = await checkToken(token);
                if (response == 'Invalid token'){
       
         
                    navigate('/auth');
                }
                console.log(typeof(process.env.REACT_APP_DEBUG))
                if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
                    const containers = await getContainers();
                    const cont = containers.find(ct => ct.Names.includes(`/${jsonData.userId}-${environmentName}`));
                    if(!cont || cont.State === "exited"){
                        navigate('/preload')
                    }
                }
                
                
            } else {
                navigate('/auth');
            }
          
        }
        handleCheckToken();

        const handleOpeningTabs = (event)=>{
            console.log('llegó');
            setLoading(event.detail)
            if (event.detail){
                message.info('Opening tabs...')
            } else{
                message.success('Tabs loaded')
            }
          }
          document.addEventListener('openingTabs', handleOpeningTabs);

          return () => {
            document.removeEventListener('openingTabs', handleOpeningTabs);
            
      
          };
       
    }, [])

   
    return (



  <div className="container">
    <div className="bottom_bar">
        <InputMlComponent/>
    </div>
      
    <div className="right_side">
        <RightSideComponent/>

    </div>
    
    <div className="left_side">
        
        <LeftSideComponent/>
 
    </div>
    
    
    <div className="top_left_side">
        <TopLeftComponent></TopLeftComponent>
        
    </div>
    
    <div className="top_right_side">
        <TopRightComponent setIsDev={setIsDev} isDev={isDev}/>
    </div>

      
    <div className="content_area">
    {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
        <TabsComponent isDev={isDev}/>
    </div>


      
    
  </div>


);
}

export default Home;
