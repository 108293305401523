import { Form, Button, Spin, Input, message } from "antd";
import { useState } from "react";
import { changePasswordToken } from "../../utils/authApi";

function ResetPasswordComponent ({token}) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const passwordValidator = (_, value) => {
        if (!value) {
          return Promise.reject('Please input your password!');
        }
    
        // Validación de cada requisito
        const minLength = 8;
        const maxLength = 64;
        const hasUpperCase = /[A-Z]/;
        const hasLowerCase = /[a-z]/;
        const hasNumber = /[0-9]/;
        const hasSpecialChar = /[$¡!|/+.()°{}_<>;:%%*@#¿?&]/;
    
        if (value.length < minLength || value.length > maxLength) {
          return Promise.reject(`Password must be between ${minLength} and ${maxLength} characters.`);
        }
        if (!hasUpperCase.test(value)) {
          return Promise.reject('Password must include at least one uppercase letter.');
        }
        if (!hasLowerCase.test(value)) {
          return Promise.reject('Password must include at least one lowercase letter.');
        }
        if (!hasNumber.test(value)) {
          return Promise.reject('Password must include at least one number.');
        }
        if (!hasSpecialChar.test(value)) {
          return Promise.reject('Password must include at least one special character.');
        }
    
        return Promise.resolve();
      };

      const repeatPasswordValidator = (_, value) => {

        if (!value) {
          return Promise.reject("Please input your password!");
        }
        if (form.getFieldValue("new_password") !== value) {
          return Promise.reject("Password do not match!");
        }
        return Promise.resolve();
      };

      const onFinish = async (values) => {
        setLoading(true);
        try {
            values.token = token
            const response = await changePasswordToken(values);
            message.success("Password successfuly changed, plase login to your account")
        } catch (error){
            message.error("Error changing password")
          
        
        } finally{
          setLoading(false);
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return (
        <Form
        name="reset-password"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        requiredMark={false}
        
      >
        {loading && (
            <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
          )}
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[{ validator: passwordValidator}]}
        >
          <Input.Password />
        </Form.Item>
  
        <Form.Item
          label="Repet password"
          name="new_password_confirmation"
          rules={[{ validator: repeatPasswordValidator }]}
        >
          <Input.Password />
        </Form.Item>
  
     
  
        <Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: '10px' }}>
        
  
            <Button type="primary" htmlType="submit">
              Reset password
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
}

export default ResetPasswordComponent;