import React, {useState } from "react";
import { UserOutlined, SettingOutlined, LogoutOutlined, MonitorOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Segmented, Spin} from "antd";
import ProfileComponent from "../profile/profileComponent";
import PostComponent from "./postComponent";
import { useNavigate, } from 'react-router-dom';
import { stopContainer } from "../../../utils/dockerApi";
import { DataContext } from "../../../utils/dataContext";
import { useContext } from "react";
import SystemMonitorComponent from "./systemMonitorComponent";
import { parseCookies, destroyCookie } from 'nookies';


function TopRightComponent({setIsDev, isDev}) {
  const { jsonData } = useContext(DataContext);
  const [showProfile, setShowProfile] = useState(false);
  const [showMonitor, setShowMonitor] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const navigate = useNavigate();
  const cookies = parseCookies();
  const [loading, setLoading] = useState(false);
  const [avatarImage, setAvatarImage] = useState(null);
  

  const handleLogOut = () =>{
    setLoading(true);
    const environmentName = cookies.environmentName;
    destroyCookie(null, 'accessToken');
    stopContainer(`${jsonData.userId}-${environmentName}`);
    navigate('/auth')
  }
  
  const handleMenuClick = async (e) => {
    const key = e.key;
    switch (key) {
      case "account-settings":
        setShowProfile(true);
        break;
      case "system-monitor":
        setShowMonitor(true);
        break;
      case "logout":
        handleLogOut();
        break
      default:
        break;
    }
  };
  const items = [
    {
      label: "Account settings",
      key: "account-settings",
      icon: <SettingOutlined/>
    },
    {
      label: "System Monitor",
      key: "system-monitor",
      icon: <MonitorOutlined />
    },
    {
      label: "Help",
      key: "3",
      icon: <QuestionCircleOutlined />
    },
    {
      label: "About",
      key: "4",
      icon: <InfoCircleOutlined />
    },
    {
      label: "Log-out",
      key: "logout",
      icon: <LogoutOutlined />
    }
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const containerStyles = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    flexDirection: "row",
    justifyContent: "right",
    marginRight: "20px",
  };

  const handleChangeMode = (value) =>{
    if (value === 'Dev'){
      setIsDev(true);
    } else {
      setIsDev(false);
    }
  }

  return (
    <>
     {loading && (
        <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}></Spin>
      )}
      <div style={containerStyles}>
        {/* <Button onClick={()=>{setShowPost(true)}}>New Post</Button> */}
        <Segmented
          options={['Normal', 'Dev']}
          onChange={handleChangeMode}
          value={isDev ? 'Dev': 'Normal'}
        />
        <Dropdown
          menu={menuProps}
          style={{ width: "auto" }}
          trigger={["click"]}
        >
          <Avatar 
          size={32} 
          icon={!avatarImage? <UserOutlined /> : null} 
          src={avatarImage? avatarImage : null}
          />
        </Dropdown>
        <ProfileComponent showProfile={showProfile} setShowProfile={setShowProfile} jsonData={jsonData} setAvatarImage={setAvatarImage}/>
        <SystemMonitorComponent showMonitor={showMonitor} setShowMonitor={setShowMonitor} jsonData={jsonData}/>
        {/* <PostComponent showPost={showPost} setShowPost={setShowPost} /> */}
      </div>
    </>
  );
}
export default TopRightComponent;
