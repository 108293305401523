import React, { useRef, useState, useEffect, useContext } from 'react';
import { Tabs, Button, Dropdown, Divider, Input, Popover, message } from 'antd';
import {SnippetsOutlined, PlusOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, CopyOutlined, ScissorOutlined } from '@ant-design/icons';
import TabContentComponentDev from './tabContentComponentDev';
import TabContentComponentNormal from './tabContentComponentNormal';
import { DndContext, PointerSensor, closestCenter, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import SaveProcessComponent from './saveProcessComponent';
import { CSS } from '@dnd-kit/utilities';
import { processNode, getNodeContent, getTabContent } from '../../../utils/nodeUtils';
import { DataContext } from '../../../utils/dataContext';
import { decodeJWT } from '../../../utils/authApi';
import { parseCookies } from 'nookies';
import {
  restrictToHorizontalAxis,
  restrictToParentElement
} from "@dnd-kit/modifiers";
import { generateLabel } from '../../../utils/labels';



class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false; 
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "svg",
    "label",
    "span",
    "textarea",
    "select",
    "option",
    "slider", // Elemento slider personalizado
    "ant-slider-handle", // Manejador de slider de Ant Design
    "ant-slider-track", // Pista de slider de Ant Design
    "ant-slider-rail", // Riel de slider de Ant Design
    "ant-select-selection-item",
    "ant-select-item-option-content",
    "ant-radio-button",
    "ant-switch-handle",
    "ant-slider-handle",
    "view-line",
    "ant-popover-title",
    "ant-popover-inner",
    "card"
  ];

  // Verifica si el elemento es interactivo según su etiqueta o clase
  if (
    element &&
    (
      (element.tagName && interactiveElements.includes(element.tagName.toLowerCase())) ||
      (element.classList && Array.from(element.classList).some(cls => interactiveElements.includes(cls)))
    )
  ) {
    return true;
  }

  return false;
}
const TabsComponent = ({isDev}) => {
  const { jsonData } = useContext(DataContext);
  const { blockToCopy, setBlockToCopy } = useContext(DataContext);
  const { blockToCut, setBlockToCut } = useContext(DataContext);
  const [activeKey, setActiveKey] = useState(null); // Cambiado a null inicialmente
  const [items, setItems] = useState([]);
  const addCalled = useRef(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const cookies = parseCookies();
  const [tabToSave, setTabToSave] = useState(null);
  const [openSaveProcess, setOpenSaveProcess] = useState(false);
  const renameInputRef = useRef(null);
  const [itemsContextMenu, setItemsContextMenu] = useState([])
  const [selectedBlock, setSelectedBlock] = useState(null);
  
  useEffect(() => {
    const updatedPanes = items.map((item) => ({
      ...item,
      children: isDev ? <TabContentComponentDev key={item.key} tabId={item.key} /> : <TabContentComponentNormal key={item.key} tabId={item.key} />,
    }));
    setItems(updatedPanes);
  }, [isDev]);

  const sensor = useSensor(SmartPointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const handleAddExistingTab = async (tabId, setActive)=>{
    console.log(setActive)
    try {
      const newPanes = [...items];
      const exists = newPanes.some(item => item.key === tabId);
      if (!exists) {
        const tabResponse = await getTabContent(tabId);
        console.log(tabResponse)
        const dataTab = { ...tabResponse };
        dataTab.open = true;
        dataTab.active = setActive? setActive : false;
        const blockIds = tabResponse.blocks.map(block => block.blockId);
        dataTab.blocks = blockIds;
        processNode(dataTab, null, tabId);
        jsonData.tabs.push(tabResponse);
        
          newPanes.push({
            label: tabResponse.tabIdName,
            children: isDev ? <TabContentComponentDev tabId={tabId} /> : <TabContentComponentNormal tabId={tabId} />,
            key: tabId
          });
          setItems(newPanes);
        
        
      }
      console.log(activeKey)
      if (activeKey){
        const activeKeyCopy = activeKey
        const jsonDataTabActive = jsonData["tabs"].find(item => item.tabId === activeKeyCopy);
        const dataTabActive = {...jsonDataTabActive}
        const blockIds = jsonDataTabActive.blocks.map(block => block.blockId);
        dataTabActive.blocks = blockIds;
        dataTabActive.active = false;
        processNode(dataTabActive, null, activeKeyCopy)
      }


      
      if (setActive){
        setActiveKey(tabId);
        jsonData.activeTabId = tabId;
      }
      

    } catch {

    }
   
  }
  useEffect(()=>{
    const handlerExistingTab = async (event)=>{
      const detail = event.detail;
      await handleAddExistingTab(detail.tabId, detail.setActive);
    }

    const handlerTabRenamedFromLeft = async (event) => {
      const { tabId, tabIdName } = event.detail;
      setItems(prevItems => {
        return prevItems.map(item => 
          item.key === tabId ? { ...item, label: tabIdName } : item
        );
      });
      jsonData.tabs = jsonData.tabs.map(tab => 
        tab.tabId === tabId ? { ...tab, tabIdName } : tab
      );
    };

   

    document.addEventListener('addExistingTab', handlerExistingTab);
    document.addEventListener('tabRenamedFromLeft', handlerTabRenamedFromLeft);
    
    return () => {
      document.removeEventListener('addExistingTab', handlerExistingTab);
      document.removeEventListener('tabRenamedFromLeft', handlerTabRenamedFromLeft);
      

    };
  }, [items])
  

  useEffect(()=>{
    const handler = async (event)=>{
      const detail = event.detail;
      remove(detail.tabId, false, detail.updateNewActive)
    }

    const handleContextMenuNewTab = async (event) =>{
      await add();
      console.log(event.detail)
      if (event.detail.addBlock){
        setTimeout(()=>{
          const event = new CustomEvent('addBlock', { detail: { type: 'text', content: '', tabId: jsonData.activeTabId, context: 'user' } });
          document.dispatchEvent(event);
        }, 2000)
        
      }
    }

    document.addEventListener('deletedTab', handler);
    document.addEventListener("contextMenuNewTab", handleContextMenuNewTab)
    return () => {
      document.removeEventListener('deletedTab', handler);
      document.removeEventListener("contextMenuNewTab", handleContextMenuNewTab);
    };
  }, [items])

  const add = async () => {
  
    if (addCalled.current) return; // Evita que se llame dos veces
    addCalled.current = true;
    try {
      const response = await processNode('');
      const nodeId = response.node_id;
      const environmentId = cookies.environmentId;
      const tabData = {
        tabId: nodeId,
        tabIdName: generateLabel(),
        isProcess: false,
        processName: '',
        blocks: [],
        childs: [],
        open: true,
        active: true,
        env: environmentId
      };

      await processNode(tabData, null, nodeId);
      const userResponse = await getNodeContent(jsonData.userId);
      const userData = userResponse.node_output;
      userData.tabs.push(nodeId)
      await processNode(userData, null, jsonData.userId);

      jsonData.tabs.push(tabData);

      const newPanes = [...items];
      newPanes.push({
        label: tabData.tabIdName,
        children:  isDev ? <TabContentComponentDev tabId={nodeId} /> : <TabContentComponentNormal tabId={nodeId} />,
        key: nodeId,
      });
      if (activeKey){
        const activeKeyCopy = activeKey
        const jsonDataTabActive = jsonData["tabs"].find(item => item.tabId === activeKeyCopy);
        const dataTabActive = {...jsonDataTabActive}
        const blockIds = jsonDataTabActive.blocks.map(block => block.blockId);
        dataTabActive.active = false;
        dataTabActive.blocks = blockIds;
        processNode(dataTabActive, null, activeKeyCopy)
      }

      setItems(newPanes);
      setActiveKey(nodeId);
      jsonData.activeTabId = nodeId;
      
    } catch (error) {
      console.error('Error adding new tab:', error);
    } finally {
    addCalled.current = false;
    const event = new CustomEvent('fetchTabsList', { detail: true });
    document.dispatchEvent(event);
  }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (jsonData.userId === ''){
        const token = cookies.accessToken;
        if (!token) {
          return
        }
        const decodedJWT = decodeJWT(token);
        jsonData.userId = decodedJWT.uuid;
        jsonData.username = decodedJWT.username;
      }
      try {
        const userResponse = await getNodeContent(jsonData.userId);
        if ('status' in userResponse && userResponse.status === 'node not found'){
          const folderHome = {
            name: "Home",
            type: "folder",
            childs: []
          };
          const folderResponse = await processNode(folderHome);
          const userData = {
            userId: jsonData.userId,
            username: jsonData.username,
            tabs: [],
            folders: [folderResponse.node_id]
          };
          await processNode(userData, null, jsonData.userId);
          folderHome.folderId = folderResponse.node_id;
          await processNode(folderHome, null, folderResponse.node_id);
          add();
        } else {
          const eventTabs = new CustomEvent('fetchTabsList', { detail: true });
          document.dispatchEvent(eventTabs);
          const eventFiles = new CustomEvent('getFiles', { detail: true });
          document.dispatchEvent(eventFiles);

        }

      } catch {
        message.error('Error loading data, please try again later');
      } finally {
        
      }
    };

    fetchData();
  }, []);



  const remove = (targetKey, updateRemoved, updateNewActive) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
    jsonData.activeTabId = newActiveKey;

    if(updateRemoved){
      const jsonDataTab = jsonData["tabs"].find(item => item.tabId === targetKey);
      const dataTab = {...jsonDataTab}
      dataTab.open = false;
      dataTab.active = false;
      const blockIds = jsonDataTab.blocks.map(block => block.blockId);
      dataTab.blocks = blockIds;
      processNode(dataTab, null, targetKey)
    }

    if (targetKey !== newActiveKey && updateNewActive){
      const jsonDataTabActive = jsonData["tabs"].find(item => item.tabId === newActiveKey);
      const dataTabActive = {...jsonDataTabActive}
      dataTabActive.active = true;
      const blockIds = jsonDataTabActive.blocks.map(block => block.blockId);
      dataTabActive.blocks = blockIds;
      processNode(dataTabActive, null, newActiveKey)
    }

  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const DraggableTabNode = ({ className, ...props }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: props['data-node-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'pointer',
    };
    return React.cloneElement(props.children, {
      ref: setNodeRef,
      style,
      ...attributes,
      ...listeners,
    });
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };


  
  function findParent(element, id, className) {
    while (element) {
      if (id && element.id && element.id.startsWith(id)) {
        return element;
      }
      if (className && element.classList && element.classList.contains(className)) {
        return element;
      }
      element = element.parentNode;
    }
    return null;
  }
  

  const handleOnContextMenu = (e) =>{
   
    const panel = findParent(e.target, 'panel');
    setSelectedTab(panel);
    e.preventDefault();
    if (panel){
      
      showContextMenu(e.pageX, e.pageY, 'tabs');
    } else if (e.target.className == 'content-container'){
      showContextMenu(e.pageX, e.pageY, 'content-container');
    } else {
      const block = findParent(e.target, null, 'block-container');
      if (block){
        setSelectedBlock(block);
        showContextMenu(e.pageX, e.pageY, 'block-container');
      }
    }
    
  }
  const showContextMenu = (x, y, location) => {
    setContextMenu({
      visible: true,
      x,
      y,
      location,
    });
    switch (location) {
      case 'tabs':
        setItemsContextMenu( [

          {
            label: "Rename",
            key: "rename",
            icon: <EditOutlined style={{marginRight: 5}}/>
          },
          {
            label: "Save as process",
            key: "save-as-process",
            icon: <SaveOutlined style={{marginRight: 5}}/>
          },
          {
            label: "Delete",
            key: "delete",
            icon: <DeleteOutlined style={{marginRight: 5}}/>
          }
        ])
        break;
      case 'content-container':
        setItemsContextMenu( [

          {
            label: "Paste",
            key: "paste-block",
            icon: <SnippetsOutlined style={{marginRight: 5}}/>
          }
        ])
        break;
      case 'block-container':
        setItemsContextMenu( [

          {
            label: "Copy",
            key: "copy-block",
            icon: <CopyOutlined style={{marginRight: 5}}/>
          },
          {
            label: "Cut",
            key: "cut-block",
            icon: <ScissorOutlined style={{marginRight: 5}}/>
          },
          {
            label: "Delete",
            key: "delete-block",
            icon: <DeleteOutlined style={{marginRight: 5}}/>
          }
        ])
        break;
    
      default:
        break;
    }
  };
  const handleCloseContextMenu = () => {
    setSelectedTab(null);
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      location: '',
    });
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    location: '',
  });

 

  const handleRenameTab = () =>{
    const tabId = selectedTab.id.split('panel-')[1];
   
    setPopRename(tabId);

  }

  const handleDeleteTab = () =>{
    const tabId = selectedTab.id.split('panel-')[1];

    remove(tabId);
    const event = new CustomEvent('deletedTabFromTopContext', { detail: tabId });
    document.dispatchEvent(event);

  }

  const handleSaveTabAsProcess = () => {
    const tabId = selectedTab.id.split('panel-')[1];
    const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
    setTabToSave(jsonDataTab);
    setOpenSaveProcess(true);
  }

  const handleCopyBlock = () =>{
    setBlockToCopy(
      {
        blockId: selectedBlock.id,
        tabId: activeKey
      }
    )
    setBlockToCut(
      {
        blockId: null,
        tabId: null
      }
    )
    const jsonDataTab = jsonData["tabs"].find(item => item.tabId === activeKey);
    const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === selectedBlock.id);
    message.info(`Copied ${jsonDataBlock.label} block.`)
  }

  const handleCutBlock = () =>{
    setBlockToCut(
      {
        blockId: selectedBlock.id,
        tabId: activeKey
      }
    )
    setBlockToCopy(
      {
        blockId: null,
        tabId: null
      }
    )
    const jsonDataTab = jsonData["tabs"].find(item => item.tabId === activeKey);
    const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === selectedBlock.id);
    message.info(`Cut ${jsonDataBlock.label} block.`)
  }

  const handleDeleteBlock = () =>{
    const event = new CustomEvent('deleteBlock', { detail: { blockId: selectedBlock.id, tabId: activeKey } });
    document.dispatchEvent(event);
  }

  const handlePasteBlock = () =>{
    if (blockToCopy.blockId){
      const jsonDataTab = jsonData["tabs"].find(item => item.tabId === activeKey);
      const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === blockToCopy.blockId);
      if (jsonDataBlock) {
        message.info(`${jsonDataBlock.label} block already exists in this tab.`)
      } else {
        const event = new CustomEvent('pasteBlock', { detail: { blockToPaste: blockToCopy, tabId: activeKey, from: 'copy' } });
        document.dispatchEvent(event);
      }
    }
    if (blockToCut.blockId){
      const jsonDataTab = jsonData["tabs"].find(item => item.tabId === activeKey);
      const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === blockToCut.blockId);
      if (jsonDataBlock) {
        message.info(`${jsonDataBlock.label} block already exists in this tab.`)
      } else {
        const event = new CustomEvent('pasteBlock', { detail: { blockToPaste: blockToCut, tabId: activeKey, from: 'cut' } });
        document.dispatchEvent(event);
      }
    }
  }

  const handleMenuClick = ({ key}) => {

    switch(key) {
      case 'rename':
        handleRenameTab()
        break
      case 'save-as-process':
        handleSaveTabAsProcess()
        break
      case 'delete':
        handleDeleteTab()
        break
      case 'copy-block':
        handleCopyBlock()
        break
      case 'cut-block':
        handleCutBlock()
        break
      case 'delete-block':
        handleDeleteBlock()
        break
      case 'paste-block':
        handlePasteBlock()
        break
      default:
        break
    }
    handleCloseContextMenu();
  };
  const menuProps = {
    items: itemsContextMenu,
    onClick: handleMenuClick

};
useEffect(() => {
  const handleClickOutside = (event) => {
    if (contextMenu.visible) {
      const menuElement = document.querySelector(".context-menu-tabs");
      if (menuElement && !menuElement.contains(event.target)) {
        handleCloseContextMenu();
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [contextMenu.visible]);

  const handleOnChangeTab = (e)=>{
    const jsonDataTabActive = jsonData["tabs"].find(item => item.tabId === activeKey);
    const dataTab = {...jsonDataTabActive}
    const blockIds = jsonDataTabActive.blocks.map(block => block.blockId);
    dataTab.blocks = blockIds;
    dataTab.active = false;
    processNode(dataTab, null, activeKey)

    const jsonDataTabNewActive = jsonData["tabs"].find(item => item.tabId === e);
    const dataTabNew = {...jsonDataTabNewActive}
    dataTabNew.active = true;
    const blockIdsNew = jsonDataTabNewActive.blocks.map(block => block.blockId);
    dataTabNew.blocks = blockIdsNew;
    processNode(dataTabNew, null, e)
    setActiveKey(e);
    jsonData.activeTabId = e;
  }

  const [popRename, setPopRename] = useState(false);
  const [popRenameInput, setPopRenameInput] = useState('');

  const handleOpenChange = (value) => {
    setPopRename(value);
    setPopRenameInput('');
  };

  const handleClose = () => {
    setPopRename(false);
    setPopRenameInput('');
  };

  const handleConfirm = async () => {
    const tabId = popRename;
    const response = await getNodeContent(tabId);
    const tabData = response.node_output;
    tabData.tabIdName = renameInputRef.current;
    await processNode(tabData, null, tabId);
    const tab = items.find(item => item.key === tabId);
    tab.label = renameInputRef.current;
    handleClose();
    const event = new CustomEvent('fetchTabsList', { detail: tabId });
    document.dispatchEvent(event);
  };

  const handleInputChange = (e) => {
    renameInputRef.current = e.target.value;
    // setPopRenameInput(e.target.value);
    console.log(renameInputRef.current);
  };
  const popoverContent = (
    <div>
      <Input
        // ref={renameInputRef} 
        placeholder="New name"
        // value={popRenameInput}
        onChange={handleInputChange}
      />
      <div style={{ marginTop: 10, textAlign: 'right' }}>
        <Button type="primary" onClick={handleConfirm} style={{ marginRight: 8 }}>
          Rename
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </div>
  );

  

  return (
    <>
  
    <Tabs
      onContextMenu={handleOnContextMenu}
      hideAdd
      onChange={handleOnChangeTab}
      activeKey={activeKey}
      onEdit={onEdit}
      items={items}
      tabBarExtraContent={{
        left: (
          <Button onClick={add} shape="circle" style={{ margin: '15px 5px 0px 5px', bottom: '5px', right: '5px' }}>
            <PlusOutlined />
          </Button>
        ),
      }}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <DndContext modifiers={[restrictToParentElement, restrictToHorizontalAxis]}sensors={[sensor]} onDragEnd={onDragEnd} collisionDetection={closestCenter}>
          <SortableContext items={items.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
            <DefaultTabBar {...tabBarProps}>
              {(node) => (
                <DraggableTabNode {...node.props} key={node.key} style={{marginLeft: '5px'}}>
                   <span id={`panel-${node.key}`} >
                      {node}
                      <Popover
        content={popoverContent}
        title="Rename Tab"
        trigger="click"
        open={popRename === node.key ? true: false}
        onOpenChange={handleOpenChange}
      >
        
      </Popover>
                      <CloseOutlined 
                      style={{marginLeft: '5px', fontSize: '10px'}} 
                      onClick={()=>{remove(node.key, true)}}
                      
                      />
                      <Divider type='vertical'></Divider>
                    </span>
                </DraggableTabNode>
              )}
            </DefaultTabBar>
          </SortableContext>
        </DndContext>
      )}
    />
    {contextMenu.visible && (
      <div style={{position: 'absolute'}}>
        <Dropdown
          menu={menuProps}
          trigger={[]}
          open={contextMenu.visible}
          overlayStyle={{
            position: "fixed",
            left: contextMenu.x,
            top: contextMenu.y,
          }}
          overlayClassName="context-menu-tabs"
        >
          <div
            style={{
              position: "fixed",
              left: contextMenu.x,
              top: contextMenu.y,
              zIndex: 1,
              
            }}
            onClick={handleCloseContextMenu}
          />
        </Dropdown>
        </div>
      )}
       <SaveProcessComponent jsonData={jsonData} jsonDataTab={tabToSave} openSaveProcess={openSaveProcess} setOpenSaveProcess={setOpenSaveProcess}/>
    </>
  );
};

export default TabsComponent;
