import { Select, Slider, InputNumber, Checkbox, Radio, DatePicker, TimePicker, Input, Button } from 'antd';
import { getProcessSettings } from '../../../utils/nodeUtils';
import { useEffect, useState } from 'react';

function ProcessSettingsComponent({blockId, jsonDataBlock, realTimeSettings, processId}) {
    const [settingsList, setSettingsList] = useState([]);

    useEffect(() => {
    
        if (realTimeSettings) {

            setSettingsList(realTimeSettings);
        }
    }, [realTimeSettings]);


    useEffect(() => {
        const fetchProcessSettings = async () => {
            if (processId) {
                try {
                    const response = await getProcessSettings(processId);
                    setSettingsList(response);
                } catch (error) {
                    console.error('Error fetching process settings:', error);
                    // Manejar el error según sea necesario
                }
            } else {
                // Si processId es null o undefined, limpiar la lista de configuraciones
                setSettingsList([]);
            }
        };

        fetchProcessSettings();
    }, [processId]);

    const handleSettingsChange = (index, value) => {
        const updatedSettings = [...settingsList];
        const setting = updatedSettings[index];
        if (setting.control_type === 'radio_'){
            value = value.target.value;
        }
        if (setting.control_type === 'textarea_'){
            value = value.target.value;
        }
        updatedSettings[index].selectedValue = value;
        setSettingsList(updatedSettings);
        if(jsonDataBlock.blockType === 'process'){
            jsonDataBlock.settings[`${setting.field_name}`] = value
        }
    };

    const renderControl = (controlType, values, field_name, selectedValue, onChange) => {
        switch (controlType) {
            case 'int_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <InputNumber
                        min={parseInt(values[0], 10)}
                        max={parseInt(values[values.length - 1], 10)}
                        value={selectedValue ? selectedValue : parseInt(values[0], 10)}
                        onChange={onChange}
                        style={{width: '100%', textAlign: 'center'}}
                        />
                 
                  </div >
                );
            case 'float_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <InputNumber
                        style={{width: '100%', textAlign: 'center'}}
                        min={parseInt(values[0], 10)}
                        max={parseInt(values[values.length - 1], 10)}
                        step={0.01}
                        value={selectedValue ? selectedValue : parseInt(values[0], 10)}
                        onChange={onChange}
                        />
                    
                    </div >
                );
            case 'button_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <Button
                    
                        onChange={onChange}
                        
                    ></Button>
                    
                    </div >
                );
            case 'textarea_':
                return (
                    <div className='card'>
                        <label style={{textAlign: 'center'}}>{field_name}</label>
                        <Input.TextArea 
                            onChange={onChange}
                            autoSize={{ minRows: 1, maxRows: 1 }}
                            value={selectedValue ? selectedValue : ''}
                        />
                    </div >
                );
            case 'time_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <TimePicker
                 
                        onChange={onChange}
                        
                    ></TimePicker>
                 
                  </div >
                );
            case 'date_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <DatePicker
                 
                        onChange={onChange}
                        
                    ></DatePicker>
                 
                  </div >
                );
            case 'checkbox_':
                const options = [];
                values.forEach((value)=>{
                    options.push({label: value, value: value})
                })
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <Checkbox.Group
                       
                        onChange={onChange}
                        options={options}
                    />
                 
                  </div >
                );
            case 'radio_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                  
                      <Radio.Group onChange={onChange}  value={selectedValue || values[0]}>
                      {values.map((option) => (
                            <Radio key={option} value={option}>
                                {option}
                            </Radio>
                        ))}
                        </Radio.Group>
                    
                    </div >
                );
            case 'dropdown_':
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <Select
                        value={selectedValue || values[0]}
                        onChange={onChange}
                        
                    >
                        {values.map((option) => (
                            <Select.Option key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select></div >
                );
            case 'slider_':
                
                
                return (
                    <div className='card'>
                    <label style={{textAlign: 'center'}}>{field_name}</label>
                    <div style={{display: 'flex', flexDirection: 'row', width: '220px'}}>
                    <Slider
                        value={typeof selectedValue === 'number' ? selectedValue : parseInt(values[0], 10)}
                        
                        min={parseInt(values[0], 10)}
                        max={parseInt(values[values.length - 1], 10)}
                        style={{width: '100%'}}
                        onChange={onChange}
                        
                    />
                     <InputNumber
                        min={parseInt(values[0], 10)}
                        max={1000}
                        style={{
                            margin: '0 5px',
                            width: '100px'
                        }}
                        value={typeof selectedValue === 'number' ? selectedValue : parseInt(values[0], 10)}
                        onChange={onChange}
              
                        />
                        </div>
                    </div >
                );
            default:
                return null;
        }
    };

    return (
     <>
            {settingsList.length > 0 ? (
                <div className="card-settings-container">
                    {settingsList.map((settings, index) => (
                        <div key={index}>
                            {renderControl(
                                settings.control_type,
                                settings.values,
                                settings.field_name,
                                settings.selectedValue,
                                (value) => handleSettingsChange(index, value)
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p>No settings available.</p>
            )}
       </>
    );
}

export default ProcessSettingsComponent;
