import { Radio } from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getMediaFile, processNode, runPythonCode } from '../../../utils/nodeUtils';
import BlockTyeComponent from './blockTypeComponent';
import { Input, Switch, Spin, Button, Card, Progress, message, Image } from 'antd';
import { 
    SettingOutlined, 
    CaretRightOutlined, 
    ReloadOutlined, 
    BorderOutlined, 
    PauseOutlined, 
    LoadingOutlined, 
    DeleteOutlined,
    ScissorOutlined,
    SnippetsOutlined,
    CopyOutlined,
    FormOutlined,
    SaveOutlined,
    HeatMapOutlined 
} from '@ant-design/icons';
import CodeEditor from './codeEditorComponent';
import { DataContext } from '../../../utils/dataContext';
import ReactMarkdown from 'react-markdown';
import ProcessSettingsComponent from './processSettingsComponent';
import { parseCookies } from 'nookies';
import TerminalComponent from './terminalComponent';
import ConsoleInput from './consoleInputComponent';
import { runProcess, uploadFile, getNodeContent } from '../../../utils/nodeUtils';
import DynamicHTMLComponent from './dynamicHtmlCompoenent';
import ProcessSelectorComponent from './processSelectorComponent';
import FileInputComponent from './fileInputComponent';


function BlockComponent({ value, tabId, blockId, label, onDelete, styles, isActive, socket, openSocket }) {
    const { jsonData } = useContext(DataContext);
    const { blockToCopy, setBlockToCopy } = useContext(DataContext);
    const { blockToCut, setBlockToCut } = useContext(DataContext);
    const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
    const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === blockId);
    const [checked, setChecked] = useState(jsonDataBlock.active);
    const [settings, setSettings] = useState(false);
    const [processSelector, setProcessSelector] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [codeEditor, setCodeEditor] = useState(false);
    const [simpleInput, setSimpleInput] = useState(true);
    const [terminal, setTerminal] = useState(false);
    const [inputContent, setInputContent] = useState(jsonDataBlock.input);
    const [markdown, setMarkdown] = useState(false);
    const [selectedValueFirstGroup, setSelectedValueFirstGroup] = useState(null);
    const [selectedValueSecondGroup, setSelectedValueSecondGroup] = useState(null);
    const [blockLabel, setBlockLabel] = useState(label);
    const [showInput, setShowInput] = useState(true);
    const [simpleOutput, setSimpleOutput] = useState(false);
    const [imageOutputs, setImageOutputs] = useState([]); // Array para múltiples imágenes
    const [htmlOutputs, setHtmlOutputs] = useState([]);
    const pythonExecutionFinished = useRef(true);
    const waitingConfirmation = useRef(false);
    const wsRef = useRef(null);
    const processWsRef = useRef(null); 
    const [realTimeSettings, setRealTimeSettings] = useState([]);
    const cookies = parseCookies();
    const [fileInput, setFileInput] = useState(false);
    const onPause = useRef(false);

    const connectWebSocket = () => {
        if (!wsRef.current) {
            const apiPort = cookies.apiPort;
            var url = '/ws/settings/';
            if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
                url = `/pipeline/port/${apiPort}${url}`
            }
            wsRef.current =  new WebSocket(process.env.REACT_APP_PIPELINE_WEBSOCKET_URL + url);

            wsRef.current.onopen = () => {
                console.log('WebSocket connected');
                if (jsonDataBlock.input !== ''){
                    wsRef.current.send(JSON.stringify({code: jsonDataBlock.input}));
                }
            };

            wsRef.current.onmessage = (message) => {
                const data = JSON.parse(message.data);
                setRealTimeSettings(data.message);
            };

            wsRef.current.onclose = () => {
                console.log('WebSocket disconnected');
                wsRef.current = null; // Clear the reference on close
            };

            wsRef.current.onerror = (error) => {
                console.log('WebSocket error:', error);
            };
        }
    };

    const disconnectWebSocket = () => {
        if (wsRef.current) {
            wsRef.current.close();
        }
    };

    const connectProcessWebSocket = () => {
        return new Promise((resolve, reject) => {
            if (!processWsRef.current) {
                const apiPort = cookies.apiPort;
                var url = '/ws/process/';
                if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){
                    url = `/pipeline/port/${apiPort}${url}`
                }
                processWsRef.current = new WebSocket(process.env.REACT_APP_PIPELINE_WEBSOCKET_URL + url);
    
                processWsRef.current.onopen = () => {
                    console.log('Process WebSocket connected');
                    resolve();  // Resuelve la promesa cuando el WebSocket se conecta
                };
    
                processWsRef.current.onmessage = (message) => {
                    const data = JSON.parse(message.data);
                    // Maneja los mensajes de ejecución de procesos
                    console.log('Process WebSocket message:', data);
                    handleSetOutputContentPython(data);
                    if (data.output === 'Execution finished') {
                        disconnectProcessWebSocket();
                    }
                };
    
                processWsRef.current.onclose = () => {
                    console.log('Process WebSocket disconnected');
                    processWsRef.current = null; // Limpia la referencia al cerrar
                };
    
                processWsRef.current.onerror = (error) => {
                    console.log('Process WebSocket error:', error);
                    reject(error);  // Rechaza la promesa en caso de error
                };
            } else if (processWsRef.current.readyState === WebSocket.OPEN) {
                resolve();  // Resuelve la promesa inmediatamente si ya está conectado
            } else if (processWsRef.current.readyState === WebSocket.CONNECTING) {
                processWsRef.current.onopen = () => {
                    resolve();  // Resuelve la promesa cuando el WebSocket termine de conectarse
                };
                processWsRef.current.onerror = (error) => {
                    reject(error);  // Rechaza la promesa si ocurre un error durante la conexión
                };
            }
        });
    };
    

    const disconnectProcessWebSocket = () => {
        if (processWsRef.current) {
            processWsRef.current.close();
        }
    };

    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            if (processWsRef.current) {
                processWsRef.current.close();
            }
        };
    }, []);

    const handleSwitchChange = (checked) => {
        setChecked(checked);
        jsonDataBlock.active = checked;
        handleUpdateBlockNode();
    };

    const handleSwitchSettings = () => {
        setSettings(!settings);
        if (jsonDataBlock.blockType === 'code'){
            if (!settings) {
                connectWebSocket();
            } else {
                disconnectWebSocket();
            }
        }
       
    };

    const handleSwitchProcessSelector = () => {
        setProcessSelector(!processSelector);
    }

    // const handleParseContent = () => {
    //     var content = '';
    //     var firstBlock = true;
       
    //     for (const data of jsonDataTab["blocks"]) {
    //         if (!firstBlock) {
    //             content += '\n';
    //         } else {
    //             firstBlock = false;
    //         }
    
    //         if (data.blockId == blockId) {
    //             content += data.input;
    //             break;
    //         } else {
    //             content += data.output;
    //         }
    //         // if (data.blockType === 'process') {
                
    //         //      // Termina el bucle al encontrar el bloque 'process'
    //         // }
    //     }
        
    //     return content;
    // };

    const handleParseContent = () => {
        var content = '';

        for (const data of jsonDataTab["blocks"]) {
            
            if (data.blockId == blockId) {
                break;
            } else {
                if (data.blockType === 'text' || data.blockType === 'upload'){
                    content = data.input;

                    console.log(content)
                } else{
                    content = data.output;
                }   
            
                
            }
        }
        
        return content;
    };

 
    const checkLabels = () => {
        let code = jsonDataBlock.input;
        jsonDataTab.blocks.forEach((block) => {
            const blockLabel = `<Block label="${block.label}"/>`;
            const blockInput = `"${block.input}"`;
            const regex = new RegExp(blockLabel, 'g'); // Crear una expresión regular para todas las ocurrencias
            code = code.replace(regex, blockInput); // Reemplazar todas las ocurrencias
        });
        return code;
    };

    const handleSetOutputContent = (response) => {
        jsonDataBlock.output = response.output;
     
        switch(response.type){
            case "text":
                setSimpleOutput(response.output);
                break
            case "json":
                setSimpleOutput(JSON.stringify(response.output));
                break
            case "image/png":
                const base64Image = response.output['image/png'];
                // setImageOutputs(`data:image/png;base64,${base64Image}`);
                break
        }
        handleUpdateBlockNode();    
    }
    
    const handleSetOutputContentPython = (response) => {
        
        switch(response.type){
            case "text":
                if(pythonExecutionFinished.current){
                    setSimpleOutput(response.output);
                    pythonExecutionFinished.current = false;
                    jsonDataBlock.output = response.output;
                    if (response.output === 'Execution finished'){
                        pythonExecutionFinished.current = true;
                        handleUpdateBlockNode();
                        setSpinner(false);
                        const eventCompletion = new CustomEvent('blockExecutionComplete', {
                            detail: { blockId: blockId }, // Asegúrate de pasar el blockId correcto
                        });
                        document.dispatchEvent(eventCompletion);    
                    }
                } else {
                    jsonDataBlock.output += response.output;
                    setSimpleOutput(prevContent => prevContent + response.output + '\n');
                    if (response.output === 'Waiting confirmation'){
                        waitingConfirmation.current = true;
                    }
                    if (response.output === 'Execution finished'){
                        pythonExecutionFinished.current = true;
                        handleUpdateBlockNode();
                        setSpinner(false);
                        const eventCompletion = new CustomEvent('blockExecutionComplete', {
                            detail: { blockId: blockId }, // Asegúrate de pasar el blockId correcto
                        });
                        document.dispatchEvent(eventCompletion);    
                    }
                }
                
                
                break
            case "json":
                setSimpleOutput(JSON.stringify(response.output));
                break
            case "html":
                setHtmlOutputs(prevHtml => [...prevHtml, response.output]);
                break;
            case "image/png":
                const base64Image = response.output['image/png'];
                setImageOutputs(prevImages => [...prevImages, `data:image/png;base64,${base64Image}`]);
                break;
    }
        
    }

    useEffect(()=>{
        const handleCodeConsumerMsgReceived = (event) => {
            const msgTabId = event.detail.tabId;
            const message = event.detail.message;
            if (tabId === msgTabId && message.request_id === blockId){
                
                if (message.status === "success") {
                    handleSetOutputContentPython(message);
                } else if (message.status === "error") {
                    handleSetOutputContentPython(message);
                }
                
            }
        }

        const handleRunAllCode = async (event) => {
            const evtTabId = event.detail.tabId;
            const evtBlockId = event.detail.blockId;
            if (evtTabId === tabId && evtBlockId === blockId){
                await handlePlayButton();
            }
        }

        const handlePauseAllCode = async (event) => {
            const evtTabId = event.detail.tabId;
            const evtBlockId = event.detail.blockId;
            if (evtTabId === tabId && evtBlockId === blockId){
                await handlePauseButton();
            }
        }

        const handleStopAllCode = async (event) => {
            const evtTabId = event.detail.tabId;
            const evtBlockId = event.detail.blockId;
            if (evtTabId === tabId && evtBlockId === blockId){
                await handleStopButton();
            }
        }

        const handleRestartKernel = async (event) => {
            const evtTabId = event.detail.tabId;
            const evtBlockId = event.detail.blockId;
            if (evtTabId === tabId && evtBlockId === blockId){
                socket.current.send(JSON.stringify({code: 'restart', request_id: blockId, user_id: jsonData.userId}));
            }
        }
        

        document.addEventListener('codeConsumerMsgReceived', handleCodeConsumerMsgReceived);
        document.addEventListener('runAllCode', handleRunAllCode);
        document.addEventListener('pauseAllCode', handlePauseAllCode);
        document.addEventListener('stopAllCode', handleStopAllCode);
        document.addEventListener('restartKernel', handleRestartKernel);
      
        return () => {
            document.removeEventListener('codeConsumerMsgReceived', handleCodeConsumerMsgReceived);
            document.removeEventListener('runAllCode', handleRunAllCode);
            document.removeEventListener('pauseAllCode', handlePauseAllCode);
            document.removeEventListener('stopAllCode', handleStopAllCode);
            document.removeEventListener('restartKernel', handleRestartKernel);
        };

    }, [socket, tabId, blockId])

    const handleRunPythonCode = async () => {
        
        try {
            await openSocket();
            var code;
            if (onPause.current){
                code = 'continue';
                onPause.current = false;
            } else {
                code = checkLabels();
            }
            
            socket.current.send(JSON.stringify({code: code, request_id: blockId, user_id: jsonData.userId}))
            
        } catch (error) {
            console.log(error)
        }
    } 

    const handleRunJavascript = async () => {
        try {
            const code = checkLabels();
            const func = new Function(code);
            var result = func();
            if (result === null) {
                result = '';
            }
            handleSetOutputContent({output: String(result), type: "text"});
        } catch (error) {
            handleSetOutputContent({output: `Error: ${error.message}`, type: "text"});
        } finally {
            setSpinner(false);
        }
    };
    const handleRunCode = async () => {
        switch(jsonDataBlock.language) {
            case 'javascript':
            case 'typescript':
                await handleRunJavascript();
                break
            case 'python':
                await handleRunPythonCode();
                break
        }
        
    }
    
    const handleRunProcess = async () => {
        const content = handleParseContent();
        
        try {
            const data = JSON.stringify({
                process_id: jsonDataBlock.processId,
                input: content,
                settings: jsonDataBlock.settings
            });
            console.log(data)
            await connectProcessWebSocket(); 
            processWsRef.current.send(data);
           
    
            
        } catch (error) {
            console.error('Error processing node:', error);
        } finally {
            // setSpinner(false);
        }
    };
    

    const handlePlayButton = async () => {
        setSpinner(true);
        console.log(onPause)
        if (!onPause.current){
            
            setSimpleOutput(false);
            setImageOutputs([])
            setHtmlOutputs([])
        }
        
        switch(jsonDataBlock.blockType){
            case 'process':
                await handleRunProcess();
                break
            case 'code':
                await handleRunCode()
                break
        }
       
    };

    const handleStopButton = () => {
        socket.current.send(JSON.stringify({code: 'stop', request_id: blockId, user_id: jsonData.userId}));
        setSpinner(false);
        onPause.current = false;

    };

    const handlePauseButton = async () => {
        socket.current.send(JSON.stringify({code: 'pause', request_id: blockId, user_id: jsonData.userId}));
        onPause.current = true;
        setSpinner(false);
    };


    const handleUpdateBlockNode = async () => {
        await processNode(jsonDataBlock, null, blockId);
      }

    const updateBlockContent = (e, path) => {
        var value;
        if (typeof e === 'string'){
            value = e;
            if (jsonDataBlock.language == 'markdown'){
                setMarkdown(e);
                setSimpleOutput(false)
                setImageOutputs([])
            }
        } else {
            value = e.target.value;
            setInputContent(value);
        }
        
        jsonDataBlock.input = value;
        if(jsonDataBlock.blockType == 'text'){
            jsonDataBlock.output = value;
        }

        if (path && typeof path === 'string'){
            jsonDataBlock.currentPath = path;
        }
        handleUpdateBlockNode();
        
        if(wsRef.current){
            wsRef.current.send(JSON.stringify({code: value}));
        }
    }
    

    const handleDeleteBlock = async () => {
        await onDelete(blockId)
        message.info(`Deleted ${blockLabel} block.`)
    };
    const handleCopyBlock = async () => {
        setBlockToCopy(
            {
              blockId: blockId,
              tabId: tabId
            }
          )
          setBlockToCut(
            {
              blockId: null,
              tabId: null
            }
          )
    
        message.info(`Copied ${blockLabel} block.`)
    };

    const handleCutBlock = async () => {
        setBlockToCut(
            {
              blockId: blockId,
              tabId: tabId
            }
          )
          setBlockToCopy(
            {
              blockId: null,
              tabId: null
            }
          )
   
          message.info(`Cut ${blockLabel} block.`)
    };

    useEffect(() => {
        if (isActive) {
            const event = new CustomEvent('passSettingsComponent', {
                detail: {
                    component: <ProcessSettingsComponent blockId={blockId}/>,
                    blockId: blockId
                }
            });
            document.dispatchEvent(event);
        }
    }, [isActive]);

    const handleRadioChangeFirstGroup = (e) => {
        setSelectedValueFirstGroup(e.target.value);
    
        setTimeout(() => {
            setSelectedValueFirstGroup(null); // Deselecciona el radio button después de 1 segundo
        }, 200);
    };

    const handleRadioChangeSecondGroup = (e) => {
        setSelectedValueSecondGroup(e.target.value);
    
        setTimeout(() => {
            setSelectedValueSecondGroup(null); // Deselecciona el radio button después de 1 segundo
        }, 200);
    };

    const handlePressEnter = async (e) => {
        if (e.shiftKey ){
            e.preventDefault();
            handlePlayButton();
        }
        
        
    }
    
    const handleCopyLabel = (e) => {
        const textToCopy = `<Block label="${blockLabel}"/>`;
    
        if (navigator.clipboard && navigator.clipboard.writeText) {
            // Método usando la API de Clipboard (para HTTPS)
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    message.info('Block label copied to clipboard');
                })
                .catch(err => {
                    message.error('Failed to copy text: ' + err);
                });
        } else {
            // Método alternativo usando document.execCommand (para HTTP)
            // Crear un elemento de texto temporal
            const tempInput = document.createElement('textarea');
            tempInput.value = textToCopy;
            document.body.appendChild(tempInput);
    
            // Seleccionar el texto en el elemento de texto temporal
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // Para dispositivos móviles
    
            // Ejecutar el comando de copiar
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    message.info('Block label copied to clipboard');
                } else {
                    message.error('Failed to copy text');
                }
            } catch (err) {
                message.error('Failed to copy text: ' + err);
            }
    
            // Remover el elemento de texto temporal
            document.body.removeChild(tempInput);
        }
    };
    

      const escapeHtml = (text) => {
        return text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
    };
    
    const parseAnsi = (text) => {
        const ansiRegex = /\x1b\[([0-9;]*)m/g;
        const colorMap = {
            '30': 'black',
            '31': 'red',
            '32': 'green',
            '33': 'orange',
            '34': 'blue',
            '35': 'magenta',
            '36': 'cyan',
            '37': 'white',
        };
        const styleMap = {
            '1': 'font-weight: bold',
            '4': 'text-decoration: underline',
        };
    
        text = escapeHtml(text);
    
        let html = '';
        let openSpans = 0;
        let currentStyles = [];
    
        html = text.replace(ansiRegex, (match, p1) => {
            if (p1 === '0') {
                let closeTags = '';
                while (openSpans > 0) {
                    closeTags += '</span>';
                    openSpans--;
                }
                currentStyles = [];
                return closeTags;
            } else {
                const styles = p1.split(';').map(code => {
                    if (colorMap[code]) return `color: ${colorMap[code]}`;
                    if (styleMap[code]) return styleMap[code];
                    return null;
                }).filter(Boolean);
    
                if (styles.length > 0) {
                    openSpans++;
                    currentStyles.push(...styles);
                    return `<span style="${currentStyles.join('; ')}">`;
                }
                return '';
            }
        });
    
        // Cierra cualquier span que quedó abierto
        while (openSpans > 0) {
            html += '</span>';
            openSpans--;
        }
    
        return html;
    };
    

    const handleConsoleInputSend = (message) =>{
        socket.current.send(JSON.stringify({input: message, request_id: blockId}))
        waitingConfirmation.current = false;
    }

    const handleDisplayInputTypes = (value) =>{
        switch(value){
            case 'code':
                setCodeEditor(true);
                if (!socket.current){
                    openSocket();
                }
                setTerminal(false)
                setSimpleInput(false)
                setFileInput(false)
                setShowInput(true)
                break
            case 'terminal':
                setTerminal(true)
                setCodeEditor(false);
                if ('language' in jsonDataBlock && jsonDataBlock.language === 'markdown'){
                    setMarkdown(false)
                }
                setSimpleInput(false)
                setFileInput(false)
                setShowInput(true)
                break
            case 'process':
                setTerminal(false)
                setCodeEditor(false);
                setSimpleInput(false)
                setFileInput(false)
                setShowInput(false)
                break
            case 'upload':
                setTerminal(false)
                setCodeEditor(false)
                setFileInput(true)
                setSimpleInput(false)
                setShowInput(false)
                break
            default:
                setSimpleInput(true)
                setTerminal(false)
                setCodeEditor(false);
                setFileInput(false)
                setShowInput(true)
                break
        }
    }


    const handleChangeLanguage = (value) => {
        switch (jsonDataBlock.language){
          case "markdown":
            setMarkdown(false);
            break
          default:
            break
        }
        jsonDataBlock.language = value
        setEditorLanguage(value);
    
        handleUpdateBlockNode();
      };
    const [editorLanguage, setEditorLanguage] = useState(jsonDataBlock.language ? jsonDataBlock.language : false);
      
    useEffect(() => {
        if (value) {
            handleDisplayInputTypes(value);
            
            
        }
      }, [value]);

    const handleProcessChange = (value) => {
        jsonDataBlock.processId = value;
        handleUpdateBlockNode();
        setProcessId(value);
    
    }
    const [processId, setProcessId] = useState(jsonDataBlock.processId ? jsonDataBlock.processId : false);
    const [file, setFile] = useState(false);

    useEffect(()=>{
        const handleSetFileInput = () =>{
            if (jsonDataBlock.blockType === 'upload' && jsonDataBlock.input !== ''){
                setShowInput(true);
                
                setFile(jsonDataBlock.input);
            }
        } 
        handleSetFileInput();
    }, [])
    

 

    

    const handleChangeUploadFile = async (info) => {
        const { fileList } = info;
        const latestFile = fileList[fileList.length - 1]?.originFileObj;
        setFile(latestFile);
        setShowInput(true);

        const formData = new FormData();
        formData.append('file', latestFile); 
        const fileResponse = await uploadFile(formData);
        console.log(fileResponse);
        jsonDataBlock.input = fileResponse.file.url;
        handleUpdateBlockNode();
        const nodeResponse = await processNode('');
        const nodeId = nodeResponse.node_id;
        const dataFile = {
            folderId: nodeId,
            blockType: 'file',
            content: fileResponse.file.name,
            type: "file",
            name: fileResponse.file.name,
            url: fileResponse.file.url,
            childs:[]
        };
        await processNode(dataFile, null, nodeId);
        const userResponse = await getNodeContent(jsonData.userId);
        const userData = userResponse.node_output;
        userData.folders.push(nodeId);
        await processNode(userData, null, jsonData.userId)
        const eventFiles = new CustomEvent('getFiles', { detail: true });
        document.dispatchEvent(eventFiles);
        
        
    };

    return (
        <div className="block-container" id={blockId} style={styles}>
            <div className="buttons-container">
                <BlockTyeComponent 
                    value={value} 
                    editorLanguage={editorLanguage}
                    processId={processId} 
                    tabId={tabId} 
                    blockId={blockId} 
                    displayInputTypes={handleDisplayInputTypes} 
                    changeLanguage={handleChangeLanguage} 
                    changeProcess={handleProcessChange}
                    changeUpload={handleChangeUploadFile}
                />
                
                
                <Radio.Group style={{ display: 'inline-flex', marginLeft: '5px' }} onChange={handleRadioChangeFirstGroup} value={selectedValueFirstGroup}>
                <Radio.Button title="Processes" value="processes"  onClick={handleSwitchProcessSelector}><HeatMapOutlined /></Radio.Button>
                    <Radio.Button title="Settings" value="settings"  onClick={handleSwitchSettings}><SettingOutlined /></Radio.Button>
                    <Radio.Button title="Refresh" value="refresh"><ReloadOutlined /></Radio.Button>
                    <Radio.Button title="Stop" value="stop" onClick={handleStopButton}><BorderOutlined /></Radio.Button>
                    <Radio.Button title="Pause" value="pause"onClick={handlePauseButton}><PauseOutlined /></Radio.Button>
                    <Radio.Button title="Play" value="play" onClick={handlePlayButton}>
                        {!spinner && (<CaretRightOutlined />)}
                        
                        {spinner && (
                            <Spin indicator={<LoadingOutlined spin />} size="small" />
                        )}
                    </Radio.Button>
                </Radio.Group>
               
    
                
                <div className='right-side-buttons'>
                <Radio.Group onChange={handleRadioChangeSecondGroup} value={selectedValueSecondGroup}>
                    <Radio.Button value="copy-label" title="block-label" style={{width: 'auto', display: 'flex', justifyContent: 'right'}} id={'block-label-' + blockId} onClick={handleCopyLabel}>{blockLabel}<FormOutlined style={{marginLeft: '5px'}}/></Radio.Button>
                    <Radio.Button value="copy" onClick={handleCopyBlock} style={{margin: 0}}><CopyOutlined /></Radio.Button>
                    <Radio.Button value="cut" onClick={handleCutBlock} style={{margin: 0}}><ScissorOutlined /></Radio.Button>
                    <Radio.Button value="paste" style={{margin: 0}}><SnippetsOutlined /></Radio.Button>
                    <Radio.Button value="delete" onClick={handleDeleteBlock} style={{margin: 0}}><DeleteOutlined /></Radio.Button>
                    </Radio.Group>
                    <Switch
                        size="medium"
                        checked={checked}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        style={{ top: '6px', margin: '0 0 0 8px' }}
                        onChange={handleSwitchChange}
                    />
                   
                    
                </div>
            </div>
            <div className={`settings-container ${settings ? 'settings-visible' : ''}`}>
                <ProcessSettingsComponent 
                blockId={blockId} 
                jsonDataBlock={jsonDataBlock} 
                realTimeSettings={realTimeSettings}
                processId={processId}
                />
            </div>
            
            {showInput && (
                <div className="input-container">
                    
                {(simpleInput) && (
                    <Input.TextArea
                    
                        placeholder="Type here..."
                        onChange={updateBlockContent}
                        value={inputContent}
                        autoSize={{ minRows: 1, maxRows: 50 }}
                        onPressEnter={handlePressEnter} // Adjust the height automatically
                    />
                )}
                {codeEditor && (
                    <CodeEditor tabId={tabId} blockId={blockId} updateBlockContent={updateBlockContent} handlePlayButton={handlePlayButton} language={editorLanguage}/>
                )}
                {terminal && (
                    <TerminalComponent jsonData={jsonData} jsonDataTab={jsonDataTab} jsonDataBlock={jsonDataBlock} updateBlockContent={updateBlockContent}/>
                )}
                {fileInput && (
                    <FileInputComponent file={file}/>
                )}
               
            </div>

            )}
            
            <div className={`process-selector-container ${processSelector ? 'process-selector-container-visible' : ''}`}>
                <ProcessSelectorComponent/>
            </div>

            <div className="output-container">
                {simpleOutput && (
                      <div>
                           <pre className='pre-output' style={{ whiteSpace: 'pre-wrap', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px', overflow: 'auto', margin: 0, paddingBottom: 0 }}
           dangerouslySetInnerHTML={{ __html: parseAnsi(simpleOutput)}}>
      </pre>
                      {waitingConfirmation.current && (<ConsoleInput onSendMessage={handleConsoleInputSend}/>)}
                  </div>
                )}
                
                
                {imageOutputs.length > 0 && (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                        {imageOutputs.map((imgSrc, index) => (
                            <Image key={index} src={imgSrc} width={300}/>
                        ))}
                    </div>
                )}
                
                <DynamicHTMLComponent htmlOutputs={htmlOutputs} />

                {markdown && (
         
                        <ReactMarkdown >
                    {markdown}
                </ReactMarkdown>

                
                )}
                
            </div>
            
            
        </div>
    );
}

export default BlockComponent;
