import React, { useState, useEffect, useContext } from 'react';
import { Select, Upload, Button, Space } from 'antd';
import { DataContext } from '../../../utils/dataContext';
import { processNode, getProcessesList } from '../../../utils/nodeUtils';
import { UploadOutlined } from '@ant-design/icons';

function BlockTypeComponent({ value, editorLanguage, processId, tabId, blockId, displayInputTypes, changeLanguage, changeProcess,changeUpload }) {
  const { jsonData } = useContext(DataContext);
  const jsonDataTab = jsonData["tabs"].find(item => item.tabId === tabId);
  const jsonDataBlock = jsonDataTab["blocks"].find(item => item.blockId === blockId);
  const [processTypeOptions, setProcessTypeOptions] = useState([]);
  const blockTypeOptions = [
    { value: 'text', label: 'Text' },
    { value: 'code', label: 'Code' },
    { value: 'process', label: 'Process' },
    { value: 'terminal', label: 'Terminal' },
    { value: 'tab', label: 'Tab' },
    { value: 'block', label: 'Block' },
    { value: 'upload', label: 'Upload' }
  ];

  const textTypeOptions = [
    { value: 'simple text', label: 'Simple Text' },
    { value: 'rich text', label: 'Rich Text' }
  ];

  const fetchProcessses = async () =>{
    try {
      const response = await getProcessesList();
      const processesList = []
      response.forEach((process)=>{
          processesList.push({value: process._id, label: process.title})
      })
      setProcessTypeOptions(processesList);
   
    } catch (error) {
      console.error('Error fetching process type options:', error);
    }
  }

  const lenguageTypeOptions = [
    { value: 'python', label: 'Python' },
    { value: 'javascript', label: 'Javascript' },
    { value: 'typescript', label: 'Typescript' },
    { value: 'css', label: 'CSS' },
    { value: 'html', label: 'HTML' },
    { value: 'xml', label: 'XML' },
    { value: 'c#', label: 'C#' },
    { value: 'c++', label: 'C++' },
    { value: 'json', label: 'JSON' },
    { value: 'markdown', label: 'Markdon' }
  ];

  const tabsTypeOptions = [
    { value: 'tab a', label: 'Tab A' },
    { value: 'tab b', label: 'Tab B' }
  ];

  const blocksTypeOptions = [
    { value: 'block a', label: 'Block A' },
    { value: 'block b', label: 'Block B' }
  ];

  const interpreterTypeOptions = [
    { value: 'linux', label: 'Linux' },
  ];

  const [selectedBlockType, setSelectedBlockType] = useState(value || 'text');
  

  useEffect(() => {
    if (value) {
      setSelectedBlockType(value);

      setTimeout(()=>{
        // displayBlockEvents(value);
      }, 100)
      updateBlockType(value);
    }
  }, [value]);

  const clearOtherKeys = (existingData, extraKeysToKeep=[]) => {
    const allKeys = [
      'processId', 'textType', 'language', 'tab', 'block', 'settings', 'interpreter', 'childBlock', 'childTab'
    ];
    const keysToKeep = ['blockType', 'input', 'output', ...extraKeysToKeep]

    allKeys.forEach(key => {
        if (!keysToKeep.includes(key)) {
            delete existingData[key];
        }
    });
  }

  const handleUpdateBlockNode = async () => {
    await processNode(jsonDataBlock, null, blockId);
  }

  const updateBlockType = (value) => {
    
    jsonDataBlock.blockType = value;
    switch(value){
      case 'process':
        jsonDataBlock.processId = '';
        jsonDataBlock.settings = {};
        clearOtherKeys(jsonDataBlock, ['processId', 'settings']);
        fetchProcessses();
        break
      case 'code':
        jsonDataBlock.language = 'python';
        clearOtherKeys(jsonDataBlock, ['language']);
        break
      case 'text':
        jsonDataBlock.textType = 'Simple Text';
        clearOtherKeys(jsonDataBlock, ['textType']);
        break
      case 'terminal':
        jsonDataBlock.interpreter = 'Linux';
        clearOtherKeys(jsonDataBlock, ['interpreter']);
        break
      case 'upload':
        clearOtherKeys(jsonDataBlock, []);
        break
    }
    handleUpdateBlockNode();

  }

  // const displayBlockEvents = (value) => {
  //   switch(value){
  //     case 'code':
  //       const eventCode = new CustomEvent('displayCodeEditor', { detail: {display: true, blockId: blockId} });
  //       document.dispatchEvent(eventCode);
  //       const eventDisableTerminal = new CustomEvent('displayTerminal', { detail: {display: false, blockId: blockId} });
  //       document.dispatchEvent(eventDisableTerminal);
  //       break
  //     case 'terminal':
  //       const eventTerminal = new CustomEvent('displayTerminal', { detail: {display: true, blockId: blockId} });
  //       document.dispatchEvent(eventTerminal);

  //       const eventDisable = new CustomEvent('displayCodeEditor', { detail: {display: false, blockId: blockId} });
  //         document.dispatchEvent(eventDisable);
  //       break
  //     // case 'process':
  //     //   const eventProcessWebsocket = new CustomEvent('displayProcessWebsocket', { detail: {display: true, blockId: blockId} });
  //     //   document.dispatchEvent(eventProcessWebsocket);
  //     //   break
  //     default:
        
  //       if (jsonDataBlock.blockType === 'code'){
  //         const eventDisable = new CustomEvent('displayCodeEditor', { detail: {display: false, blockId: blockId} });
  //         document.dispatchEvent(eventDisable);
  //         if (jsonDataBlock.language === 'markdown') {
  //           const eventDisableMarkdown = new CustomEvent('markdown', {detail :{blockId: blockId, input: false }});
  //           document.dispatchEvent(eventDisableMarkdown);
  //         }
  //       } else if (jsonDataBlock.blockType === 'process') {
  //         const eventDisableProcessSettings = new CustomEvent('changeProcess', { detail: {processId: false,  blockId: blockId} });
  //         document.dispatchEvent(eventDisableProcessSettings);
  //         // const eventProcessWebsocket = new CustomEvent('displayProcessWebsocket', { detail: {display: false, blockId: blockId} });
  //         // document.dispatchEvent(eventProcessWebsocket);
  //       } else if (jsonDataBlock.blockType === 'terminal') {
  //         const eventDisableTerminal = new CustomEvent('displayTerminal', { detail: {display: false, blockId: blockId} });
  //         document.dispatchEvent(eventDisableTerminal);
  //       }
  //       break
  //   }
  // }

  const handleBlockTypeChange = (value) => {
    setSelectedBlockType(value);
    displayInputTypes(value);
    updateBlockType(value);
  };

  // const handleLanguageChange = (value) => {
  //   switch (jsonDataBlock.language){
  //     case "markdown":
  //       const eventDisableMarkdown = new CustomEvent('markdown', { detail: {blockId: blockId, input: false }});
  //       document.dispatchEvent(eventDisableMarkdown);
  //       break
  //     default:
  //       break
  //   }
  //   jsonDataBlock.language = value
  //   const event = new CustomEvent('changeLanguage', { detail: {blockId: blockId, language: value }});
  //   document.dispatchEvent(event);
  //   handleUpdateBlockNode();
  // };

  const handleTextChange = (value) => {
    jsonDataBlock.textType = value;
    handleUpdateBlockNode();
  }

 

  const handleTabChange = (value) => {
    jsonDataBlock.childTab = value;
    handleUpdateBlockNode();
  }

  const handleBlockChange = (value) => {
    jsonDataBlock.childBlock = value;
    handleUpdateBlockNode();
  }

  return (
    <>
      <Select
        value={selectedBlockType}
        style={{ minWidth: 120 }}
        options={blockTypeOptions}
        onChange={handleBlockTypeChange}
      />
      {selectedBlockType === 'text' && (
        <Select
          defaultValue="simple text"
          style={{ minWidth: 120 }}
          options={textTypeOptions}
          onChange={handleTextChange}
        />
      )}
      {selectedBlockType === 'code' && (
        <Select
          value={editorLanguage? editorLanguage: 'python'}
          style={{ minWidth: 120 }}
          options={lenguageTypeOptions}
          onChange={changeLanguage}
        />
      )}
      {selectedBlockType === 'process' && (
        <Select
          value={processId ? processId : 'Select a process'}
          style={{ minWidth: 120 }}
          options={processTypeOptions}
          onChange={changeProcess}
        />
      )}
      {selectedBlockType === 'tab' && (
        <Select
          defaultValue="Select a Tab"
          style={{ minWidth: 120 }}
          options={tabsTypeOptions}
          onChange={handleTabChange}
        />
      )}
      {selectedBlockType === 'block' && (
        <Select
          defaultValue="Select an interpeter"
          style={{ minWidth: 120 }}
          options={blocksTypeOptions}
          onChange={handleBlockChange}
        />
      )}
      {selectedBlockType === 'terminal' && (
        <Select
          defaultValue="linux"
          style={{ minWidth: 120 }}
          options={interpreterTypeOptions}
          onChange={handleBlockChange}
        />
      )}
      {selectedBlockType === 'upload' && (
             <Upload
             beforeUpload={() => false} // Prevent automatic upload
             onChange={changeUpload}
             showUploadList={false}
           >
             <Button style={{ width: 120, marginRight: '0px' }} icon={<UploadOutlined />}>Subir archivo</Button>
           </Upload>
      )}
      </>
  );
}

export default BlockTypeComponent;
