import { Form, Input, Button, Steps, message, Spin } from "antd";
import { useState } from "react";
import ProfileImageComponent from "./profileImage";
import { checkUsernameEmail, signup } from "../../utils/authApi";


function Signup() {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState(null); // State for profile image
  const [loading, setLoading] = useState(false);

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setProfileImage(file);
    } else {
      setProfileImage(null);
    }
  };

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        // Merge current step data into formData
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const keysToOmit = ["re-email", "profile-image"];
  
    // Merge all steps data and handle the submission
    const data = new FormData();
  
    // Append form fields
    for (const [key, value] of Object.entries({ ...formData, ...values })) {
      if (!keysToOmit.includes(key)) {
        data.append(key, value);
      }
    }
  
    // Append the profile image if available
    if (profileImage) {
      data.append('profile_image', profileImage);
    }
    try {
      await signup(data);
      message.success("Account successfully created, please check your email to verify");
      setTimeout(()=>{
        window.location.reload();
      }, 3000)
    } catch (error){
      message.error("Error creating account, please try later");
    } finally {
      setLoading(false);
    }
  
  };
  

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const emailValidator = async (_, value) => {
    if (!value) {
      return Promise.reject("Please input your email!");
    }
    if (!/\S+@\S+\.\S+/.test(value)) {
      return Promise.reject("Invalid email format!");
    }
    try {
      const response = await checkUsernameEmail(null, value);
      if (response.email === "available") {
        return Promise.resolve();
      } else {
        return Promise.reject("User with this email already exists");
      }
    } catch (error) {
      console.error("Error checking email:", error);
      return Promise.reject("Error validating email");
    }
  };

  const repeatEmailValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your email!");
    }
    if (form.getFieldValue("email") !== value) {
      return Promise.reject("Emails do not match!");
    }
    return Promise.resolve();
  };

  const userValidator = async (_, value) => {
    if (!value) {
      return Promise.reject("Please input your username!");
    }
    try {
      const response = await checkUsernameEmail(value, null);
      if (response.username === "available") {
        return Promise.resolve();
      } else {
        return Promise.reject("User with this username already exists");
      }
    } catch (error) {
      console.error("Error checking username:", error);
      return Promise.reject("Error validating username");
    }
  };

  const passwordValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your password!");
    }
    const minLength = 8;
    const maxLength = 64;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[$¡!|/+.()°{}_<>;:%%*@#¿?&]/;
    if (value.length < minLength || value.length > maxLength) {
      return Promise.reject(`Password must be between ${minLength} and ${maxLength} characters.`);
    }
    if (!hasUpperCase.test(value)) {
      return Promise.reject("Password must include at least one uppercase letter.");
    }
    if (!hasLowerCase.test(value)) {
      return Promise.reject("Password must include at least one lowercase letter.");
    }
    if (!hasNumber.test(value)) {
      return Promise.reject("Password must include at least one number.");
    }
    if (!hasSpecialChar.test(value)) {
      return Promise.reject("Password must include at least one special character.");
    }
    return Promise.resolve();
  };

  const repeatPasswordValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your password!");
    }
    if (form.getFieldValue("password") !== value) {
      return Promise.reject("Password do not match!");
    }
    return Promise.resolve();
  };

  const steps = [
    {
      title: "Personal Info",
      content: (
        <>
          <Form.Item
            label="Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Surname"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input your surname",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Account",
      content: (
        <>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ validator: userValidator }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ validator: emailValidator }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Repeat Email"
            name="re-email"
            rules={[{ validator: repeatEmailValidator }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Profile Image" name="profile-image">
          <ProfileImageComponent onFileChange={handleFileChange} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Security",
      content: (
        <>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ validator: passwordValidator }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Repeat Password"
            name="password_confirmation"
            rules={[{ validator: repeatPasswordValidator }]}
          >
            <Input.Password />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Form
      name="basic"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      style={{ maxWidth: 600, marginTop: '10px' }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      requiredMark={false}
    >
        {loading && (
          <Spin spinning={true} style={{position: 'absolute', zIndex:99, height: '100%', width: '100%', top: '0', left: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}} ></Spin>
        )}
        
     
      
      <Steps current={current} items={steps} />
      <div className="steps-content" style={{ marginTop: 24 }}>
        {steps[current].content}
      </div>
      <div
        className="steps-action"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "right",
        }}
      >
        {current > 0 && <Button onClick={() => prev()}>Previous</Button>}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit">
            Sign-up
          </Button>
        )}
      </div>
    </Form>
  );
}

export default Signup;
