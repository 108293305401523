// File: DataContext.js
import React, { createContext, useState } from 'react';

// Crear el contexto
export const DataContext = createContext();

// Proveedor del contexto que envuelve tu aplicación
export const DataProvider = ({ children }) => {

   
  const [jsonData, setJsonData] = useState({
    userId: '',
    username: '',
    tabs: [],
    folders: [],
    activeTabId: ''
  });

  const [blockToCopy, setBlockToCopy] = useState(
    {
      blockId: null,
      tabId: null
    }
  )

  const [blockToCut, setBlockToCut] = useState(
    {
      blockId: null,
      tabId: null
    }
  )

  const [currentEnvironment, setCurrentEnvironment] = useState('');


  return (
    <DataContext.Provider value={{ 
        jsonData, setJsonData, 
        blockToCopy, setBlockToCopy, 
        blockToCut, setBlockToCut,
        currentEnvironment, setCurrentEnvironment
      }}>
      {children}
    </DataContext.Provider>
  );
};
