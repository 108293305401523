import React, { useState, useContext, useEffect, useRef } from "react";
import { Tree, Dropdown, Skeleton, Button } from "antd";
import {
  CaretDownOutlined,
  NumberOutlined,
  EditOutlined,
  DeleteOutlined, 
  BorderlessTableOutlined,
  PlusCircleOutlined,
  EllipsisOutlined,
  ArrowsAltOutlined
} from "@ant-design/icons";
import { getTabsList } from "../../../utils/nodeUtils";
import { DataContext } from "../../../utils/dataContext";
import { processNode, getNodeContent, deleteNodes } from "../../../utils/nodeUtils";
import ContextMenuTabsComponent from "./contextMenuTabs";
import { parseCookies } from 'nookies';


function TabListComponent() {
  const { jsonData } = useContext(DataContext);
  const [gData, setGData] = useState([]);
  const [gDataPrevious, setGDataPrevious] = useState([]);
  const [expandedKeys] = useState([]);
  const addCalled = useRef(false);
  const [selectedTab, setSelectedTab] = useState(null)
  const [selectedTabs, setSelectedTabs] = useState([])
  const [titleSpan, setTitleSpan] = useState(null)
  const [openTab, setOpenTab] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const cookies = parseCookies();

  let delayCounter = 0; // Inicializar el contador de retraso
  let firstLoad = true;
  let openTabsCount = 0; // Contador para las tabs abiertas
  let openTabsProcessed = 0; 

  const countOpenTabs = (tabs) => {
    let count = 0;
    tabs.forEach((tab) => {
      if (tab.open) {
        count++;
      }
      if (tab.childs.length > 0) {
        count += countOpenTabs(tab.childs); // Contar en los hijos también
      }
    });
    return count;
  };

  const parseTabsList = (tabsList, tab, increment = 1000) => {
    var children = [];
    var data = { title: tab.tabIdName, key: tab.tabId, children: children };
    data.icon = <NumberOutlined />;
    
    if (tab.childs.length > 0) {
      tab.childs.forEach((child) => {
        parseTabsList(children, child, increment); // Pasar el incremento a los hijos
      });
    } else {
      data.isLeaf = true;
    }
    
    tabsList.push(data);
    
    // Calcular el retraso para el elemento actual
    const delay = delayCounter;
    delayCounter += increment; // Incrementar el retraso para la siguiente llamada

    if (tab.open && firstLoad) {
      setTimeout(() => {
        handleOpenTab(tab.tabId, tab.active);
        if (openTabsProcessed === 0){
          console.log('first tab')
          const event = new CustomEvent('openingTabs', { detail: true });
          document.dispatchEvent(event);
        }
        openTabsProcessed++;
        
        if (openTabsProcessed === openTabsCount) { 
          const event = new CustomEvent('openingTabs', { detail: false });
          document.dispatchEvent(event);
        }
      }, delay);
    }
  };


  useEffect(() => {
    const handleFetchTabsList = async () =>{
      await fetchTabsList();
      firstLoad = false;
    }

    const handleDeletedTabFromTopContextMenu = async (event) =>{
      await handleDeleteTab(event.detail);
      fetchTabsList();
      console.log('ok')
    }

    document.addEventListener("fetchTabsList", handleFetchTabsList);
    document.addEventListener("deletedTabFromTopContext", handleDeletedTabFromTopContextMenu);
  return () => {
    document.removeEventListener("fetchTabsList", handleFetchTabsList);
    document.removeEventListener("deletedTabFromTopContext", handleDeletedTabFromTopContextMenu);
  };
  }, []);

  const deepCopy = (items) => 
    items.map(item => ({
      ...item,
      children: item.children ? deepCopy(item.children) : []
    }));


  const fetchTabsList = async () => {
    if (addCalled.current) return; // Evita que se llame dos veces
    addCalled.current = true;
    try {
      const environmentId = cookies.environmentId;
      const response = await getTabsList(jsonData.userId, environmentId);
      const tabsList = [];
      openTabsCount = countOpenTabs(response);
      response.forEach((tab) => {
        
        parseTabsList(tabsList, tab);
      });
      setGData(tabsList);
      const tabsListCopy = deepCopy(tabsList);
      setGDataPrevious(tabsListCopy);
      if (tabsList.length === 0){
        handleNewTab(true);
      }
    } catch (error) {
      console.error("Error fetching process type options:", error);
    } finally {
      addCalled.current = false;
    }
  };
  const onDragEnter = (info) => {
   
  };

  const updateChilds = async (tab) => {
    const children = [];
    const response = await getNodeContent(tab.key);
    const tabData = response.node_output;

    for (const child of tab.children) {
        children.push(child.key);
        await updateChilds(child);  // Asegúrate de esperar la llamada recursiva
    }

    tabData.childs = children;
    await processNode(tabData, null, tab.key);
};


const updateJsonDataTabs = async (data) => {
  const newTabList = [];

  for (const tab of data) {
      newTabList.push(tab.key);
      await updateChilds(tab);  // Asegúrate de esperar la llamada asíncrona
  }

  const response = await getNodeContent(jsonData.userId);
  const userData = response.node_output;
  userData.tabs = newTabList;
  await processNode(userData, null, jsonData.userId);
  fetchTabsList();
};

const moveElementToIndex = (arr, element, toIndex) => {
  // Encuentra el índice del elemento a mover
  const fromIndex = arr.indexOf(element);
  
  // Verifica si el elemento está en el array
  if (fromIndex === -1) {
    throw new Error('Elemento no encontrado en el array');
  }
  
  // Verifica si el índice de destino está dentro del rango
  if (toIndex < 0 || toIndex >= arr.length) {
    throw new Error('Índice de destino fuera de rango');
  }
  
  // Extrae el elemento desde la posición original
  arr.splice(fromIndex, 1);
  
  // Inserta el elemento en la nueva posición
  arr.splice(toIndex, 0, element);
  
  return arr;
};

const insertElementAtIndex = (arr, element, index) => {
  // Verifica si el índice está dentro del rango válido
  if (index < 0 || index > arr.length) {
    throw new Error('Índice fuera de rango');
  }
  
  // Inserta el nuevo elemento en la posición especificada
  arr.splice(index, 0, element);
  
  return arr;
};


  const updatePositionOnDrop = async (info, gridData) => {
    const dragNode = info.dragNode;
    const dropToGap = info.dropToGap;
    const node = info.node;
    console.log(dropToGap)
    if (!dropToGap){
        const parentDragNode = findParentNodeByKey(gridData, dragNode.key)

        if ((parentDragNode && node.key !== parentDragNode.key) || (!parentDragNode)){
          const tabResponse = await getNodeContent(node.key);
          const tabData = tabResponse.node_output;
          const newPosition = insertElementAtIndex(tabData.childs, dragNode.key, info.dropPosition)
          tabData.childs = newPosition;
          console.log(info)
          await processNode(tabData, null, node.key)
          console.log(gridData)
          const parentNode = findParentNodeByKey(gridData, dragNode.key)
          console.log(parentNode)
          if (!parentNode) {
            const userResponse = await getNodeContent(jsonData.userId);
            const userData = userResponse.node_output;
            userData.tabs = userData.tabs.filter(item => item !== dragNode.key);
            await processNode(userData, null, jsonData.userId)
          } else {
            const parentFolderResponse = await getNodeContent(parentNode.key);
            const parentFolderData = parentFolderResponse.node_output;
            parentFolderData.childs = parentFolderData.childs.filter(item => item !== dragNode.key);
            await processNode(parentFolderData, null, parentNode.key)
          }
        

        
        
          
        } else {
          console.log(parentDragNode)
          console.log(node)
          console.log(dragNode)
          const tabResponse = await getNodeContent(node.key);
          console.log(tabResponse)
          const tabData = tabResponse.node_output;
          console.log('Posicion antes: ' + tabData.childs)
          const newPosition = moveElementToIndex(tabData.childs, dragNode.key, info.dropPosition)
          console.log('Posicion despues: '+ newPosition)
          tabData.childs = newPosition;
          await processNode(tabData, null, node.key)
  
        //   if (node.type === 'folder'){
     
        //   } else {
        //     const parentNode = findParentNodeByKey(gridData, node.key)
        //     console.log(parentNode)
        //     if (parentNode){
        //       const folderResponse = await getNodeContent(parentNode.key);
        //       const folderData = folderResponse.node_output;
        //       console.log(folderData.childs)
        //       const newPosition = moveElementToIndex(folderData.childs, dragNode.key, info.dropPosition)
        //       console.log(newPosition)
        //     }
        //   }
        }
        
     
    } else {
      const parentNode = findParentNodeByKey(gridData, node.key)
      const parentDragNode = findParentNodeByKey(gridData, dragNode.key)
      
      if (parentNode !== parentDragNode){
       
        if (!parentNode){
          const userResponse = await getNodeContent(jsonData.userId);
          const userData = userResponse.node_output;
          const newPosition = insertElementAtIndex(userData.tabs, dragNode.key, info.dropPosition)
          userData.tabs = newPosition;
          await processNode(userData, null, jsonData.userId)
      

        } else {
          const tabResponse = await getNodeContent(parentNode.key);
          const tabData = tabResponse.node_output;
          const newPosition = insertElementAtIndex(tabData.childs, dragNode.key, info.dropPosition)
          tabData.childs = newPosition;
          await processNode(tabData, null, parentNode.key)
       
        }
        if (!parentDragNode) {
          const userResponse = await getNodeContent(jsonData.userId);
          const userData = userResponse.node_output;
          userData.tabs = userData.tabs.filter(item => item !== dragNode.key);
          await processNode(userData, null, jsonData.userId)
        } else {
          const parentTabResponse = await getNodeContent(parentDragNode.key);
          const parentTabData = parentTabResponse.node_output;
          parentTabData.childs = parentTabData.childs.filter(item => item !== dragNode.key);
          await processNode(parentTabData, null, parentDragNode.key)
        }
      } else {
        console.log(parentDragNode)
        console.log(parentNode)
        if (parentNode){
            const tabResponse = await getNodeContent(parentNode.key);
            const tabData = tabResponse.node_output;
            
            console.log(info.dropPosition)
            const newPosition = moveElementToIndex(tabData.childs, dragNode.key, info.dropPosition)
            
            tabData.childs = newPosition;
            await processNode(tabData, null, parentNode.key)
        } else {
            // const userResponse = await getNodeContent(jsonData.userId);
            // const userData = userResponse.node_output;
            // const newPosition = moveElementToIndex(userData.folders, dragNode.key, info.dropPosition)
            // userData.folders = newPosition;
            // await processNode(userData, null, jsonData.userId)
        }
      }
    }
    fetchTabsList();
  }




  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    // updateJsonDataTabs(dragKey, dropKey, info.dropToGap);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    // updatePositionOnDrop(info, gDataPrevious);
    setGData(data);
    console.log(gData)

    updateJsonDataTabs(data);

  };

  const findAntTreeTitleElement = (element) => {
    if (!element) return null;
  
    if (element.classList.contains('ant-tree-title')) {
      return element;
    } else {
      // Recorrer los hijos del elemento
      for (let i = 0; i < element.children.length; i++) {
        const foundElement = findAntTreeTitleElement(element.children[i]);
        if (foundElement) return foundElement;
      }
    }
  
    return null;
  };

  const findSiblingByClass = (element, className) => {
    let sibling = element.nextElementSibling;
    while (sibling) {
      if (sibling.classList.contains(className)) {
        return sibling;
      }
      sibling = sibling.nextElementSibling;
    }
    return null; // Retorna null si no se encuentra ningún hermano con la clase deseada
  };

const handleRightClick = (e) => {
  if (e.event.target.className === 'ant-tree-title') {
    const titleSpan = e.event.target;
    setTitleSpan(titleSpan)
  } else if (e.event.target.parentNode.className.includes('anticon')) {
    const titleSpan = findSiblingByClass(e.event.target.parentNode.parentNode, 'ant-tree-title');
    setTitleSpan(titleSpan)
  } if (e.event.target.parentNode.className.includes('ant-tree-treenode')) {
    const titleSpan = findAntTreeTitleElement(e.event.target.parentNode);
    setTitleSpan(titleSpan)
  }
  
  setSelectedTab(e.node.key);
  showContextMenu(e.event.pageX, e.event.pageY, e.node.key);

};


  const showContextMenu = (x, y, key) => {
    setContextMenu({
      visible: true,
      x,
      y,
      key,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      key: null,
    });
  };


  const updateTabTitle = async (newValue) => {
    const response = await getNodeContent(selectedTab);
    const tabData = response.node_output;
    tabData.tabIdName = newValue;
    await processNode(tabData, null, selectedTab);
    const event = new CustomEvent('tabRenamedFromLeft', { detail: {tabIdName: newValue, tabId: selectedTab} });
    document.dispatchEvent(event);
  };


  const handleRenameTab = () => {
    const currentText = titleSpan.textContent.trim();
    const input = document.createElement('input');
    input.value = currentText;
    input.classList.add('ant-tree-title');
    input.style.width = '80%';
    input.style.borderRadius = '4px';
    input.style.fontSize = '12px';
    input.style.border = 'none';
    input.style.outline = '1.4px solid #1677ff';
  
    // Reemplaza titleSpan con input para permitir la edición
    titleSpan.replaceWith(input);
  
    input.focus();
    setIsRenaming(true);
  
    const handleInputBlur = () => {
      cleanupEventListeners();
      const newValue = input.value;
      titleSpan.textContent = newValue;
      updateTabTitle(newValue);
      input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
       // Limpia los event listeners después de completar la edición
       setIsRenaming(false);
    };
  
    const handleInputKeyDown = (e) => {
      if (e.key === 'Enter') {
        cleanupEventListeners();
        const newValue = input.value;
        titleSpan.textContent = newValue;
        updateTabTitle(newValue);
        input.replaceWith(titleSpan); // Reemplaza input con titleSpan después de editar
        // Limpia los event listeners después de completar la edición
        setIsRenaming(false);
      }
    };
  
    input.addEventListener('blur', handleInputBlur);
    input.addEventListener('keydown', handleInputKeyDown);
  
    const cleanupEventListeners = () => {
      input.removeEventListener('blur', handleInputBlur);
      input.removeEventListener('keydown', handleInputKeyDown);
    };
  }

  const findParentNodeByKey = (data, key) => {
    // Recorre cada nodo en data
    for (const node of data) {
      // Verifica si el nodo actual tiene el key deseado
      if (node.children && node.children.some(child => child.key === key)) {
        return node; // Retorna el nodo actual si alguno de sus hijos coincide con el key
      }
  
      // Si el nodo tiene hijos, realiza la búsqueda recursiva en los hijos
      if (node.children) {
        const parentNode = findParentNodeByKey(node.children, key);
        if (parentNode) {
          return parentNode; // Retorna el nodo padre encontrado en los hijos
        }
      }
    }
    return null; // Retorna null si no se encuentra ningún nodo con el key deseado
  };

  const deleteNodesByKeys = (tree, keysToDelete) => {
    // Función recursiva que busca y elimina los nodos con las keys especificadas
    const deleteRecursive = (nodes, keysToDelete) =>  {
      return nodes.filter(node => {
        // Si el nodo tiene una key en la lista de keysToDelete, lo eliminamos
        if (keysToDelete.includes(node.key)) {
          return false;
        }
        // Si tiene hijos, buscar recursivamente en ellos
        if (node.children && node.children.length > 0) {
          node.children = deleteRecursive(node.children, keysToDelete);
        }
        // Devolver el nodo si no es uno de los que queremos eliminar
        return true;
      });
    }
  
    // Ejecutar la función de eliminación recursiva en el árbol completo
    return deleteRecursive(tree, keysToDelete);
  }
  

  const handleDeleteTab = async (tab) => {

    const tabToDelete = tab? tab : selectedTab

    const data = deleteNodesByKeys(gData, [tabToDelete]);
    setGData(data);
    await deleteNodes([tabToDelete], 'tabs');

    
    const event = new CustomEvent('deletedTab', { 
      detail: {
        tabId: tabToDelete, 
        updateRemoved: false, 
        updateNewActive: true
      } 
    });
    document.dispatchEvent(event);
  }

  const handleOpenTab = async (tab, setActive) => {
    if (!tab){
      tab = selectedTab
    }
    console.log(setActive)
    const event = new CustomEvent('addExistingTab', { detail: {tabId: tab, setActive: setActive} });
    document.dispatchEvent(event);
  }

  const handleMenuClick = ({ key}) => {
    switch(key) {
      case 'open-tab':
        handleOpenTab(null, true);
        break
      case 'rename':
        handleRenameTab()
        break
      case 'delete':
        handleDeleteTab()
        break
    }
    handleCloseContextMenu();
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    key: null,
  });

  const items = [
    {
      label: "Open Tab",
      key: "open-tab",
      icon: <BorderlessTableOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Rename",
      key: "rename",
      icon: <EditOutlined style={{marginRight: 5}}/>
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined style={{marginRight: 5}}/>
    }
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
};

  

  useEffect(() => {
  const handleClickOutside = (event) => {
    
    if (contextMenu.visible) {

      const menuElement = document.querySelector(".context-menu-tabs");
      if (menuElement && !menuElement.contains(event.target)) {
        handleCloseContextMenu();
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [contextMenu.visible]);

  const handleDoubleClickTab = (e, node) =>{
    setSelectedTab(node.key);
    setOpenTab(true);
    
  }
  useEffect(() => {
    if (openTab && selectedTab !== null) {
      handleOpenTab(null, true);
      setOpenTab(false); // Reset openTab after handling
    }
  }, [selectedTab, openTab]);

  const handleNewTab = (addBlock) => {
    const eventNewTab = new CustomEvent('contextMenuNewTab', { detail: {addBlock: addBlock} });
    document.dispatchEvent(eventNewTab);
  }

  const itemsMenuTabs = [
    { label: 'Open selected', key: 'open-selected', icon: <ArrowsAltOutlined/> },
    { label: 'Delete selected', key: 'delete-selected', icon: <DeleteOutlined/> }
];

const handleOpenSelected = async () =>{
  const tabsToOpen = [...selectedTabs];
  
  setSelectedTabs([]);

  for (const tab of tabsToOpen) {
    handleOpenTab(tab);
  }

}

const handleDeleteSelected = async () => {
  const tabsToDelete = [...selectedTabs];
  
  setSelectedTabs([]);

  const data = deleteNodesByKeys(gData, tabsToDelete);
  setGData(data);

  await deleteNodes(tabsToDelete, 'tabs');
  
  var deleteDelay = 0; // Inicializar el contador de retraso
  const deleteIncrement = 1000; // Incremento del retraso (puedes ajustar este valor)
  
  // Asumiendo que tabsToDelete es un array con las pestañas a eliminar
  tabsToDelete.forEach((tab, index) => {
    const isLastTab = index === tabsToDelete.length - 1;
    setTimeout(() => {
      const event = new CustomEvent(
        'deletedTab', 
        { 
          detail: {
            tabId: tab, 
            updateRemoved: false, 
            updateNewActive: isLastTab
          } 
        }
      );
      document.dispatchEvent(event);
    }, deleteDelay);
  
    deleteDelay += deleteIncrement; // Incrementar el retraso para la siguiente pestaña
  });

    
};

const handleMenuTabsClick = async (e) => {
  const key = e.key;

  switch(key){
    case 'open-selected':
      await handleOpenSelected();
      break
    case 'delete-selected':
      await handleDeleteSelected();
      break
  }
} 
const menuPropsTabs = {
    items: itemsMenuTabs,
    onClick: handleMenuTabsClick
};

  const handleCheckTab = (keys, e)=>{
    setSelectedTabs(keys)
    console.log(e)
  }

  return (
    <ContextMenuTabsComponent>

<div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
  <label style={{ marginRight: '10px', fontSize: '14px' }}>Tabs</label>
  <div style={{ marginLeft: 'auto' }}>
    <Button style={{ marginLeft: '5px' }} title='New Tab'  onClick={handleNewTab}>
      <PlusCircleOutlined />
    </Button>
    <Dropdown menu={menuPropsTabs} trigger={["click"]}>
      <Button style={{ marginLeft: '5px', maxWidth: "44px", minHeight: "32px" }} title="More options">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  </div>
</div>
    {!gData.length > 0  && (<Skeleton active />)}
    
    
    <Tree
    height={250}
      checkable
      showLine
      switcherIcon={<CaretDownOutlined />}
      className="draggable-tree"
      defaultExpandedKeys={expandedKeys}
      draggable={{
        icon: false, // Esto desactivará el icono de arrastre
        nodeDraggable: (node) => !isRenaming // Desactivar arrastrar si isRenaming es true
      }}
      blockNode
      showIcon
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={gData}
      onRightClick={handleRightClick}
      onDoubleClick={handleDoubleClickTab}
      checkedKeys={selectedTabs}
      onCheck={handleCheckTab}
  
    />
    {contextMenu.visible && (
      <div style={{position: 'absolute'}}>
        <Dropdown
        
          menu={menuProps}
          trigger={[]}
          open={contextMenu.visible}
          overlayStyle={{
            position: "fixed",
            left: contextMenu.x,
            top: contextMenu.y,
          }}
          overlayClassName="context-menu-tabs"
        >
          <div
            style={{
              position: "fixed",
              left: contextMenu.x,
              top: contextMenu.y,
              zIndex: 1,
            }}
            onClick={handleCloseContextMenu}
          />
        </Dropdown>
        </div>
      )}
    </ContextMenuTabsComponent>
  );
}
export default TabListComponent;
