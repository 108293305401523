import React from "react";
import { Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

const AddBlockComponent = ({ onMenuClick }) => {
    const items = [
        { label: "Text", key: "text" },
        { label: "Code", key: "code" },
        { label: "Process", key: "process" },
        { label: "Terminal", key: "terminal" },
        { label: "Tab", key: "tab" },
        { label: "Block", key: "block" },
        { label: "Upload", key: "upload" },
    ];
    const menuProps = {
        items,
        onClick: onMenuClick,
    };

    return (
        <Dropdown menu={menuProps} trigger={["click"]}>
            <Button style={{ maxWidth: "44px", minHeight: "32px" }}>
                <EllipsisOutlined />
            </Button>
        </Dropdown>
    );
};

export default AddBlockComponent;
