import { post, get, put, patch, deleteFunc } from "./apiUtils";
import { parseCookies } from 'nookies';


// Función para procesar un nodo utilizando la API
export const processNode = async (input, processId, nodeId, settings={}) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const requestData = {
        input: JSON.stringify(input),
        settings: settings
    };
    if (processId) {
        requestData['process_id'] = processId;
    }
    if (nodeId) {
        requestData['node_id'] = nodeId;
    }
    var url = '/node/process_node/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }

    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData);
        return response;
    } catch (error) {
        console.error('Error processing node:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const getProcessSettings = async (process_id) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const requestData = {
        process_id: process_id
    };

    var url = '/node/get_process_settings/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData);
        return response;
    } catch (error) {
        console.error('Error getting settings:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }

};

export const getProcessesList = async (status, user_id) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    let url = `/process/`;

    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }

    const queryParameters = [];

    if (status) {
        queryParameters.push(`status=${status}`);
    }

    if (user_id) {
        queryParameters.push(`user_id=${user_id}`);
    }

    if (queryParameters.length > 0) {
        url += '?' + queryParameters.join('&');
    }

    

    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await get(process.env.REACT_APP_PIPELINE_API_URL, url);
        return response;
    } catch (error) {
        console.error('Error getting processes:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const getNodeContent = async (nodeId, tabs, folders, blocks, env) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    try {
        var url = `/node/get_node_content/?node_id=${nodeId}`;

  
        if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

            url = `/pipeline/port/${apiPort}${url}`
        }
        if (tabs) {
            url = url + "&tabs=true";
        }
        if (folders) {
            url = url + "&folders=true";
        }
        if (blocks) {
            url = url + "&blocks=true";
        }
        if (env) {
            url = url + `&env=${env}`;
        }
        const response = await get(process.env.REACT_APP_PIPELINE_API_URL, url);
        return response;
    } catch (error) {
        console.error('Error getting node content:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const getTabsList = async (nodeId, env) => {
    
    try {
        const response = await getNodeContent(nodeId, true, null, null, env)
        return response.node_output.tabs;
    } catch (error) {
        console.error('Error getting tab list:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const getFilesList = async (nodeId) => {
    try {
        const response = await getNodeContent(nodeId, false, true)
        return response.node_output.folders;
    } catch (error) {
        console.error('Error getting files list:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const getTabContent = async (nodeId) => {
    try {
        const response = await getNodeContent(nodeId, false, false, true)
        return response.node_output;
    } catch (error) {
        console.error('Error getting tab content:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const runPythonCode = async (code) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const requestData = {
        code: code
    };

    var url = '/process/run_python_code/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData)
        return response.output;
    } catch (error) {
        console.error('Error exectuing python code:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const uploadFile = async (formData) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;

    var url = '/file/upload_file/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
   
    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, formData, );
        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }

};

export const saveProcess = async (id, title, code, user_id) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;

    const requestData = {
        _id: id,
        title: title,
        code: code,
        user_id: user_id
    };
    console.log(requestData);

    var url = '/process/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
   
    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData);
        return response;
    } catch (error) {
        console.error('Error saving process:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }

};

export const deleteProcess = async (id) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;

    var url = `/process/${id}/`
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
   
    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await deleteFunc(process.env.REACT_APP_PIPELINE_API_URL, url);
        return response;
    } catch (error) {
        console.error('Error saving process:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }

};

// export const runProcess = async (id, input, settings) => {
//     const cookies = parseCookies();
//     const apiPort = cookies.apiPort;

//     const requestData = {
//         process_id: id,
//         input: input, 
//         settings: settings
//     };
//     console.log(requestData);
   
//     try {
//         // Llama a la función de utilidad de POST con la URL completa y los datos
//         const response = await post(process.env.REACT_APP_PIPELINE_API_URL, `/pipeline/port/${apiPort}/process/run_process/`, requestData);
//         return response;
//     } catch (error) {
//         console.error('Error running process:', error);
//         throw error; // Puedes manejar el error según tus necesidades
//     }

// };


export const getEnvironments = async () => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }
    var url = `/environment/`;
    try {
        const response = await get(process.env.REACT_APP_USERS_API_URL, url, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const saveEnvironment = async (name, port, last_used) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }
    const requestData = {
        name: name,
        port: port,
        last_used: last_used
    };
    var url = `/environment/`;
    try {
        const response = await post(process.env.REACT_APP_USERS_API_URL, url, requestData, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const updateEnvironment = async (id, name, port, last_used) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
    const token = cookies.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`
    }
    
    const requestData = {};
    if (name){
        requestData.name = name;
    }
    if (port){
        requestData.port = port;
    }
    if (last_used){
        requestData.last_used = last_used;
    } else {
        requestData.last_used = false;
    }
    var url = `/environment/${id}/`;

    try {
        const response = await patch(process.env.REACT_APP_USERS_API_URL, url, requestData, headers)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};


export const directoryCreate = async (directory_name) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
 
    const requestData = {
        directory_name: directory_name
    };
    var url = '/file/directory_create/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};


export const moveDirectory = async (current_path, new_path) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
 
    const requestData = {
        current_path: current_path,
        new_path: new_path
    };
    var url = '/file/move_directory/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};

export const renameFile = async (current_path, new_name) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
 
    const requestData = {
        current_path: current_path,
        new_name: new_name
    };
    var url = '/file/rename_file/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};


export const removeFile = async (path) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;
 
    const requestData = {
        path: path
    };

    var url = '/file/remove_file/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    try {
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData)
        return response;
    } catch (error) {
        console.error('Error on get environments:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }
};


export const deleteNodes = async (id_list, type) => {
    const cookies = parseCookies();
    const apiPort = cookies.apiPort;

    var url = '/node/delete_node/'
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === "false"){

        url = `/pipeline/port/${apiPort}${url}`
    }
    const requestData = {
        uuid_list: id_list,
        type: type
    };
   
    try {
        // Llama a la función de utilidad de POST con la URL completa y los datos
        const response = await post(process.env.REACT_APP_PIPELINE_API_URL, url, requestData);
        return response;
    } catch (error) {
        console.error('Error saving process:', error);
        throw error; // Puedes manejar el error según tus necesidades
    }

};




