import React, { useEffect, useRef } from 'react';

function DynamicHTMLComponent({ htmlOutputs }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Limpiar scripts anteriores para evitar duplicados
      const scripts = containerRef.current.querySelectorAll('script');
      scripts.forEach(script => {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        document.body.appendChild(newScript);
        document.body.removeChild(newScript);
      });
    }
  }, [htmlOutputs]); // Dependencia para ejecutar cuando htmlOutputs cambie

  return (
    <div ref={containerRef}>
      {htmlOutputs.length > 0 && (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
          {htmlOutputs.map((html, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: html }} />
          ))}
        </div>
      )}
    </div>
  );
}

export default DynamicHTMLComponent;
